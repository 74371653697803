<p-confirmDialog header="Warning" icon="pi pi-exclamation-triangle" key="deleteGame" [breakpoints]="{ '960px': '75vw' }"
    [style]="{ width: '20vw' }"></p-confirmDialog>
<div *ngIf="loading" class="col-12 text-center">
    <p-progressSpinner></p-progressSpinner>
</div>
<ng-container *ngIf="!loading">
    <form [formGroup]="gameForm">
        <div class="formgrid grid">
            <div class="field col-12">
                <label for="game">Game Name</label>
                <input pInputText type="text" class="w-full" id="game" name="gameName" formControlName="gameName">
            </div>
            <div class="field col-12 md:col-6">
                <label for="game_version">Subassembly</label>
                <input pInputText type="text" class="w-full" id="subassemblyName" name="subassemblyName"
                    formControlName="subassemblyName" placeholder="e.g. Main, Camera, Sound, etc.">
                <div class="flex">
                    <button type="button" pButton icon="pi pi-plus" (click)="addSubassembly()" class="flex-1 m-1"
                        [disabled]="gameForm.controls['subassemblyName'].value.trim() === '' || updating"
                        pTooltip="Add subassembly" showDelay="500"></button>
                    <button type="button" pButton icon="pi pi-pencil" (click)="editSubassembly()" class="flex-1 m-1"
                        [disabled]="!selectedSubassembly || updating" pTooltip="Edit subassembly" showDelay="500"></button>
                </div>
                <p-listbox [options]="subassemblies" [(ngModel)]="selectedSubassembly"
                    [ngModelOptions]="{standalone: true}" [listStyle]="{height: '150px'}" emptyMessage=" "
                    (onChange)="getSubassemblyPackges($event)" optionLabel="name">
                </p-listbox>
            </div>
            <div class="field col-12 md:col-6">
                <label for="game_version">Version Name</label>
                <input pInputText type="text" class="w-full" id="versionName" name="versionName"
                    formControlName="versionName" placeholder="e.g. Standard, Chuck E. Cheese, etc.">
                <div class="flex">
                    <button pButton pRipple icon="pi pi-plus" (click)="addPackage()" class="flex-1 m-1"
                        [disabled]="!selectedSubassembly || gameForm.controls['versionName'].value.trim() === '' || updating"
                        pTooltip="Add version name" showDelay="500"></button>
                    <button pButton pRipple icon="pi pi-pencil" (click)="editPackage()" class="flex-1 m-1"
                        [disabled]="!selectedPackage || updating" pTooltip="Edit version name" showDelay="500"></button>
                </div>
                <p-listbox [options]="packages" [(ngModel)]="selectedPackage" [ngModelOptions]="{standalone: true}"
                    [listStyle]="{height: '150px'}" emptyMessage=" " optionLabel="version_name"></p-listbox>
            </div>
        </div>
        <p-messages *ngIf="errorMessage" severity="error">
            <ng-template pTemplate>
                {{errorMessage}}
            </ng-template>
        </p-messages>
        <div class="flex flex-wrap">
            <p-button *ngIf="editGame" label="Delete" icon="pi pi-trash" class="mr-auto" styleClass="p-button-danger"
                [disabled]="updating || deleting" (onClick)="confirmDelete()">
            </p-button>
            <p-button label="Close" icon="pi pi-times" class="ml-auto" styleClass="p-button-secondary"
                [disabled]="updating || deleting" (onClick)="onCancel()">
            </p-button>
            <p-button label="Save" icon="pi pi-check" class="ml-2" (onClick)="submitGame()"
                [disabled]="!gameForm.valid || updating || deleting"></p-button>
        </div>
    </form>
</ng-container>
