import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import { AuthService } from 'src/app/service/auth.service';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { Table } from 'primeng/table';
import { ConfirmationService, LazyLoadEvent, MenuItem } from 'primeng/api';
import { Subject, Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import * as _ from 'lodash';
import { Software } from 'src/app/api/software';
import { ModifySoftwareComponent } from '../modify-software/modify-software.component';

@Component({
  selector: 'app-software',
  templateUrl: './software.component.html',
  styleUrls: ['./software.component.scss']
})
export class SoftwareComponent implements OnInit, OnDestroy {
  @ViewChild('dt', { static: false }) tableComponent: Table;

  rows: Software[] = [];
  selectedRow: Software;
  totalRows: number;
  loading = false;
  columns: any[];

  dialogRef: DynamicDialogRef;

  // LAZY LOADING CODE
  firstLoad = true;
  rowsPerPage = 20;
  sortField = 'software_name';
  sortOrder = 1; // Ascending
  searchTerm: string;
  // stateKey = 'softwareTable';
  // END LAZY LOADING CODE

  filterTimeout: any;
  filterValue: string = '';
  timerId: any;

  items: MenuItem[];
  activeItem: MenuItem;
  queryParams: any;

  private readonly searchSubject = new Subject<string | undefined>();
  searchSubscription: Subscription;

  constructor(private apiService: ApiService, public layoutService: LayoutService, private router: Router,
    private route: ActivatedRoute, public authService: AuthService, private dialogService: DialogService,
    private confirmService: ConfirmationService) {
    this.selectedRow = {} as Software;
    this.totalRows = 0;

    this.columns = [
      { field: 'software_name', header: 'Name', type: 'string' },
      { field: 'description', header: 'Description', type: 'string' },
      { field: 'version_num', header: 'Version #', type: 'string' }
    ];
  }

  ngOnInit(): void {
    this.timerId = setInterval(() => {
      this.forceUpdate();
    }, 1000 * 60 * 10);

    // this.route.queryParams.subscribe(params => {
    //   if (!this.firstLoad)
    //     return;

    //   // console.log('this.route.queryParams', params);
    //   this.queryParams = {};

    //   if (params['s'] !== undefined) {
    //     // console.log('search from query:', params['s'])
    //     this.queryParams['s'] = params['s'];
    //     this.searchTerm = params['s'];
    //   }

    //   // console.log('query =', this.queryParams);
    //   if (!_.isEmpty(this.queryParams)) {
    //     if (localStorage.getItem(this.stateKey)) {
    //       // console.log('clearing filter from state object, before:', localStorage.getItem(this.stateKey));
    //       let event = <LazyLoadEvent>JSON.parse(localStorage.getItem(this.stateKey));
    //       event.filters = null;
    //       localStorage.setItem(this.stateKey, JSON.stringify(event));
    //       // console.log('clearing filter from state object, after:', localStorage.getItem(this.stateKey));
    //     } else {
    //       // console.log('query params found but no state object, nothing to change')
    //     }
    //   } else {
    //     if (localStorage.getItem(this.stateKey)) {
    //       let event = <LazyLoadEvent>JSON.parse(localStorage.getItem(this.stateKey));
    //       if (event.filters) {
    //         if (event.filters['search']) {
    //           // console.log('restoring search from storage:', event.filters['search'].value)
    //           this.searchTerm = event.filters['search'].value;
    //           this.queryParams['s'] = this.searchTerm;
    //         }
    //       }
    //     }
    //   }
    // });

    this.searchSubscription = this.searchSubject
      .pipe(
        debounceTime(800),
        distinctUntilChanged()
      )
      .subscribe(searchTerm => this.tableComponent.filter(searchTerm, 'search', 'contains'));

    // this.forceUpdate();
  }

  ngOnDestroy() {
    clearInterval(this.timerId);
    if (this.dialogRef) {
      this.dialogRef.close();
    }
    this.searchSubscription.unsubscribe();
    // let event = <LazyLoadEvent>JSON.parse(localStorage.getItem(this.stateKey));
    // event['selection'] = null;
    // localStorage.setItem(this.stateKey, JSON.stringify(event));
  }

  getAddButtonLabel(): string {
    if (this.layoutService.isMobile())
      return "";
    else
      return "Add Software";
  }

  forceUpdate() {
    this.tableComponent.filter(this.searchTerm, 'search', 'contains');
  }

  editSoftware(selectedPackage: Software) {
    // console.log('event', event)
    // this.router.navigate(['/packages', event.data.package_id]);
    this.dialogRef = this.dialogService.open(ModifySoftwareComponent, {
      header: `Edit Software`,
      width: this.layoutService.isMobile() ? "100%" : "30vw",
      closable: false,
      closeOnEscape: false,
      draggable: true,
      data: {
        layoutService: this.layoutService,
        id: selectedPackage.software_id
      }
    });

    this.dialogRef.onClose.subscribe((created: boolean) => {
      if (created) {
        this.forceUpdate();
      } else {
        this.selectedRow = null;
      }
    });
  }

  onRowSelect(event: any) {
    this.editSoftware(event.data);
  }

  addPackage() {
    // console.log('event', event)
    // this.router.navigate(['/packages', event.data.package_id]);
    this.dialogRef = this.dialogService.open(ModifySoftwareComponent, {
      header: `Add Software`,
      width: this.layoutService.isMobile() ? "100%" : "30vw",
      closable: false,
      closeOnEscape: false,
      draggable: true,
      data: {
        layoutService: this.layoutService
      }
    });

    this.dialogRef.onClose.subscribe((created: boolean) => {
      if (created) {
        this.forceUpdate();
      }
    });
  }

  isSortDisabled(fieldName: string): boolean {
    if (fieldName.endsWith('_day')) {
      return true;
    }

    return false;
  }

  loadDataLazy(event: LazyLoadEvent) {
    // Apply the filters passed in the event and execute query
    let filters = {};
    this.loading = true;
    // console.log('loadDataLazy', event);
    let sf = event.sortField || this.sortField;
    let filter = event.filters['search'] ? event.filters['search'].value : null;
    if (filter)
      filters['s'] = filter;

    this.selectedRow = null;

    // this.router.navigate(['/'].concat(this.route.snapshot.url.map(p => p.path)), { queryParams: this.criteria ? { ...this.criteria.queryParams, ...filters } : filters });
    // console.log(`criteria - first: ${event.first}, rows: ${event.rows}, sort field: ${sf}, sort order: ${event.sortOrder}, status: ${status}, search: ${filter}, locationIDs: ${locationIDs ? locationIDs.map(l => l.location_id).join(',') : locationIDs}`);
    this.apiService.getSoftwarePackages(event.first, event.rows, sf, event.sortOrder, filter).subscribe({
      next: (resp) => {
        if (resp.body) {
          this.rows = resp.body.rows
          this.totalRows = resp.body.totalRows;
        } else {
          this.rows = [];
        }
        this.loading = false
      }, error: (err) => {
        this.layoutService.onError(err),
          this.loading = false
      }
    });
  }

  onGlobalFilterBodyChange(event, dt) {
    const searchQuery = event.srcElement.value;
    this.searchSubject.next(searchQuery?.trim());
  }

  getPageHeight() {
    if (this.layoutService.isMobile())
      return '100%';
    else return "calc(100vh - 27vh)";
  }
}