import { ValidatorFn, AbstractControl, Validators, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import * as moment from 'moment';

export function optionalValidator(validators?: (ValidatorFn | null | undefined)[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {

    return control.value ? Validators.compose(validators)(control) : null;
  };
}

export class CustomValidators {
  // http://stackoverflow.com/questions/106179/regular-expression-to-match-dns-hostname-or-ip-address
  static IpAddress(control: AbstractControl): { [key: string]: boolean } {
    const regex: RegExp = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
    // console.log('ipAddressValidator:', control.value);
    if (regex.test(control.value)) {
      // console.log('ipAddressValidator valid');
      return null;
    } else {
      // console.log('ipAddressValidator invalid');
      return { valid: false };
    }
  }

  // http://stackoverflow.com/questions/106179/regular-expression-to-match-dns-hostname-or-ip-address
  static Hostname(control: AbstractControl): { [key: string]: boolean } {
    const regex: RegExp = /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/;
    // console.log('hostnameValidator:', control.value);
    if (regex.test(control.value)) {
      // console.log('hostnameValidator valid');
      return null;
    } else {
      // console.log('hostnameValidator invalid');
      return { valid: false };
    }
  }

  static HostnameOrIpAddress(control: AbstractControl): { [key: string]: boolean } {
    // Test hostname
    let result = CustomValidators.Hostname(control);
    if (!result) {
      // Valid, return now
      return null;
    }

    // Invalid, test as IP address
    result = CustomValidators.IpAddress(control);

    return result;
  }

  private static EmailAddress(email: string): boolean {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  static SurroundingWhiteSpace(control: AbstractControl): { [key: string]: boolean } {
    if (String(control.value).startsWith(' ') || String(control.value).endsWith(' ')) {
      return { whitespace: false };
    } else {
      return null;
    }
  }

  static EmailList(control: AbstractControl): { [key: string]: boolean } {
    if (String(control.value).length > 0 &&
      String(control.value).split('\n').filter(email => !CustomValidators.EmailAddress(email)).length) {
      // console.log('bad mail', String(control.value));
      return { emailList: false };
    } else {
      // console.log('good mail');

      return null;
    }
  }

  static IpAddressList(control: AbstractControl): { [key: string]: boolean } {
    if (String(control.value).length > 0 &&
      String(control.value).split('\n').filter(ip => {
        const tmpControl = { value: ip } as AbstractControl;
        return CustomValidators.IpAddress(tmpControl);
      }).length) {
      return { ipAddressList: false };
    } else {
      return null;
    }
  }

  // Verifies the startDateControlName is <= the endDateControlName in days
  static DateIsSameOrBefore(startDateControlName: string, endDateControlName: string): ValidatorFn {
    return (group: UntypedFormGroup): { [key: string]: any } => {
      const dateRegex = /^\d{2}\/\d{2}\/\d{2}$/;
      const dateRegex2 = /^\d{4}\-\d{2}\-\d{2}$/;
      if ((dateRegex.test(String(group.controls[startDateControlName].value)) &&
        dateRegex.test(String(group.controls[endDateControlName].value))) ||
        (dateRegex2.test(String(group.controls[startDateControlName].value)) &&
          dateRegex2.test(String(group.controls[endDateControlName].value)))) {
        let mStartDate = null;
        if (dateRegex.test(String(group.controls[startDateControlName].value))) {
          mStartDate = moment(String(group.controls[startDateControlName].value), 'MM/DD/YY');
        } else {
          mStartDate = moment(String(group.controls[startDateControlName].value), 'YYYY-MM-DD');
        }

        let mEndDate = null;
        if (dateRegex.test(String(group.controls[endDateControlName].value))) {
          mEndDate = moment(String(group.controls[endDateControlName].value), 'MM/DD/YY');
        } else {
          mEndDate = moment(String(group.controls[endDateControlName].value), 'YYYY-MM-DD');
        }

        if (mStartDate.isValid() && mEndDate.isValid()) {
          if (mStartDate.isSameOrBefore(mEndDate, 'day')) {
            // console.log('DateIsSameOrBefore valid');
            return null;
          } else {
            return { error: 'Start Date must be the same or before End Date' };
          }
        } else {
          return { error: 'Start and/or End Date invalid' };
        }
      } else {
        return { error: 'Start and End Dates required' };
      }
    };
  }


  // Intended to check that date in dateControlName is >= date in minDateControlName. dateControlName is considered valid if it
  // is empty. Date fields should contain Date objects
  static DateIsSameOrAfter(dateControlName: string, minDateControlName: string): ValidatorFn {
    return (group: UntypedFormGroup): { [key: string]: any } => {
      if (group.controls[dateControlName].value) {
        const mDate = moment(group.controls[dateControlName].value);
        const minDate = moment(group.controls[minDateControlName].value);

        if (mDate.isValid() && minDate.isValid()) {
          if (minDate.isSameOrBefore(mDate, 'day')) {
            // Valid
            return null;
          } else {
            return { error: `Date must be the same or after ${minDate.format('MM/DD/YYYY')}` };
          }
        } else {
          return { error: 'Invalid date' };
        }
      } else {
        // Valid
        return null;
      }
    };
  }

  static DateIsBetween(minDate: moment.Moment,
    maxDate: moment.Moment,
    startDateControlName: string,
    endDateControlName: string): ValidatorFn {
    return (group: UntypedFormGroup): { [key: string]: any } => {
      const dateRegex = /^\d{2}\/\d{2}\/\d{2}$/;
      const dateRegex2 = /^\d{4}\-\d{2}\-\d{2}$/;
      if ((dateRegex.test(String(group.controls[startDateControlName].value)) &&
        dateRegex.test(String(group.controls[endDateControlName].value))) ||
        (dateRegex2.test(String(group.controls[startDateControlName].value)) &&
          dateRegex2.test(String(group.controls[endDateControlName].value)))) {
        let mStartDate = null;
        if (dateRegex.test(String(group.controls[startDateControlName].value))) {
          mStartDate = moment(String(group.controls[startDateControlName].value), 'MM/DD/YY');
        } else {
          mStartDate = moment(String(group.controls[startDateControlName].value), 'YYYY-MM-DD');
        }

        let mEndDate = null;
        if (dateRegex.test(String(group.controls[endDateControlName].value))) {
          mEndDate = moment(String(group.controls[endDateControlName].value), 'MM/DD/YY');
        } else {
          mEndDate = moment(String(group.controls[endDateControlName].value), 'YYYY-MM-DD');
        }

        // const mMinDate = moment(minDate);
        // const mMaxDate = moment(maxDate);
        // console.log('ready to isvalid');
        if (mStartDate.isValid() && mEndDate.isValid()) {
          if (mStartDate.isBetween(minDate, maxDate, 'day', '[]') && mEndDate.isBetween(minDate, maxDate, 'day', '[]')) {
            return null;
          } else {
            return { error: `Start and End Dates cannot be outside ${minDate.format('MM/DD/YY')} - ${maxDate.format('MM/DD/YY')}` };
          }
        } else {
          return { error: 'Start and/or End Date invalid' };
        }
      } else {
        return { error: 'Start and End Dates required' };
      }
    };
  }

  static DateRangeIsBetween(minDate: moment.Moment,
    maxDate: moment.Moment): ValidatorFn {
    return (control: UntypedFormControl): { [key: string]: any } => {
      // const dateRegex = /^\d{2}\/\d{2}\/\d{2}$/;
      const dateRegex2 = /^\d{4}\-\d{2}\-\d{2}$/;
      let mStartDate = null;
      let mEndDate = null;
      if (control.value) {
        if (control.value[1] === null) {
          // console.log('Date range is missing end date');
          return { error: 'Date range is missing end date.' };
        } else {
          // console.log('start', control.value[0]);
          // console.log('end', control.value[1]);
          mStartDate = moment(control.value[0]);
          mEndDate = moment(control.value[1]);
          if (mStartDate.isBetween(minDate, maxDate, 'day', '[]') && mEndDate.isBetween(minDate, maxDate, 'day', '[]')) {
            // console.log('Date range is valid');
            return null;
          } else {
            // console.log('Date range cannot be outside');
            return { error: `Date range cannot be outside of: ${minDate.format('MM/DD/YY')} - ${maxDate.format('MM/DD/YY')}` };
          }
        }
      } else {
        // console.log('date range is required');
        return { error: 'Date range is required.' };
      }
    };
  }

  static MultipleOf(min: number, max: number, step: number): ValidatorFn {
    return (control: UntypedFormControl): { [key: string]: any } => {
      if (control.value) {
        if (control.value !== min && control.value !== max && control.value % step) {
          // console.log(`Value must be a multiple of ${step} when it is not set to the min/max value.`);
          return { error: `Value must be a multiple of ${step} when it is not set to the min/max value.` };
        } else { return null; }
      } else {
        // console.log('Value is required');
        return { error: 'Value is required.' };
      }
    };
  }
}
