<p-confirmPopup></p-confirmPopup>
<div class="grid">
    <div class="col-12" [style.height]="getPageHeight()">
        <div class="flex gap-2 mb-2">
          <span class="material-symbols-outlined" style="font-size: 2.5rem">warning_amber</span><span
                class="font-semibold my-auto text-2xl">Game Alerts</span>
        </div>
        <p-table #dt [columns]="columns" [value]="alerts" selectionMode="single" [(selection)]="selectedAlert"
            [scrollable]="!layoutService.isMobile()" [lazy]="true" (onLazyLoad)="loadDataLazy($event)"
            scrollHeight="flex" [responsive]="true" [paginator]="true" [rows]="rowsPerPage" [sortField]="sortField"
            [sortOrder]="sortOrder" [totalRecords]="totalAlerts" [lazyLoadOnInit]="true"
            [rowsPerPageOptions]="[10,20,30,50,100]" [loading]="loading" (onRowSelect)="handleRowClick()"
            styleClass="p-datatable-striped">
            <ng-template pTemplate="caption">
              <div class="flex">
                <span class="p-input-icon-left ml-auto">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" [(ngModel)]="searchTerm" pAutoFocus [autofocus]="true"
                        (onChange)="onGlobalFilterBodyChange($event, dt)" (input)="onGlobalFilterBodyChange($event, dt)"
                        placeholder="Search" (click)="$event.target.select()" />
                </span>
            </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <!-- <th style="width: 2.25em">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th> -->
                    <th *ngFor="let col of columns" [pSortableColumn]="col.sort_field">
                        {{col.header}} <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                            ariaLabelDesc="Activate to sort in descending order"
                            ariaLabelAsc="Activate to sort in ascending order">
                        </p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                    <!-- <td>
            <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
          </td> -->
                    <td *ngFor="let col of columns">
                        <span class="p-column-title font-bold mr-auto">{{col.header}}</span>
                        <ng-container *ngIf="col.field === 'alert_time'; else noFormat">
                            {{rowData[col.field] | date:'M/d/yy h:mm:ss aa'}}</ng-container>
                        <ng-template #noFormat>{{rowData[col.field]}}</ng-template>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td [attr.colspan]="columns.length + 1">
                        No results for selected criteria.
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <ng-container *ngIf="alerts.length > 0">
                    <div class="text-right text-xl" [ngClass]="{'mb-8': layoutService.isMobile()}">
                        {{totalAlerts | number}} alert{{totalAlerts == 0 || totalAlerts > 1 ? 's' : '' }}
                    </div>
                </ng-container>
            </ng-template>
        </p-table>
    </div>
</div>
