import { Component, OnInit } from '@angular/core';
import { saveAs } from 'file-saver';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-report-viewer',
  templateUrl: './report-viewer.component.html',
  styleUrls: ['./report-viewer.component.scss']
})
export class ReportViewerComponent implements OnInit {
  report: Record<string, any>;
  rows = [];
  layoutService: LayoutService; // Reference to component passed in through DynamicDialogConfig
  logData: string;

  constructor(private apiService: ApiService, private ref: DynamicDialogRef, public config: DynamicDialogConfig) {

  }

  ngOnInit() {
    // Due to dynamically instantiating this component, I get an error if I use dependency injection so instead
    // The caller needs to pass a reference to the layoutService
    this.layoutService = this.config.data.layoutService;

    this.apiService.getReport(this.config.data.mac_address, this.config.data.id, this.config.data.type, this.config.data.modified ? this.config.data.modified : null).subscribe({
      next: (resp) => {
        if (resp.body) {
          this.report = resp.body;

          if (this.config.data.type === 'log') {
            this.logData = this.report["logData"];
          } else {
            for (let key in this.report) {
              this.rows.push({ key: key, value: this.report[key] });
            }
          }
        } else {
          this.layoutService.onError(resp);
          setTimeout(() => this.ref.close(), 2000);
        }
      }, error: (err) => {
        this.layoutService.onError(err);
        setTimeout(() => this.ref.close(), 2000);
      }
    });
  }

  onCloseClick() {
    this.ref.close();
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  export(fileType: string) {
    this.apiService.exportReport(this.config.data.mac_address, this.config.data.id, this.config.data.type, fileType, this.config.data.modified ? this.config.data.modified : null).subscribe({
      next: (resp) => {
        saveAs(resp, this.config.data.filename + `.${fileType}`);
      }, error: (err) => {
        // console.log('err', err)
        this.layoutService.onError(err);
      }
    });
  }

  onDownloadAuditClick() {
    window.location.href = this.config.data.audit_file;
  }
}
