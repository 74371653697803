<div class="layout-menu-profile">
    <button class="p-link border-noround" (click)="toggleMenu()" pTooltip="Profile"
        [tooltipDisabled]="isTooltipDisabled">
        <img [src]="'assets/layout/images/' + (layoutService.config.colorScheme === 'light' ? 'user' : 'user-dark') + '.png'" alt="avatar" style="width: 32px; height: 32px;">
        <span>
            <strong>{{fullname}}</strong>
            <small>{{userAccessLevel}}</small>
        </span>
        <i class="layout-menu-profile-toggler pi pi-fw"
            [ngClass]="{'pi-angle-down': menuProfilePosition === 'start' || isHorizontal, 'pi-angle-up': menuProfilePosition === 'end' && !isHorizontal}"></i>
    </button>

    <ul *ngIf="menuProfileActive" [@menu]="isHorizontal ? 'overlay' : 'inline'">
        <li pTooltip="Settings" [tooltipDisabled]="isTooltipDisabled">
            <button class="p-link" (click)="layoutService.onProfileSettingsClicked()">
                <i class="pi pi-cog pi-fw"></i>
                <span>Account Settings</span>
            </button>
        </li>
        <li pTooltip="Logout" [tooltipDisabled]="isTooltipDisabled" (click)="onToggleTheme()">
            <button class="p-link">
                <span class="material-symbols-outlined" style="font-size: 1.25rem; margin-right: 0.5rem;">
                    contrast
                </span>
                <span>Toggle Color Scheme</span>
            </button>
        </li>
        <li pTooltip="Logout" [tooltipDisabled]="isTooltipDisabled" (click)="layoutService.onLogoutClicked()">
            <button class="p-link">
                <i class="pi pi-power-off pi-fw"></i>
                <span>Logout</span>
            </button>
        </li>
    </ul>
</div>
