<p-confirmPopup></p-confirmPopup>
<div class="grid">
    <div class="col-12" [style.height]="getPageHeight()">
        <div class="flex gap-2 mb-2">
          <span class="material-symbols-outlined" style="font-size: 2.5rem">settings</span><span class="font-semibold my-auto text-2xl">Server Settings</span>
            <p-message severity="warn"
                text="Be cautious when modifying these values, a wrong value could take down the server!">
            </p-message>
        </div>
        <p-table #dt [value]="settings" selectionMode="single" [globalFilterFields]="['key_name', 'description']"
            [scrollable]="!layoutService.isMobile()" [(selection)]="selectedRow" scrollHeight="flex" [responsive]="true"
            [paginator]="true" [rows]="rowsPerPage" [sortField]="sortField" [sortOrder]="sortOrder"
            [totalRecords]="totalRows" [rowsPerPageOptions]="[10,20,30,50,100]" (onRowSelect)="onRowSelect($event)"
            [loading]="loading" styleClass="p-datatable-striped">
            <ng-template pTemplate="caption">
                <div class="flex">
                    <button pButton type="button" icon="pi pi-plus" [label]="getAddButtonLabel()" (click)="onAdd()"></button>
                    <div class="align-self-center font-semibold ml-auto md:text-xl">
                        <input type="text" (click)="$event.target.select()" pInputText placeholder="Search" id="gfilter" pAutoFocus [autofocus]="true"
                            class="ml-2" (input)="dt.filterGlobal($event.target.value, 'contains')">
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                  <th pSortableColumn="key_name">
                    Key <p-sortIcon field="key_name"></p-sortIcon>
                  </th>
                  <th pSortableColumn="description">
                      Description <p-sortIcon field="description"></p-sortIcon>
                  </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-kv>
                <tr [pSelectableRow]="kv">
                  <td>{{kv.key_name}}</td>
                    <td>{{kv.description}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
