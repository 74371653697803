<p-confirmDialog key="discardClient" [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '20vw' }"></p-confirmDialog>
<div *ngIf="loading" class="col-12 text-center">
    <p-progressSpinner></p-progressSpinner>
</div>
<ng-container *ngIf="!loading">
    <form [formGroup]="dataEntryForm">
        <div class="formgrid grid">
            <div class="field col-12 md:col-4">
                <label for="game">Game</label>
                <input pInputText type="text" class="w-full" id="game" name="game" formControlName="game">
            </div>
            <div class="field col-12 md:col-4">
                <label for="game_version">Version</label>
                <input pInputText type="text" class="w-full" id="game_version" name="game_version"
                    formControlName="game_version">
            </div>
            <div class="field col-12 md:col-4">
                <label for="mac_address">MAC Address</label>
                <input pInputText type="text" class="w-full" id="mac_address" name="mac_address"
                    formControlName="mac_address">
            </div>
            <div class="field col-12">
                <label for="location_name">Location</label>
                <input pInputText type="text" class="w-full" id="location_name" name="location_name"
                    formControlName="location_name">
            </div>
            <div class="field col-12 md:col-4">
                <label for="drone_version">Game Client Version</label>
                <input pInputText type="text" class="w-full" id="drone_version" name="drone_version"
                    formControlName="drone_version">
            </div>
            <div class="field col-12 md:col-4">
                <label for="check_in_time">Last Contact</label>
                <input pInputText type="text" class="w-full" id="check_in_time" name="check_in_time"
                    formControlName="check_in_time">
            </div>
            <div class="field col-12 md:col-4">
                <label for="package_id">Package ID</label>
                <input pInputText type="text" class="w-full" id="package_id" name="package_id"
                    formControlName="package_id">
            </div>
            <div class="field col-12 md:col-6">
                <label for="public_ip_address">Public IP Address</label>
                <input pInputText type="text" class="w-full" id="public_ip_address" name="public_ip_address"
                    formControlName="public_ip_address">
            </div>
            <div class="field col-12 md:col-6">
                <label for="local_ip_address">Local IP Address</label>
                <input pInputText type="text" class="w-full" id="local_ip_address" name="local_ip_address"
                    formControlName="local_ip_address">
            </div>
            <div class="field col-12">
                <label for="schedule_type">Audit Schedule</label>
                <p-dropdown [options]="auditScheduleOptions" id="schedule_type" name="schedule_type" styleClass="w-full"
                    (onChange)="scheduleTypeChanged($event)" formControlName="schedule_type">
                </p-dropdown>
                <ng-container *ngIf="dataEntryForm.value.schedule_type == 'Recurring'">
                    <p-dropdown [options]="auditFrequencyOptions" id="schedule_frequency" name="schedule_frequency"
                        placeholder="Select frequency"
                        styleClass="w-full fadeinright animation-ease-out animation-duration-300 mt-2"
                        (onChange)="scheduleFrequencyChanged($event)" formControlName="schedule_frequency">
                    </p-dropdown>
                </ng-container>
                <ng-container
                    *ngIf="dataEntryForm.value.schedule_frequency == 'Weekly' && dataEntryForm.value.schedule_type == 'Recurring'">
                    <p-multiSelect [options]="weeklyOptions" selectionLimit="2" showToggleAll="false" [filter]="false"
                        formControlName="schedule_options_weekly" placeholder="Select up to 2 days"
                        styleClass="w-full fadeinright animation-ease-out animation-duration-300 mt-2">
                    </p-multiSelect>
                </ng-container>
                <ng-container
                    *ngIf="dataEntryForm.value.schedule_frequency == 'Monthly' && dataEntryForm.value.schedule_type == 'Recurring'">
                    <p-dropdown [options]="monthlyOptions" id="monthly" name="monthly"
                        formControlName="schedule_options_monthly" placeholder="Select time of month"
                        styleClass="w-full fadeinright animation-ease-out animation-duration-300 mt-2">
                    </p-dropdown>
                </ng-container>
            </div>
            <div class="field col-12">
                <label for="audit_id">Past Audits</label>
                <p-dropdown [options]="record.audits" id="audit_id" name="audit_id" styleClass="w-full"
                    formControlName="audit_id" optionLabel="formattedAuditDate" optionValue="audit_id" filter="true"
                    [virtualScroll]="true" [virtualScrollItemSize]="31">
                </p-dropdown>
            </div>
            <div class="field col-12">
                <button pButton pRipple label="Download" icon="pi pi-download" class="p-button-raised mr-2 mb-2"
                    (click)="onDownloadAuditClick()" [disabled]="(record.audits && record.audits.length == 0) || updating"></button>
                <button pButton pRipple label="View Current" icon="pi pi-eye" class="p-button-raised mr-2 mb-2"
                    (click)="onViewCurrentAuditClick()"
                    [disabled]="(record.audits && record.audits.length == 0) || updating"></button>
                <button pButton pRipple label="View Lifetime" icon="pi pi-eye" class="p-button-raised mr-2 mb-2"
                    (click)="onViewLifetimeAuditClick()"
                    [disabled]="(record.audits && record.audits.length == 0) || updating"></button>
                <button pButton pRipple label="View Log" icon="pi pi-eye" class="p-button-raised mr-2 mb-2"
                    (click)="onViewGameLogClick()" [disabled]="(record.audits && record.audits.length == 0) || updating"></button>
            </div>
            <div class="field col-12">
                <div class="formgroup-inline">
                    <div class="field-checkbox">
                        <p-checkbox id="clear_current_audits" inputId="clear_current_audits" name="clear_current_audits"
                            formControlName="clear_current_audits" [binary]="true"></p-checkbox>
                        <label for="clear_current_audits"> Clear Current (Last Cleared:
                            {{record.last_cleared_current_audits}})</label>
                    </div>
                    <div class="field-checkbox">
                        <p-checkbox id="clear_lifetime_audits" inputId="clear_lifetime_audits"
                            name="clear_lifetime_audits" formControlName="clear_lifetime_audits" [binary]="true">
                        </p-checkbox>
                        <label for="clear_lifetime_audits"> Clear Lifetime (Last Cleared:
                            {{record.last_cleared_lifetime_audits}})</label>
                    </div>
                </div>
            </div>
            <div class="field col-12">
                <label class="w-full" for="audit_id">Settings</label>
                <button pButton pRipple label="View Current" icon="pi pi-eye" class="p-button-raised mr-2 mb-2"
                    (click)="onViewCurrentSettingsClick()"
                    [disabled]="(record.audits && record.audits.length == 0) || updating"></button>
                <button pButton pRipple label="View Modified" [disabled]="!record.settings_file_exists || updating" icon="pi pi-eye"
                    (click)="onViewModifiedSettingsClick()" class="p-button-raised mr-2 mb-2"></button>
                <button pButton pRipple label="Edit" icon="pi pi-cog" class="p-button-raised mr-2 mb-2"
                    (click)="onEditSettingsClick()" [disabled]="(record.audits && record.audits.length == 0) || updating"></button>
            </div>
            <div class="field col-12">
                <div class="formgroup-inline">
                    <div *ngIf="record.settings_file_exists" class="field-checkbox">
                        <p-checkbox id="update_settings" inputId="update_settings" name="update_settings"
                            formControlName="update_settings" [binary]="true"></p-checkbox>
                        <label for="update_settings"> Update Settings</label>
                    </div>
                    <div *ngIf="record.settings_file_exists" class="field-checkbox">
                        <p-checkbox id="delete_settings" inputId="delete_settings" name="delete_settings"
                            formControlName="delete_settings" [binary]="true">
                        </p-checkbox>
                        <label for="delete_settings"> Delete Settings</label>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="flex flex-wrap">
            <p-button label="Delete Client" icon="pi pi-trash" class="mr-auto" styleClass="p-button-danger"
                [disabled]="updating" (onClick)="onDeleteClientClick()">
            </p-button>
            <p-button label="Cancel" icon="pi pi-times" class="ml-auto" styleClass="p-button-secondary"
                [disabled]="updating" (onClick)="verifyCancelAction($event)">
            </p-button>
            <p-button label="Save" icon="pi pi-check" class="ml-2" [disabled]="!dataEntryForm.valid || updating"
                (onClick)="submitForm()"></p-button>
        </div>
    </form>
</ng-container>
