import { Component, OnInit } from '@angular/core';
import { saveAs } from 'file-saver';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { ApiService } from 'src/app/service/api.service';
import { GameProvisioningService } from 'src/app/service/game-provisioning.service';

@Component({
  selector: 'app-game-install-log-viewer',
  templateUrl: './game-install-log-viewer.component.html',
  styleUrls: ['./game-install-log-viewer.component.scss']
})
export class GameInstallLogViewerComponent implements OnInit {
  report: Record<string, any>;
  layoutService: LayoutService; // Reference to component passed in through DynamicDialogConfig
  logData: string;

  constructor(private gameProvisioningService: GameProvisioningService, private ref: DynamicDialogRef,
    public config: DynamicDialogConfig) {
  }

  ngOnInit() {
    // Due to dynamically instantiating this component, I get an error if I use dependency injection so instead
    // The caller needs to pass a reference to the layoutService
    this.layoutService = this.config.data.layoutService;
    let logFileUrl = this.config.data.selected_log;

    this.gameProvisioningService.downloadLog(logFileUrl).subscribe({
      next: (resp) => {
        this.logData = resp;
      }, error: (err) => {
        this.layoutService.onError(err);
        setTimeout(() => this.ref.close(), 2000);
      }
    });
  }

  downloadLogFile() {
    console.log('this.selectedLog.log_file', this.config.data.selected_log)
    window.open(this.config.data.selected_log, '_blank');
  }
}
