<p-confirmDialog header="Warning" icon="pi pi-exclamation-triangle" key="deleteGamePackage"
    [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '20vw' }"></p-confirmDialog>
<form [formGroup]="dataEntryForm">
    <div class="formgrid grid">
        <div class="field col-12">
            <label for="subassembly">Subassembly</label>
            <input pInputText type="text" class="w-full" id="subassembly" name="subassembly"
                formControlName="subassembly">
        </div>
        <div class="field col-12">
            <label for="versionName">Version Name</label>
            <input pInputText type="text" class="w-full" id="versionName" name="versionName"
                formControlName="versionName">
        </div>
        <div class="field col-12">
            <label for="versionNum">Version #</label>
            <input pInputText type="text" class="w-full" id="versionNum" name="versionNum" formControlName="versionNum"
                placeholder="e.g. 1.0.8">
        </div>
        <div class="field col-12">
            <label for="packageFile">Package</label>
            <p-fileUpload #u [style.display]="uploading ? 'none' : 'block'" name="packageFile"
                [showUploadButton]="false" [showCancelButton]="false" customUpload="true"
                (uploadHandler)="myUploader($event)" (onProgress)="updateProgress($event)" accept=".gz">
                <ng-template pTemplate="content" let-files>
                    <div class="text-center font-italic p-1">Drag & drop <strong>.tar.gz</strong> file here</div>
                </ng-template>
            </p-fileUpload>
            <div class="font-italic" *ngIf="uploading">Uploading: {{ uploader.files[0].name }}...</div>
            <p-progressBar *ngIf="uploading" [value]="uploadProgress" [showValue]="false"></p-progressBar>
        </div>
        <div *ngIf="editPackage" class="field col-12">
            <label for="modified">Last Modified</label>
            <input pInputText type="text" class="w-full" id="modified" name="modified" formControlName="modified">
        </div>
    </div>
    <p-messages *ngIf="errorMessage" severity="error">
        <ng-template pTemplate>
            {{errorMessage}}
        </ng-template>
    </p-messages>
    <div class="flex flex-wrap">
        <p-button label="Delete" icon="pi pi-trash" class="mr-auto" styleClass="p-button-danger"
            [disabled]="uploading || deleting" (onClick)="confirmDelete()" pTooltip="Delete game package"
            showDelay="500">
        </p-button>
        <p-button label="Cancel" icon="pi pi-times" class="ml-auto" styleClass="p-button-secondary"
            [disabled]="uploading || deleting" (onClick)="onCancel()">
        </p-button>
        <p-button label="Save" icon="pi pi-check" class="ml-2" (onClick)="submitPackage()"
            [disabled]="!dataEntryForm.valid || uploading || deleting"></p-button>
    </div>
</form>
