import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { ApiService, Setting } from 'src/app/service/api.service';

@Component({
  selector: 'app-modify-setting',
  templateUrl: './modify-setting.component.html',
  styleUrls: ['./modify-setting.component.scss']
})
export class ModifySettingComponent implements OnInit {
  newSetting = false;
  dataEntryForm: UntypedFormGroup;
  setting: Setting = {} as Setting;
  errorMessage: string;
  successMessage: string;
  updating = false;
  loading = false;
  layoutService: LayoutService;

  constructor(private apiService: ApiService, private confirmService: ConfirmationService,
    private ref: DynamicDialogRef, private config: DynamicDialogConfig) { }

  ngOnInit() {
    let key = '';
    if (this.config.data.key !== undefined) {
      key = this.config.data.key;
      this.layoutService = this.config.data.layoutService;
      // console.log('this.config.data', this.config.data)
    }

    this.loading = true;
    this.dataEntryForm = new UntypedFormGroup({
      'key_name': new UntypedFormControl(this.setting.key_name, [Validators.required, Validators.maxLength(40)]),
      'data': new UntypedFormControl(this.setting.data, [Validators.required, Validators.maxLength(255)]),
      'description': new UntypedFormControl(this.setting.description, [Validators.required, Validators.maxLength(2048)]),
    });

    if (key) {
      this.newSetting = false;
      this.apiService.getSetting(key).subscribe({
        next: (resp) => {
          // console.log('resp', resp)
          this.setting = resp;
          this.dataEntryForm.controls['key_name'].setValue(this.setting.key_name);
          this.dataEntryForm.controls['key_name'].disable({ onlySelf: true });
          this.dataEntryForm.controls['data'].setValue(this.setting.data);
          this.dataEntryForm.controls['description'].setValue(this.setting.description);
          this.loading = false;
        }, error: (err) => {
          this.loading = false;
          this.layoutService.onError(err);
        }
      });
    } else {
      this.newSetting = true;
      this.loading = false;
    }
  }



  onSave() {
    this.updating = true;
    this.errorMessage = '';

    this.setting.data = this.dataEntryForm.value.data;
    this.setting.description = this.dataEntryForm.value.description;

    if (this.newSetting) {
      this.setting.key_name = this.dataEntryForm.value.key_name;
      this.apiService.addSetting(this.setting).subscribe({
        next: () => {
          this.updating = false;
          this.ref.close(true);
        }, error: (err) => {
          console.log(err);
          this.errorMessage = err;
          this.updating = false;
        }
      });
    } else {
      this.apiService.updateSetting(this.setting).subscribe({
        next: () => {
          this.updating = false;
          this.ref.close(true);
        }, error: (err) => {
          this.errorMessage = err;
          this.updating = false;
        }
      });
    }
  }

  onCancel() {
    this.ref.close();
  }

  deleteSetting(setting: Setting) {
    this.updating = true;

    this.apiService.deleteSetting(setting).subscribe({
      next: () => {
        this.updating = false;
        this.ref.close(true);
      }, error: (err) => {
        this.errorMessage = err;
        this.updating = false;
      }
    });
  }

  onDeleteClick() {
    this.confirmService.confirm({
      message: "Deleting a setting can have dire consequences, are you REALLY sure you know what you're doing???",
      icon: 'pi pi-exclamation-triangle',
      key: 'discardSetting',
      accept: () => {
        this.deleteSetting(this.setting);
      },
      reject: () => {
        //reject action
      }
    });
  }
}
