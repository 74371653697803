<div *ngIf="!logData else showLog" class="col-12 text-center">
    <p-progressSpinner></p-progressSpinner>
</div>
<ng-template #showLog>
    <div class="flex flex-wrap"><button pButton pRipple label="Download" icon="pi pi-download"
            class="p-button-raised ml-auto" (click)="downloadLogFile()"></button></div>
    <pre>
{{logData}}
</pre>
</ng-template>
