import { Component, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileUpload } from 'primeng/fileupload';
import { ApiService } from 'src/app/service/api.service';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { ActivatedRoute } from '@angular/router';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Software } from 'src/app/api/software';

@Component({
  selector: 'app-modify-software',
  templateUrl: './modify-software.component.html',
  styleUrls: ['./modify-software.component.scss']
})
export class ModifySoftwareComponent implements OnInit {
  @ViewChild('u', { static: false }) uploader: FileUpload;
  firstLoad = true;
  updating = false;
  loading = false;
  dataEntryForm: UntypedFormGroup;
  record: Software = {} as Software;
  formChanged = false;
  newRecord = true;
  maxFilesize = 20 * 1024 * 1024;
  packageDownloadServer = '';
  minDate = new Date();
  uploading = false;
  deleting = false;
  uploadProgress = 0;
  errorMessage: string;

  constructor(private apiService: ApiService, public layoutService: LayoutService,
    private activatedRoute: ActivatedRoute, private config: DynamicDialogConfig, private ref: DynamicDialogRef,
    private confirmationService: ConfirmationService) { }

  ngOnInit() {
    let id = null;
    if (this.config.data.id !== undefined) {
      // console.log('this.config.data.id', this.config.data.id)
      id = this.config.data.id;
      this.newRecord = false;
    }

    this.dataEntryForm = new UntypedFormGroup({
      'software_name': new UntypedFormControl(null, Validators.required),
      'version_num': new UntypedFormControl(null, Validators.required),
      'description': new UntypedFormControl(null, null),
      'replacePackageFile': new UntypedFormControl(false, null)
    });

    if (id) {
      this.apiService.getSoftwarePackage(id).subscribe({
        next: (resp) => {
          // When editing a task we no longer allow setting task type
          this.record = resp.body;
          // console.log(resp.body);

          this.dataEntryForm.controls['software_name'].setValue(this.record.software_name);
          this.dataEntryForm.controls['version_num'].setValue(this.record.version_num);
          this.dataEntryForm.controls['description'].setValue(this.record.description);

          this.dataEntryForm.valueChanges.subscribe(val => {
            this.formChanged = true;
          });
        }, error: (err) => this.layoutService.onError(err)
      });
    } else {
      this.dataEntryForm.valueChanges.subscribe(val => {
        this.formChanged = true;
      });
    }
  }

  submitForm() {
    this.errorMessage = '';
    if (this.newRecord || this.dataEntryForm.value.replacePackageFile) {
      if (this.uploader.files.length === 0) {
        this.errorMessage = "Select a file to upload."
        return;
      } else {
        this.uploader.upload();
      }
    } else
      this.myUploader(null);
  }

  toggleUploader() {
    this.errorMessage = '';
  }

  myUploader(event: any) {
    this.errorMessage = '';
    this.updating = true;
    this.uploading = true;

    const formData = new FormData();
    formData.append('software_name', this.dataEntryForm.value.software_name);
    formData.append('version_num', this.dataEntryForm.value.version_num);
    formData.append('description', this.dataEntryForm.value.description ? this.dataEntryForm.value.description : '');

    if (event)
      formData.append('files', event.files[0]);

    this.dataEntryForm.disable();
    if (this.newRecord) {
      this.apiService.addSoftwarePackage(formData).subscribe({
        next: (event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            // console.log(Math.round(100 * event.loaded / event.total));
            this.uploadProgress = Math.round(100 * event.loaded / event.total);
          } else if (event instanceof HttpResponse) {
            this.errorMessage = event.body.message;
            this.uploadProgress = 100;
            this.ref.close(true);
            // success!
          } else {
            // console.log('event', event);
            // this.uploadProgress = 100;
          }
        },
        error: (err: any) => {
          // console.log(err);
          this.uploadProgress = 100;

          if (err.error && err.error.message) {
            this.errorMessage = err.error.message;
          } else {
            this.errorMessage = 'Could not upload the file!';
          }

          this.dataEntryForm.enable();
        }
      });
    } else {
      formData.append('software_id', this.record.software_id);
      this.apiService.updateSoftwarePackage(formData).subscribe({
        next: (event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            // console.log(Math.round(100 * event.loaded / event.total));
            this.uploadProgress = Math.round(100 * event.loaded / event.total);
          } else if (event instanceof HttpResponse) {
            this.errorMessage = event.body.message;
            this.uploadProgress = 100;
            this.ref.close(true);
            // success!
          } else {
            // console.log('event', event);
            // this.uploadProgress = 100;
          }
        },
        error: (err: any) => {
          // console.log(err);
          this.uploadProgress = 100;

          if (err.error && err.error.message) {
            this.errorMessage = err.error.message;
          } else {
            this.errorMessage = 'Could not upload the file!';
          }

          this.dataEntryForm.enable();
        }
      });
    }
  }

  updateProgress(event: any) {
    // console.log(event);
  }

  validForm() {
    let valid = true;
    if (this.dataEntryForm.valid) {
      if ((this.newRecord || this.dataEntryForm.value.replacePackageFile) && this.uploader?.files.length === 0) {
        valid = false;
      }
    } else {
      valid = false;
    }

    // this.cd.detectChanges();
    return valid;
  }

  onDeleteClick() {
    this.confirmationService.confirm({
      header: "Delete Software",
      message: "Deleting this software can break installers. Are you sure you want to continue?",
      accept: () => {
        this.updating = true;
        const formData = new FormData();
        formData.append('software_id', this.record.software_id);
        formData.append('delete_software', "1");

        this.apiService.updateSoftwarePackage(formData).subscribe({
          next: (resp) => {
            this.updating = false;
            this.ref.close(true);
          }, error: (err) => {
            this.updating = false;
            this.layoutService.onError(err);
          }
        });
      },
      reject: () => {
      },
      key: "discard"
    });
  }

  verifyCancelAction(event) {
    if (!this.formChanged) {
      this.ref.close(false);
    } else {
      this.confirmationService.confirm({
        message: "Are you sure you'd like to discard these changes?",
        accept: () => {
          this.ref.close(false);
        },
        reject: () => {
        },
        key: "discard"
      });
    }
  }
}
