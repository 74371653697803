<p-confirmDialog header="Delete Setting" key="discardSetting" icon="pi pi-question-circle"
    [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '20vw' }"></p-confirmDialog>
<div *ngIf="loading" class="col-12 text-center">
    <p-progressSpinner></p-progressSpinner>
</div>
<form *ngIf="!loading" [formGroup]="dataEntryForm">
    <div class="formgrid grid">
        <div class="field col-12 md:col-6">
            <label for="key_name">Key</label>
            <input pInputText [pAutoFocus]="true" trimInput type="text" class="w-full" id="key_name" name="key_name"
                formControlName="key_name">
        </div>
        <div class="field col-12 md:col-6">
            <label for="data">Value</label>
            <input pInputText type="text" trimInput class="w-full" id="data" name="data" formControlName="data">
        </div>
        <div class="field col-12">
            <label for="description">Description</label>
            <textarea rows="5" cols="58" pInputTextarea trimInput id="description" name="description" class="w-full max-h-25rem"
                formControlName="description"></textarea>
        </div>
    </div>
</form>
<p-message *ngIf="newSetting" severity="info"
    text="This setting will be ignored unless the server-side code is modified to reference it.">
</p-message>
<hr>
<p-messages *ngIf="errorMessage" severity="error">
    <ng-template pTemplate>
        {{errorMessage}}
    </ng-template>
</p-messages>
<div class="flex flex-wrap">
    <p-button *ngIf="!newSetting" label="Delete Setting" icon="pi pi-trash" class="mr-auto" styleClass="p-button-danger"
        [disabled]="updating" (onClick)="onDeleteClick()">
    </p-button>
    <p-button label="Cancel" icon="pi pi-times" class="ml-auto" styleClass="p-button-secondary" [disabled]="updating"
        (onClick)="onCancel()">
    </p-button>
    <p-button label="Save" icon="pi pi-check" class="ml-2" (onClick)="onSave()"
        [disabled]="!dataEntryForm.valid || updating"></p-button>
</div>
