<p-confirmPopup></p-confirmPopup>
<div class="grid">
    <div class="col-12" [style.height]="getPageHeight()">
        <div class="flex gap-2 mb-2">
            <span class="material-symbols-outlined" style="font-size: 2.5rem">person</span><span
                class="font-semibold my-auto text-2xl">User Administration</span>
        </div>
        <p-table #dt *ngIf="userRecords.length" [value]="userRecords" selectionMode="single" [(selection)]="selectedRow"
            [scrollable]="!layoutService.isMobile()" scrollHeight="flex" sortField="first_name" sortOrder="1"
            (onRowSelect)="onRowSelect($event)" [globalFilterFields]="['first_name', 'last_name', 'username', 'access']"
            [responsive]="true" [loading]="loading" styleClass="p-datatable-striped">
            <ng-template pTemplate="caption">
                <div class="flex">
                    <button type="button" pButton icon="pi pi-plus" (click)="onAddUser()" [label]="getAddButtonLabel()"
                        class="my-auto"></button>
                    <div class="align-self-center font-semibold ml-auto md:text-xl">
                        <input type="text" (click)="$event.target.select()" pInputText pAutoFocus [autofocus]="true"
                            placeholder="Search" id="gfilter" class="ml-2"
                            (input)="dt.filterGlobal($event.target.value, 'contains')">
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th [pSortableColumn]="'first_name'">Full Name <p-sortIcon [field]="'first_name'"
                            ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order"
                            ariaLabelAsc="Activate to sort in ascending order">
                        </p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'username'">Username <p-sortIcon [field]="'username'"
                            ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order"
                            ariaLabelAsc="Activate to sort in ascending order">
                        </p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'access'">Access Level <p-sortIcon [field]="'access'"
                            ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order"
                            ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'last_login_timestamp'">Signed In <p-sortIcon
                            [field]="'last_login_timestamp'" ariaLabel="Activate to sort"
                            ariaLabelDesc="Activate to sort in descending order"
                            ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'last_ip'">IP Address <p-sortIcon [field]="'last_ip'"
                            ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order"
                            ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                    <td><span class="p-column-title font-bold mr-auto">Full
                            Name</span>{{rowData.first_name + ' ' + rowData.last_name}}
                    </td>
                    <td><span class="p-column-title font-bold mr-auto">Username</span>{{rowData.username}}</td>
                    <td><span class="p-column-title font-bold mr-auto">Access Level</span>{{rowData.access}}</td>
                    <td><span class="p-column-title font-bold mr-auto">Signed
                            In</span>{{rowData.last_login_timestamp | date:'M/d/yy h:mm:ss aa'}}</td>
                    <td><span class="p-column-title font-bold mr-auto">IP Address</span>{{rowData.last_ip}}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td colspan="5">
                        No results for selected criteria.
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <ng-container *ngIf="userRecords.length > 1">
                    <div class="text-right text-xl" [ngClass]="{'mb-8': layoutService.isMobile()}">
                        {{userRecords.length}} user{{userRecords.length === 0 || userRecords.length > 1 ? 's' : '' }}
                    </div>
                </ng-container>
            </ng-template>
        </p-table>
    </div>
</div>
