import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ModifyUserComponent } from './modify-user/modify-user.component';
import { ApiService, UserLoginRecord } from 'src/app/service/api.service';
import { LayoutService } from 'src/app/layout/service/app.layout.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  userRecords: UserLoginRecord[] = [];
  loading: boolean;
  userDialogRef: DynamicDialogRef;
  selectedRow: UserLoginRecord;

  constructor(private reportDataService: ApiService, public layoutService: LayoutService,
    private confirmService: ConfirmationService, private dialogService: DialogService) {
  }

  ngOnInit() {
    this.refreshData();
  }

  getAddButtonLabel(): string {
    if (this.layoutService.isMobile())
      return "";
    else
      return "Add User";
  }

  refreshData() {
    this.loading = true;
    this.reportDataService.getUsers().subscribe({
      next: (resp) => {
        this.userRecords = resp.body;
        this.loading = false;
      }, error: (err) => {
        this.loading = false;
        this.layoutService.onError(err);
      }
    });
  }

  onEdit(user: UserLoginRecord) {
    this.userDialogRef = this.dialogService.open(ModifyUserComponent, {
      header: 'Edit User',
      width: this.layoutService.isMobile() ? "100%" : "30vw",
      closable: false,
      closeOnEscape: false,
      draggable: true,
      data: {
        id: user.username,
        layoutService: this.layoutService
      }
    });

    this.userDialogRef.onClose.subscribe((created: boolean) => {
      this.selectedRow = null;
      if (created) {
        this.refreshData();
      }
    });
  }

  onAddUser() {
    this.userDialogRef = this.dialogService.open(ModifyUserComponent, {
      header: 'Add User',
      width: this.layoutService.isMobile() ? "100%" : "30vw",
      closable: false,
      closeOnEscape: false,
      draggable: true,
      data: {
        layoutService: this.layoutService
      }
    });

    this.userDialogRef.onClose.subscribe((created: boolean) => {
      if (created) {
        this.refreshData();
      }
    });
  }

  onRowSelect(event: any) {
    this.onEdit(this.selectedRow);
  }

  getPageHeight() {
    if (this.layoutService.isMobile())
      return '100%';
    else return "calc(100vh - 27vh)";
  }
}
