<div class="layout-topbar" [ngClass]="{'border-bottom-none': layoutService.config.topbarTheme !== 'light'}">
    <div class="layout-topbar-start">
        <a class="layout-topbar-logo" style="color:white; font-size: 1.4em;" routerLink="/">
            Coastal Amusements
        </a>
        <a #menuButton class="layout-menu-button" (click)="onMenuButtonClick()" pRipple>
            <i class="pi pi-angle-right"></i>
        </a>

        <!-- <a #mobileMenuButton class="layout-topbar-mobile-button" (click)="onMobileTopbarMenuButtonClick()" pRipple>
            <i class="pi pi-bars text-4xl"></i>
        </a> -->
    </div>

    <div class="layout-topbar-end">
        <div class="layout-topbar-actions-end">
            <ul class="layout-topbar-items">
                <!-- <li class="layout-topbar-search">
                    <input type="text" placeholder="Search">
                    <i class="pi-fw pi pi-search"></i>
                </li>
                <li>
                    <a pStyleClass="@next" enterClass="ng-hidden" enterActiveClass="px-scalein" leaveToClass="ng-hidden" leaveActiveClass="px-fadeout" [hideOnOutsideClick]="true" pRipple>
                        <i class="pi pi-bell"></i>
                    </a>
                <div class="ng-hidden">
                    <ul class="list-none p-0 m-0">
                        <li>
                            <a class="py-2 px-3 flex gap-2 cursor-pointer text-color hover:text-primary">
                                <i class="pi pi-fw pi-sliders-h text-lg"></i>
                                <span>Pending tasks</span>
                            </a>
                        </li>
                        <li>
                            <a class="py-2 px-3 flex gap-2 cursor-pointer text-color hover:text-primary ">
                                <i class="pi pi-fw pi-calendar text-lg"></i>
                                <span>Meeting today at 3pm</span>
                            </a>
                        </li>
                        <li>
                            <a class="py-2 px-3 flex gap-2 cursor-pointer text-color hover:text-primary">
                                <i class="pi pi-fw pi-download text-lg"></i>
                                <span>Download documents</span>
                            </a>
                        </li>
                        <li>
                            <a class="py-2 px-3 flex gap-2 cursor-pointer text-color hover:text-primary">
                                <i class="pi pi-fw pi-bookmark text-lg"></i>
                                <span>Book flight</span>
                            </a>
                        </li>
                    </ul>
                </div>


                </li>
                <li>
                    <a pStyleClass="@next" enterClass="ng-hidden" enterActiveClass="px-scalein" leaveToClass="ng-hidden" leaveActiveClass="px-fadeout" [hideOnOutsideClick]="true" pRipple>
                        <i class="pi pi-envelope"></i>
                    </a>
                    <div class="ng-hidden">
                        <ul class="list-none p-0 m-0 flex flex-column text-color">
                            <li>
                                <a class="cursor-pointer flex align-items-center px-3 py-2 gap-3 hover:text-primary">
                                    <img src="assets/layout/images/avatar5.png" class="w-3rem h-3rem">
                                    <span>Give me a call</span>
                                </a>
                            </li>
                            <li>
                                <a class="cursor-pointer flex align-items-center px-3 py-2 gap-3 hover:text-primary">
                                    <img src="assets/layout/images/avatar1.png" class="w-3rem h-3rem">
                                    <span>Sales reports attached</span>
                                </a>
                            </li>
                            <li>
                                <a class="cursor-pointer flex align-items-center px-3 py-2 gap-3 hover:text-primary">
                                    <img src="assets/layout/images/avatar2.png" class="w-3rem h-3rem">
                                    <span>About your invoice</span>
                                </a>
                            </li>
                            <li>
                                <a class="cursor-pointer flex align-items-center px-3 py-2 gap-3 hover:text-primary">
                                    <img src="assets/layout/images/avatar3.png" class="w-3rem h-3rem">
                                    <span>Meeting today at 10pm</span>
                                </a>
                            </li>
                            <li>
                                <a class="cursor-pointer flex align-items-center px-3 py-2 gap-3 hover:text-primary">
                                    <img src="assets/layout/images/avatar4.png" class="w-3rem h-3rem">
                                    <span>Out of office</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <a pStyleClass="@next" enterClass="ng-hidden" enterActiveClass="px-scalein" leaveToClass="ng-hidden" leaveActiveClass="px-fadeout" [hideOnOutsideClick]="true" pRipple>
                     <i class="pi pi-cog"></i>
                    </a>
                    <div class="ng-hidden">
                        <ul class="list-none p-0 m-0">
                            <li>
                                <a class="py-2 px-3 flex gap-2 cursor-pointer text-color hover:text-primary">
                                    <i class="pi pi-fw pi-palette text-lg"></i>
                                    <span>Change Theme</span>
                                </a>
                            </li>
                            <li>
                                <a class="py-2 px-3 flex gap-2 cursor-pointer text-color hover:text-primary ">
                                    <i class="pi pi-fw pi-star text-lg"></i>
                                    <span>Favorites</span>
                                </a>
                            </li>
                            <li>
                                <a class="py-2 px-3 flex gap-2 cursor-pointer text-color hover:text-primary">
                                    <i class="pi pi-fw pi-lock text-lg"></i>
                                    <span>Lock Screen</span>
                                </a>
                            </li>
                            <li>
                                <a class="py-2 px-3 flex gap-2 cursor-pointer text-color hover:text-primary">
                                    <i class="pi pi-fw pi-image text-lg"></i>
                                    <span>Wallpaper</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </li> -->
            </ul>
        </div>
    </div>
</div>
