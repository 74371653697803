<p-confirmDialog header="Warning" icon="pi pi-exclamation-triangle" key="discardSettings"
    [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '20vw' }"></p-confirmDialog>
<div *ngIf="loading" class="col-12 text-center">
    <p-progressSpinner></p-progressSpinner>
</div>
<ng-container *ngIf="!loading">
    <form [formGroup]="dataEntryForm">
        <ng-container *ngFor="let section of gameClientSettings">
            <h3>{{ section['name'] }}</h3>
            <ng-container *ngFor="let s of section['settings']">
                <div class="field grid">
                    <label [for]="s['field_name']" class="col-12 mb-2 md:col-4 md:mb-0">{{s['desc']}}</label>
                    <div class="col-12 md:col-8">
                        <p-inputNumber *ngIf="s['type'] === 'number' else notSpinner" mode="decimal"
                            [showButtons]="true" [inputId]="s['field_name']" [min]="s['min']" [max]="s['max']"
                            [step]="s['step']" [formControlName]="s['field_name']">
                        </p-inputNumber>
                        <ng-template #notSpinner>
                            <p-dropdown *ngIf="s['type'] === 'combobox' else notCombo" [inputId]="s['field_name']"
                                [options]="s['options']" styleClass="w-full" [formControlName]="s['field_name']">
                            </p-dropdown>
                            <ng-template #notCombo>
                                <p-checkbox *ngIf="s['type'] === 'checkbox' else unknownType" [id]="s['field_name']"
                                    [inputId]="s['field_name']" [name]="s['field_name']" [binary]="true"
                                    [formControlName]="s['field_name']">
                                </p-checkbox>
                                <ng-template #unknownType>Unknown setting type: {{ s['type'] }}</ng-template>
                            </ng-template>
                        </ng-template>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <hr>
        <div class="flex flex-wrap">
            <p-button label="Cancel" icon="pi pi-times" class="ml-auto" styleClass="p-button-secondary"
                [disabled]="updating" (onClick)="verifyCancelAction($event)">
            </p-button>
            <p-button label="Save" icon="pi pi-check" class="ml-2" (onClick)="submitForm()"
                [disabled]="!dataEntryForm.valid || updating"></p-button>
        </div>
    </form>
</ng-container>
