<p-confirmDialog header="Discard" key="discard" icon="pi pi-question-circle" [breakpoints]="{ '960px': '75vw' }"
    [style]="{ width: '20vw' }"></p-confirmDialog>
<form [formGroup]="dataEntryForm">
    <div class="formgrid grid">
        <div class="field col-12 md:col-6">
            <label for="package_name">Update Name</label>
            <input pInputText type="text" [autofocus]="true" trimInput class="w-full" id="package_name"
                name="package_name" formControlName="package_name">
        </div>
        <div class="field-checkbox col-12 md:col-3 md:my-auto">
            <p-checkbox id="gameUpdate" inputId="gameUpdate" name="gameUpdate" (onChange)="toggleGameUpdate()"
                formControlName="gameUpdate" [binary]="true"></p-checkbox>
            <label for="gameUpdate">Game Update</label>
        </div>
        <div class="field col-12 md:col-3">
            <label for="expiration_date">Expiration</label>
            <p-calendar styleClass="w-full" inputId="expiration_date" formControlName="expiration_date" appendTo="body"
                [minDate]="minDate" [readonlyInput]="true" [showButtonBar]="true" [selectOtherMonths]="true"
                [touchUI]="layoutService.isMobile()" pTooltip="Set a date if the package should expire" showDelay="500">
            </p-calendar>
        </div>
        <div class="field col-12">
            <label for="operand">Criteria</label>
            <div class="flex">
                <p-dropdown appendTo="body" [options]="operandItems" filter="true" placeholder="Operand" id="operand"
                    name="operand" styleClass="w-full" class="flex-1 flex align-items-center mr-2"
                    (onChange)="onOperandChange($event)" formControlName="operand">
                </p-dropdown>
                <p-dropdown appendTo="body" [options]="operatorItems" id="operator" name="operator"
                    class="flex-none flex align-items-center mr-2" formControlName="operator"></p-dropdown>
                <p-dropdown appendTo="body" [options]="valueItems" filter="true" placeholder="Value" id="opValue"
                    name="opValue" styleClass="w-full" class="flex-1 flex align-items-center" formControlName="opValue"
                    [virtualScroll]="true" [virtualScrollItemSize]="31">
                </p-dropdown>
            </div>
            <div [style]="{ height: '6px' }">
                <p-progressBar *ngIf="loading" mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
            </div>
        </div>
        <div class="field col-12 -mt-2 text-right">
            <p-button icon="pi pi-plus" styleClass="p-button p-button-secondary" (onClick)="addCriterian($event)"
                [disabled]="!dataEntryForm.value.operator || !dataEntryForm.value.opValue || !dataEntryForm.value.operand || uploading || deleting"
                showDelay="500" pTooltip="Add criterian to list">
            </p-button>
        </div>
        <div class="field col-12">
            <p-listbox [options]="taskCriteria" emptyMessage="No criteria defined" [listStyle]="{'height':'150px'}"
                formControlName="criteria"></p-listbox>
        </div>
        <div class="field col-12 text-right">
            <p-button icon="pi pi-minus" styleClass="p-button p-button-secondary" (onClick)="removeCriterian($event)"
                [disabled]="!dataEntryForm.value.criteria || uploading || deleting" showDelay="500"
                pTooltip="Remove selected criterian from list">
            </p-button>
        </div>
        <div *ngIf="!newRecord" class="field col-12">
            <a href="{{record.file_path}}">Current package size: {{record.package_filesize}}</a>
        </div>
        <div *ngIf="!newRecord" class="field-checkbox col-12">
            <p-checkbox id="replacePackageFile" inputId="replacePackageFile" name="replacePackageFile"
                (onChange)="toggleUploader()" formControlName="replacePackageFile" [binary]="true"></p-checkbox>
            <label for="replacePackageFile"> Update File</label>
        </div>
        <div *ngIf="newRecord || dataEntryForm.value.replacePackageFile" class="field col-12">
            <label for="package_file">Package</label>
            <p-fileUpload #u [style.display]="uploading ? 'none' : 'block'" name="package_file"
                [showUploadButton]="false" [showCancelButton]="false" customUpload="true"
                (uploadHandler)="myUploader($event)" (onProgress)="updateProgress($event)" accept=".gz">
                <ng-template pTemplate="content" let-files>
                    <div class="text-center font-italic p-1">Drag & drop <strong>.tar.gz</strong> file here</div>
                </ng-template>
            </p-fileUpload>
            <div class="font-italic" *ngIf="uploading">Uploading: {{ uploader.files[0].name }}...</div>
            <p-progressBar *ngIf="uploading" [value]="uploadProgress" [showValue]="false"></p-progressBar>
            <p-messages *ngIf="errorMessage" severity="error">
                <ng-template pTemplate>
                    {{errorMessage}}
                </ng-template>
            </p-messages>
        </div>
    </div>
    <hr>
    <div class="flex flex-wrap">
        <p-button *ngIf="!newRecord" label="Delete" icon="pi pi-trash" class="mr-auto" styleClass="p-button-danger"
            [disabled]="uploading || deleting" (onClick)="onDeleteClick()" pTooltip="Delete game package"
            showDelay="500">
        </p-button>
        <p-button label="Cancel" icon="pi pi-times" class="ml-auto" styleClass="p-button-secondary"
            [disabled]="uploading || deleting" (onClick)="verifyCancelAction($event)">
        </p-button>
        <p-button label="Save" icon="pi pi-check" class="ml-2" (onClick)="submitForm()"
            [disabled]="!dataEntryForm.valid || uploading || deleting"></p-button>
    </div>
</form>
