import { Injectable } from '@angular/core';
import { ApiService, UserLoginRecord, UserPayload, ApiResponse } from './api.service';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { HttpResponse } from '@angular/common/http';

const jwtHelper = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  preferenceChangeEvent = new Subject<boolean>();
  signInStatusUpdate = new Subject<ApiResponse>();
  updateMyAccountUpdate = new Subject<boolean>();
  userLogin: UserLoginRecord = {} as UserLoginRecord;
  timerId: any;

  constructor(private api: ApiService, private router: Router) {
    // Delete the old session object if it exists
    if (localStorage.getItem('user_session')) {
      localStorage.removeItem('user_session');
    }
    if (this.loadUserFromToken(localStorage.getItem('session_token'))) {
      this.initSessionTokenCheck();
    }
  }

  signInUser(email: string, password: string): Observable<HttpResponse<ApiResponse>> {
    return this.api.signInUser(email, password);
  }

  // Default is to check every hour
  initSessionTokenCheck(frequency = 1000 * 60 * 60) {
    this.timerId = setInterval(() => {
      this.sessionTokenCheck();
    }, frequency);
  }

  /**
   * Check session token to see if it's close to expiring, refresh if necessary
   */
  sessionTokenCheck(): void {
    // if token is going to expire within the next hour
    const payload = this.decodePayload(localStorage.getItem('session_token'));

    if (payload) {
      if (moment().unix() >= payload.exp - environment.secondsBeforeTokenExpiration) {
        this.api.refreshToken().subscribe(resp => {
          // A new session token is issued after an account update
          const sessionToken = resp.headers.get('x-auth');
          localStorage.setItem('session_token', sessionToken);

          if (!this.loadUserFromToken(sessionToken)) {
            this.signoutUser();
          }
        }, err => {
          this.signoutUser();
        });
      }
    } else {
      this.signoutUser();
    }
  }

  parsePayload(payload: UserPayload): UserLoginRecord {
    const user: UserLoginRecord = {} as UserLoginRecord;
    user.user_id = payload.data.id;
    user.first_name = payload.data.fn;
    user.last_name = payload.data.ln;
    user.username = payload.data.u;
    user.access_level_id = payload.data.ai;
    user.access = payload.data.a;
    user.timezone = payload.data.tz;

    return user;
  }

  decodePayload(token: string): UserPayload {
    let payload: UserPayload = null;
    try {
      payload = jwtHelper.decodeToken(token) as UserPayload;
      // If any field is undefined then it's invalid
      if (payload.exp === undefined || payload.iat === undefined ||
        payload.data === undefined ||
        payload.data.fn === undefined ||
        payload.data.ln === undefined ||
        payload.data.id === undefined ||
        payload.data.u === undefined ||
        payload.data.a === undefined ||
        payload.data.ai === undefined ||
        payload.data.tz === undefined) {
        payload = null;
      } else if (jwtHelper.isTokenExpired(token)) {
        payload = null;
      }
    } catch (e) {
    }

    return payload;
  }

  loadUserFromToken(token: string): boolean {
    let success = false;
    if (token) {
      const payload = this.decodePayload(token);
      if (payload) {
        this.userLogin = this.parsePayload(payload);
        success = true;
      }
    } else {
      this.userLogin = {} as UserLoginRecord;
    }

    return success;
  }

  signoutUser() {
    this.clearSession();
    clearInterval(this.timerId);
    this.router.navigate(['/login']);
  }

  clearSession() {
    this.userLogin = {} as UserLoginRecord;
    localStorage.removeItem('session_token');
  }

  getUserAccount(): UserLoginRecord {
    if (this.loadUserFromToken(localStorage.getItem('session_token'))) {
      return this.userLogin;
    } else {
      clearInterval(this.timerId);
      return null;
    }
  }

  updateMyAccount(user_id: number, first_name?: string, last_name?: string, password?: string): Observable<boolean> {
    this.api.updateMyAccount(user_id, first_name, last_name, password).subscribe(resp => {
      // A new session token is issued after an account update
      const sessionToken = resp.headers.get('x-auth');
      localStorage.setItem('session_token', sessionToken);
      this.loadUserFromToken(sessionToken);

      this.updateMyAccountUpdate.next(true);
    }, err => {
      this.updateMyAccountUpdate.next(false);
    });

    return this.updateMyAccountUpdate.asObservable();
  }

  resetPasswordRequest(email: string): Observable<ApiResponse> {
    return this.api.resetPasswordRequest(email);
  }

  resetPassword(resetKey: string, password: string): Observable<ApiResponse> {
    return this.api.resetPassword(resetKey, password);
  }

  isAuthenticated(): boolean {
    if (localStorage.getItem('session_token')) {
      if (this.loadUserFromToken(localStorage.getItem('session_token'))) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isStaff(): boolean {
    return this.userLogin.access_level_id && this.userLogin.access_level_id === 2;
  }

  isAdministrator(): boolean {
    return this.userLogin.access_level_id && this.userLogin.access_level_id === 1;
  }

  getAccessLevel(): number {
    return this.userLogin.access_level_id;
  }

  accessLevelToString(): string {
    switch (this.userLogin.access_level_id) {
      case 1:
        return 'Administrator';
        break;

      case 2:
        return 'Staff';
        break;

      default:
        return "?"
        break;
    }
  }
}
