import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
  username = '';
  errorResult = '';
  resetResult = '';
  loading = false;

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit() {
    this.username = '';
    this.errorResult = '';
    this.loading = false;
    this.resetResult = '';
  }

  onSubmit(form: NgForm) {
    if (form.valid && !this.loading) {
      this.errorResult = '';
      this.loading = true;

      this.authService.resetPasswordRequest(form.value.login_username).subscribe({
        next: (resp) => {
          this.loading = false;
          if (resp.ok) {
            this.resetResult = resp.message;
          } else {
            this.errorResult = resp.message;
          }
          this.loading = false;
        },
        error: (err) => {
          this.errorResult = err;
          this.loading = false;
        }
      });
    } else {
      // console.log('ignoring form submit attempt')
    }
  }
}
