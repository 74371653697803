import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import { AuthService } from 'src/app/service/auth.service';

// Must contain uppercase, lowercase and number or symbol
const PASSWORD_REGEX = /^((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: UntypedFormGroup;
  username = '';
  resetKey = '';
  formError = '';
  resetResult = '';
  loading = false;
  barLabel = 'Password strength:';
  myColors = ['#DD2C00', '#FF6D00', '#FFD600', '#AEEA00', '#00C853'];
  strengthLabels = ['(Useless)', '(Weak)', '(Normal)', '(Strong)', '(Great!)'];
  minPasswordLength: number;
  initializing = true;

  constructor(private authService: AuthService, private apiService: ApiService, private router: Router, private activeRoute: ActivatedRoute) { }

  ngOnInit() {
    this.apiService.getAccountValidationRules().subscribe({
      next: (resp) => {
        this.minPasswordLength = resp.body.min_password_length;

        this.resetPasswordForm = new UntypedFormGroup({
          'new_password': new UntypedFormControl(null, [Validators.required, Validators.minLength(this.minPasswordLength)]),
          'repeat_password': new UntypedFormControl(null, [Validators.required, Validators.minLength(this.minPasswordLength), Validators.pattern(PASSWORD_REGEX)])
        }, this.passwordsMatch);

        this.formError = '';
        this.resetResult = '';

        const params = this.activeRoute.snapshot.queryParams;

        if (!params['k']) {
          // console.error('Missing query parameters, redirecting to home');
          this.router.navigate(['/']);
        }

        this.initializing = false;
      }, error: (err) => {
        this.resetPasswordForm = new UntypedFormGroup({
          'new_password': new UntypedFormControl({ value: null, disabled: true }, Validators.required),
          'repeat_password': new UntypedFormControl({ value: null, disabled: true }, Validators.required)
        }, this.passwordsMatch);
        this.formError =
          'Could not contact server. Please try again later.';

        this.initializing = false;
      }
    });
  }

  private passwordsMatch(form: UntypedFormGroup) {
    // console.log('passwordsMatch called');
    if (form.get('new_password').value !== form.get('repeat_password').value) {
      // console.log('mismatch');
      return { mismatch: true };
    } else {
      // console.log('match');
      return null;
    }
  }

  onSubmit() {
    this.formError = '';
    this.resetResult = '';
    this.loading = true;
    this.authService.resetPassword(this.activeRoute.snapshot.queryParams['k'],
      this.resetPasswordForm.value.new_password).subscribe({
        next: (resp) => {
          if (resp.ok) {
            this.resetResult = resp.message;
          } else {
            this.formError = resp.message;
          }
          this.loading = false;
        }, error: (err) => {
          this.formError =
            'Could not change your password. Please try again or request another password reset and follow the link in the email.';
          this.loading = false;
        }
      });
  }

}
