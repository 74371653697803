import { HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { EditGameClient, EditGameSettings, GameClient, GameClientResponse, UpdateGameClient } from '../api/game-client';
import { Package, PackageCriteriaValues, PackageResponse } from '../api/package';
import { Software, SoftwareResponse } from '../api/software';

export interface ApiResponse {
  ok?: boolean | null;
  message?: string | null;
}

export interface Setting {
  key_name: string;
  data: string;
  description: string;
}

export interface Alert {
  alert_id: number;
  alert_time: string;
  public_ip_address: string;
  local_ip_address: string;
  log_message: string;
  sent: number;
}

export interface AlertListing {
  rows: Alert[];
  totalRows: number;
}

////////////////////////////////////////////////////////////////

export interface AccessLevel {
  access_level_id: number;
  name: string;
}

export interface Timezone {
  name: string;
}

export interface UserOptions {
  access_level_list: AccessLevel[];
  timezones_list: Timezone[];
  min_password_length: number;
}

export interface UserLoginRecord {
  user_id: number;
  access_level_id: number;
  access: string; // access level name
  first_name: string;
  last_name: string;
  username: string;
  last_login_time: string;
  last_failed_attempt: string;
  last_ip: string;
  login_attempts: number;
  login_attempt_ip: string;
  timezone: string;
  locked: number;
  deactivated: number;
  access_level_list: AccessLevel[];
  timezones_list: Timezone[];
  min_password_length?: number;
}

export interface UpdateAccount {
  access_level_id: number;
  first_name: string;
  last_name: string;
  username: string;
  locked: number;
  login_attempts: number;
  password?: string;
  timezone: string;
}

export interface AccountValidationRules {
  min_password_length: number;
}

export interface UserPayload {
  data: {
    id: number;  // user_id
    fn: string;  // first_name
    ln: string;  // last_name
    u: string;   // username
    ai: number;  // access_level_id
    a: string;  // access level name
    tz: string;  // timezone
  }
  iat: number; // issued at time
  exp: number; // expiration
}

export interface ServerResponse {
  result?: string;
  error?: string;
}

////////////////////////////////////////////////////////////////

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private http: HttpClient) { }

  public getGameClients(first: number, rows: number, sortField: string, sortOrder: number, filter: string): Observable<HttpResponse<GameClientResponse>> {
    const httpHeaders = new HttpHeaders({
      'x-auth': this.getUserToken()
    });

    let querystring = `/clients?first=${first}&rows=${rows}&sortField=${sortField}&sortOrder=${sortOrder}`;

    if (filter) {
      const encFilter = encodeURIComponent(filter)
      querystring += `&filter=${encFilter}`;
    }

    return this.http.get<GameClientResponse>(environment.apiUrl + querystring, { headers: httpHeaders, observe: 'response' }).pipe(
      catchError(this.handleError));
  }

  public getGameClient(mac: string): Observable<HttpResponse<EditGameClient>> {
    const httpHeaders = new HttpHeaders({
      'x-auth': this.getUserToken()
    });

    let url = `${environment.apiUrl}/editclient?mac=${mac}`;
    return this.http.get<EditGameClient>(url, { headers: httpHeaders, observe: 'response' }).pipe(
      catchError(this.handleError));
  }

  public updateGameClient(gameClient: UpdateGameClient): Observable<ApiResponse> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-auth': this.getUserToken()
    });

    return this.http.post<ApiResponse>(`${environment.apiUrl}/updateclient`, gameClient, { headers: httpHeaders }).pipe(
      catchError(this.handleError));
  }

  public deleteGameClient(gameClient: UpdateGameClient): Observable<ApiResponse> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-auth': this.getUserToken()
    });

    return this.http.post<ApiResponse>(`${environment.apiUrl}/updateclient`, gameClient, { headers: httpHeaders }).pipe(
      catchError(this.handleError));
  }

  public getReport(mac: string, id: number, reportType: string, modified?: boolean): Observable<HttpResponse<Record<string, any>>> {
    const httpHeaders = new HttpHeaders({
      'x-auth': this.getUserToken()
    });

    let url = `${environment.apiUrl}/generateReport?mac=${mac}&type=${reportType}`;

    if (id) {
      url += `&id=${id}`;
    }

    if (modified) {
      url += `&modified=1`;
    }

    return this.http.get<Record<string, any>>(url, { headers: httpHeaders, observe: 'response' }).pipe(
      catchError(this.handleError));
  }

  public exportReport(mac: string, id: number, reportType: string, fileType: string, modified?: boolean): Observable<Blob> {
    const httpHeaders = new HttpHeaders({
      'x-auth': this.getUserToken(),
      'Accept': 'application/octet-stream'
    });

    let url = `${environment.apiUrl}/exportreport?mac=${mac}&type=${reportType}&exportFileType=${fileType}`;

    if (id) {
      url += `&id=${id}`;
    }

    if (modified) {
      url += `&modified=1`;
    }

    return this.http.get(url, { headers: httpHeaders, responseType: 'blob' });
  }

  public getGameSettings(mac: string): Observable<HttpResponse<EditGameSettings>> {
    const httpHeaders = new HttpHeaders({
      'x-auth': this.getUserToken()
    });

    let url = `${environment.apiUrl}/editsettings?mac=${mac}`;
    return this.http.get<EditGameSettings>(url, { headers: httpHeaders, observe: 'response' }).pipe(
      catchError(this.handleError));
  }

  public updateGameSettings(settings: Record<string, any>): Observable<ApiResponse> {
    const httpHeaders = new HttpHeaders({
      'x-auth': this.getUserToken()
    });

    const formData = new FormData();
    for (let key in settings) {
      formData.append(key, settings[key]);
    }

    let url = `${environment.apiUrl}/updateSettings`;
    return this.http.post<ApiResponse>(url, formData, { headers: httpHeaders, observe: 'response' }).pipe(
      catchError(this.handleError));
  }

  public getPackages(first: number, rows: number, sortField: string, sortOrder: number, filter: string): Observable<HttpResponse<PackageResponse>> {
    const httpHeaders = new HttpHeaders({
      'x-auth': this.getUserToken()
    });

    let querystring = `/packages?first=${first}&rows=${rows}&sortField=${sortField}&sortOrder=${sortOrder}`;

    if (filter) {
      const encFilter = encodeURIComponent(filter)
      querystring += `&filter=${encFilter}`;
    }

    return this.http.get<PackageResponse>(environment.apiUrl + querystring, { headers: httpHeaders, observe: 'response' }).pipe(
      catchError(this.handleError));
  }

  public getPackage(id: number): Observable<HttpResponse<Package>> {
    const httpHeaders = new HttpHeaders({
      'x-auth': this.getUserToken()
    });

    let url = `${environment.apiUrl}/editpackage?id=${id}`;
    return this.http.get<Package>(url, { headers: httpHeaders, observe: 'response' }).pipe(
      catchError(this.handleError));
  }

  public addUpdatePackage(updatePackage: FormData): Observable<HttpEvent<any>> {
    const httpHeaders = new HttpHeaders({
      'x-auth': this.getUserToken()
    });

    let url = `${environment.apiUrl}/insertpackage`;
    const req = new HttpRequest('POST', url, updatePackage, {
      headers: httpHeaders,
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }

  public modifyUpdatePackage(updatePackage: FormData): Observable<HttpEvent<any>> {
    const httpHeaders = new HttpHeaders({
      'x-auth': this.getUserToken()
    });

    let url = `${environment.apiUrl}/updatepackage`;
    const req = new HttpRequest('POST', url, updatePackage, {
      headers: httpHeaders,
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }

  public getSoftwarePackage(id: number): Observable<HttpResponse<Software>> {
    const httpHeaders = new HttpHeaders({
      'x-auth': this.getUserToken()
    });

    let url = `${environment.apiUrl}/editsoftware?id=${id}`;
    return this.http.get<Software>(url, { headers: httpHeaders, observe: 'response' }).pipe(
      catchError(this.handleError));
  }

  public getSoftwarePackages(first: number, rows: number, sortField: string, sortOrder: number, filter: string): Observable<HttpResponse<SoftwareResponse>> {
    const httpHeaders = new HttpHeaders({
      'x-auth': this.getUserToken()
    });

    let querystring = `/software?first=${first}&rows=${rows}&sortField=${sortField}&sortOrder=${sortOrder}`;

    if (filter) {
      const encFilter = encodeURIComponent(filter)
      querystring += `&filter=${encFilter}`;
    }

    return this.http.get<SoftwareResponse>(environment.apiUrl + querystring, { headers: httpHeaders, observe: 'response' }).pipe(
      catchError(this.handleError));
  }

  public addSoftwarePackage(softwarePackage: FormData): Observable<HttpEvent<any>> {
    const httpHeaders = new HttpHeaders({
      'x-auth': this.getUserToken()
    });

    let url = `${environment.apiUrl}/insertsoftware`;
    const req = new HttpRequest('POST', url, softwarePackage, {
      headers: httpHeaders,
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }

  public updateSoftwarePackage(softwarePackage: FormData): Observable<HttpEvent<any>> {
    const httpHeaders = new HttpHeaders({
      'x-auth': this.getUserToken()
    });

    let url = `${environment.apiUrl}/updatesoftware`;
    const req = new HttpRequest('POST', url, softwarePackage, {
      headers: httpHeaders,
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }

  getDroneCriteriaValues(operand: string): Observable<PackageCriteriaValues> {
    const httpHeaders = new HttpHeaders({
      'x-auth': this.getUserToken()
    });

    let querystring = `/getcriteriavalues?fieldName=${operand}`;

    return this.http.get<PackageCriteriaValues>(environment.apiUrl + querystring, { headers: httpHeaders }).pipe(
      catchError(this.handleError));
  }

  public getSetting(key: string): Observable<Setting> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-auth': this.getUserToken()
    });

    return this.http.get<Setting>(`${environment.apiUrl}/settings?k=${key}`, { headers: httpHeaders }).pipe(
      catchError(this.handleError));
  }

  public getSettings(): Observable<Setting[]> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-auth': this.getUserToken()
    });

    return this.http.get<Setting[]>(`${environment.apiUrl}/settings`, { headers: httpHeaders }).pipe(
      catchError(this.handleError));
  }

  public deleteSetting(setting: Setting): Observable<ApiResponse> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-auth': this.getUserToken()
    });

    const payload = { delete_setting: true, key_name: setting.key_name };
    return this.http.post<ApiResponse>(environment.apiUrl + '/settings', payload, {
      headers: httpHeaders,
      observe: 'response'
    }).pipe(
      catchError(this.handleError));
  }

  public addSetting(setting: Setting): Observable<ApiResponse> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-auth': this.getUserToken()
    });

    const payload = { add_setting: true, key_name: setting.key_name, data: setting.data, description: setting.description };
    return this.http.post<ApiResponse>(environment.apiUrl + '/settings', payload, {
      headers: httpHeaders,
      observe: 'response'
    }).pipe(
      catchError(this.handleError));
  }

  public updateSetting(setting: Setting): Observable<ApiResponse> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-auth': this.getUserToken()
    });

    return this.http.post<ApiResponse>(`${environment.apiUrl}/settings`, setting, {
      headers: httpHeaders,
      observe: 'response'
    }).pipe(
      catchError(this.handleError));
  }

  public signInUser(email: string, password: string): Observable<HttpResponse<ApiResponse>> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post<HttpResponse<ApiResponse>>(environment.apiUrl + '/authenticate', { username: email, password: password }, {
      headers: httpHeaders,
      observe: 'response'
    }).pipe(
      catchError(this.handleError));
  }

  public updateMyAccount(user_id: number, first_name?: string,
    last_name?: string, timezone?: string, password?: string): Observable<HttpResponse<ApiResponse>> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-auth': this.getUserToken()
    });

    let formFields = {};

    formFields['user_id'] = user_id;
    if (first_name) {
      formFields['first_name'] = first_name;
    }

    if (last_name) {
      formFields['last_name'] = last_name;
    }

    if (timezone) {
      formFields['timezone'] = timezone;
    }

    if (password) {
      formFields['password'] = password;
    }

    return this.http.post<HttpResponse<ApiResponse>>(environment.apiUrl + '/updatemyaccount', formFields, {
      headers: httpHeaders,
      observe: 'response'
    }).pipe(
      catchError(this.handleError));
  }

  public resetPasswordRequest(email: string): Observable<ApiResponse> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post<ApiResponse>(environment.apiUrl + '/passwordresetrequest', {
      username: email,
      host: window.location.host,
      appName: environment.appName
    }, {
      headers: httpHeaders
    }).pipe(
      catchError(this.handleError));
  }

  public resetPassword(resetKey: string, password: string): Observable<ApiResponse> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post<ApiResponse>(environment.apiUrl + '/resetpassword', { password, key: resetKey }, {
      headers: httpHeaders
    }).pipe(
      catchError(this.handleError));
  }

  public getUserToken(): string {
    const previousSessionToken = localStorage.getItem('session_token');
    if (previousSessionToken) {
      return previousSessionToken;
    } else {
      return "";
    }
  }

  public refreshToken(): Observable<HttpResponse<Object>> {
    const httpHeaders = new HttpHeaders({
      'x-auth': this.getUserToken()
    });

    return this.http.post<HttpResponse<Object>>(environment.apiUrl + '/refreshToken', null, {
      headers: httpHeaders,
      observe: 'response'
    }).pipe(
      catchError(this.handleError));
  }

  public getUser(username: string): Observable<HttpResponse<UserLoginRecord>> {
    const httpHeaders = new HttpHeaders({
      'x-auth': this.getUserToken()
    });

    return this.http.get<UserLoginRecord>(environment.apiUrl + `/edituser?u=${encodeURIComponent(username)}`, {
      headers: httpHeaders,
      observe: 'response'
    }).pipe(
      catchError(this.handleError));
  }

  public getUserOptions(): Observable<HttpResponse<UserOptions>> {
    const httpHeaders = new HttpHeaders({
      'x-auth': this.getUserToken()
    });

    return this.http.get<UserOptions>(environment.apiUrl + `/useroptions`, {
      headers: httpHeaders,
      observe: 'response'
    }).pipe(
      catchError(this.handleError));
  }

  public updateUser(user: UserLoginRecord, password?: string): Observable<HttpResponse<ApiResponse>> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-auth': this.getUserToken()
    });

    // Only send back the fields that are permitted to be updated
    let payload = {
      first_name: user.first_name,
      last_name: user.last_name,
      username: user.username,
      locked: user.locked,
      login_attempts: user.login_attempts,
      access_level_id: user.access_level_id,
      timezone: user.timezone
    };

    if (password) {
      payload['password'] = password;
    }

    return this.http.post<HttpResponse<ApiResponse>>(`${environment.apiUrl}/updateuser`, payload, {
      headers: httpHeaders,
      observe: 'response'
    }).pipe(
      catchError(this.handleError));
  }

  public addUser(user: UserLoginRecord): Observable<HttpResponse<UserLoginRecord>> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-auth': this.getUserToken()
    });

    const payload: any = _.cloneDeep(user);
    payload['appName'] = environment.appName;

    return this.http.post<UserLoginRecord>(environment.apiUrl + '/insertuser', payload, { headers: httpHeaders, observe: 'response' }).pipe(
      catchError(this.handleError));
  }

  public deleteUser(user: UserLoginRecord): Observable<ApiResponse> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-auth': this.getUserToken()
    });

    const payload = { username: user.username, delete_user: true };

    return this.http.post<ApiResponse>(`${environment.apiUrl}/updateuser`, payload, { headers: httpHeaders }).pipe(
      catchError(this.handleError));
  }

  public getUsers(): Observable<HttpResponse<UserLoginRecord[]>> {
    const httpHeaders = new HttpHeaders({
      'x-auth': this.getUserToken()
    });

    return this.http.get<UserLoginRecord[]>(environment.apiUrl + "/users", { headers: httpHeaders, observe: 'response' }).pipe(
      catchError(this.handleError));
  }

  public getTimezones(): Observable<HttpResponse<Timezone[]>> {
    const httpHeaders = new HttpHeaders({
      'x-auth': this.getUserToken()
    });

    return this.http.get<Timezone[]>(environment.apiUrl + "/timezones", { headers: httpHeaders, observe: 'response' }).pipe(
      catchError(this.handleError));
  }

  getAlert(alertId: number): Observable<HttpResponse<Alert>> {
    const httpHeaders = new HttpHeaders({
      'x-auth': this.getUserToken()
    });

    return this.http.get<Alert>(environment.apiUrl + `/alerts?id=${alertId}`, { headers: httpHeaders, observe: 'response' }).pipe(
      catchError(this.handleError));
  }

  getAlerts(first: number, rows: number, sortField: string, sortOrder: number, filter: string): Observable<HttpResponse<AlertListing>> {
    const httpHeaders = new HttpHeaders({
      'x-auth': this.getUserToken()
    });

    let querystring = `/alerts?first=${first}&rows=${rows}&sortField=${sortField}&sortOrder=${sortOrder}`;

    if (filter) {
      const encFilter = encodeURIComponent(filter)
      querystring += `&filter=${encFilter}`;
    }

    return this.http.get<AlertListing>(environment.apiUrl + querystring, { headers: httpHeaders, observe: 'response' }).pipe(
      catchError(this.handleError));
  }

  deleteAlert(alertId: number): Observable<ApiResponse> {
    const httpHeaders = new HttpHeaders({
      'x-auth': this.getUserToken()
    });

    return this.http.post<ApiResponse>(environment.apiUrl + `/alerts`, { alert_id: alertId }, { headers: httpHeaders }).pipe(
      catchError(this.handleError));
  }

  getAccountValidationRules(): Observable<HttpResponse<AccountValidationRules>> {
    return this.http.get<AccountValidationRules>(environment.apiUrl + `/accountvalidation`, { observe: 'response' }).pipe(
      catchError(this.handleError));
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////

  handleError(error: HttpErrorResponse) {
    let message = "";
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      message = error.error.message;
      // console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if (error.error && error.error.message) {
        message = error.error.message;
      } else {
        message = error.statusText;
      }
      // console.error(
      //   `Backend returned code ${error.status}, ` +
      //   `body was: ${error.error}`, error);
    }
    // return an observable with a user-facing error message
    return throwError(() => new Error(message));
  }

}
