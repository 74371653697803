import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { ApiService, UserLoginRecord } from 'src/app/service/api.service';

// Must contain uppercase, lowercase and number or symbol
const PASSWORD_REGEX = /^((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;

@Component({
  selector: 'app-modify-user',
  templateUrl: './modify-user.component.html',
  styleUrls: ['./modify-user.component.css']
})
export class ModifyUserComponent implements OnInit {
  newRecord = false;
  dataEntryForm: UntypedFormGroup;
  userRecord: UserLoginRecord = {} as UserLoginRecord;
  errorMessage: string;
  successMessage: string;
  updating = false;
  loading = false;
  layoutService: LayoutService;
  accessLevelOptions: SelectItem[];
  timezoneOptions: SelectItem[];
  minPasswordLength: number;

  constructor(private apiService: ApiService, private confirmService: ConfirmationService,
    private ref: DynamicDialogRef, private config: DynamicDialogConfig) {
  }

  ngOnInit() {
    let id = '';
    if (this.config.data.id !== undefined) {
      id = this.config.data.id;
    }
    this.layoutService = this.config.data.layoutService;

    this.loading = true;
    this.dataEntryForm = new UntypedFormGroup({
      'first_name': new UntypedFormControl(this.userRecord.first_name, [Validators.required, Validators.maxLength(25)]),
      'last_name': new UntypedFormControl(this.userRecord.last_name, [Validators.required, Validators.maxLength(25)]),
      'username': new UntypedFormControl(this.userRecord.username, [Validators.required, Validators.email, Validators.maxLength(255)]),
      'access_level_id': new UntypedFormControl(this.userRecord.access_level_id, Validators.required),
      'timezone': new UntypedFormControl(this.userRecord.timezone, Validators.required),
      'changePassword': new UntypedFormControl(false, null),
      'new_password': new UntypedFormControl({ value: null, disabled: true }, null),
      'repeat_password': new UntypedFormControl({ value: null, disabled: true }, null),
      'locked': new UntypedFormControl(this.userRecord.locked == 1, null)
    });

    if (id) {
      this.newRecord = false;
      this.apiService.getUser(id).subscribe({
        next: (resp) => {
          this.userRecord = resp.body;
          this.timezoneOptions = this.userRecord.timezones_list.map(t => { return { value: t.name, label: t.name } });
          this.accessLevelOptions = this.userRecord.access_level_list.map(a => { return { value: +a.access_level_id, label: a.name } });
          this.minPasswordLength = this.userRecord.min_password_length;
          this.dataEntryForm.controls['first_name'].setValue(this.userRecord.first_name);
          this.dataEntryForm.controls['last_name'].setValue(this.userRecord.last_name);
          this.dataEntryForm.controls['username'].setValue(this.userRecord.username);
          this.dataEntryForm.controls['username'].disable();
          this.dataEntryForm.controls['access_level_id'].setValue(this.userRecord.access_level_id);
          this.dataEntryForm.controls['timezone'].setValue(this.userRecord.timezone);
          this.dataEntryForm.controls['changePassword'].setValue(false);
          this.dataEntryForm.controls['locked'].setValue(this.userRecord.locked == 1);

          this.loading = false;
        }, error: (err) => {
          this.loading = false;
          this.layoutService.onError(err);
        }
      });
    } else {
      this.apiService.getUserOptions().subscribe({
        next: (resp) => {
          this.timezoneOptions = resp.body.timezones_list.map(t => { return { value: t.name, label: t.name } });
          this.accessLevelOptions = resp.body.access_level_list.map(a => { return { value: +a.access_level_id, label: a.name } });
          this.minPasswordLength = resp.body.min_password_length;

          this.loading = false;
        }, error: (err) => {
          this.loading = false;
          this.layoutService.onError(err);
        }
      });

      this.newRecord = true;
      this.loading = false;
    }
  }

  togglePasswordFields() {
console.log('togglePasswordFields .minPasswordLength', this.minPasswordLength)

    const newPasswordControl = this.dataEntryForm.get('new_password');
    const repeatPasswordControl = this.dataEntryForm.get('repeat_password');
    if (this.dataEntryForm.value.changePassword) {
      newPasswordControl.enable();
      newPasswordControl.setValidators(Validators.compose(
        [Validators.required, Validators.minLength(this.minPasswordLength), Validators.maxLength(200)]));
      newPasswordControl.updateValueAndValidity();

      repeatPasswordControl.enable();
      repeatPasswordControl.setValidators(Validators.compose(
        [Validators.required, Validators.minLength(this.minPasswordLength), Validators.pattern(PASSWORD_REGEX), Validators.maxLength(200)]));
      repeatPasswordControl.updateValueAndValidity();
    } else {
      newPasswordControl.disable();
      newPasswordControl.clearValidators();
      newPasswordControl.reset();
      newPasswordControl.updateValueAndValidity();

      repeatPasswordControl.disable();
      repeatPasswordControl.clearValidators();
      repeatPasswordControl.reset();
      repeatPasswordControl.updateValueAndValidity();
    }
  }

  onSave() {
    this.updating = true;
    this.errorMessage = '';

    if (this.newRecord)
      this.userRecord.username = this.dataEntryForm.value.username;

    this.userRecord.first_name = this.dataEntryForm.value.first_name;
    this.userRecord.last_name = this.dataEntryForm.value.last_name;
    this.userRecord.locked = this.dataEntryForm.value.locked;
    this.userRecord.access_level_id = this.dataEntryForm.value.access_level_id;
    this.userRecord.timezone = this.dataEntryForm.value.timezone;

    if (!this.userRecord.locked) {
      this.userRecord.login_attempts = 0;
    }

    let newPassword = null;
    if (this.dataEntryForm.value.changePassword) {
      newPassword = this.dataEntryForm.value.new_password;
    }

    if (this.newRecord) {
      this.apiService.addUser(this.userRecord).subscribe({
        next: (resp) => {
          this.updating = false;
          this.ref.close(true);
        }, error: (err) => {
          this.updating = false;
          this.layoutService.onError(err);
        }
      });
    } else {
      this.apiService.updateUser(this.userRecord, newPassword).subscribe({
        next: (resp) => {
          if (resp.body.ok) {
            if (resp.headers.get('x-auth')) {
              // User updated their own account so update token
              const sessionToken = resp.headers.get('x-auth');
              localStorage.setItem('session_token', sessionToken);
            }
          }
          this.updating = false;
          this.ref.close(true);
        }, error: (err) => {
          this.updating = false;
          this.layoutService.onError(err);
        }
      });
    }
  }

  onCancel() {
    this.ref.close();
  }

  deleteUser(user: UserLoginRecord) {
    this.updating = true;

    this.apiService.deleteUser(user).subscribe({
      next: () => {
        this.updating = false;
        this.ref.close(true);
      }, error: (err) => {
        this.updating = false;
        this.layoutService.onError(err);
      }
    });
  }

  onDeleteClick() {
    this.confirmService.confirm({
      message: 'Are you sure that you want to delete this user account?',
      icon: 'pi pi-exclamation-triangle',
      key: 'discardUser',
      accept: () => {
        this.deleteUser(this.userRecord);
      },
      reject: () => {
        //reject action
      }
    });
  }
}
