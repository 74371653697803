<p-sidebar [(visible)]="rightMenuActive" position="right" [blockScroll]="true" [showCloseIcon]="false" [baseZIndex]="1000" [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'">
    <div class="p-3">
        <div class="flex flex-column mb-5">
            <h6 class="pb-2 mb-2 border-bottom-1 surface-border">ONLINE MEMBERS</h6>
            <div class="flex flex-row flex-wrap gap-1">

            </div>
        </div>
        <div class="flex flex-column mb-5">
            <h6 class="pb-2 mb-2 border-bottom-1 surface-border">LATEST ACTIVITY</h6>
            <div class="flex pt-2">
                <i class="pi pi-images align-self-start p-2 border-1 border-transparent border-circle mr-2" style="background-color:rgba(0,0,0,.12)"></i>
                <div class="flex flex-column">

                </div>
            </div>
            <div class="flex pt-3">
                <i class="pi pi-images align-self-start p-2 border-1 border-transparent border-circle mr-2" style="background-color:rgba(0,0,0,.12)"></i>
                <div class="flex flex-column">

                </div>
            </div>
            <div class="flex pt-3">
                <i class="pi pi-images align-self-start p-2 border-1 border-transparent border-circle mr-2" style="background-color:rgba(0,0,0,.12)"></i>
                <div class="flex flex-column">
                    
                </div>
            </div>
        </div>
        <div class="flex flex-column">
            <h6 class="pb-2 mb-2 border-bottom-1 surface-border">NEXT EVENTS</h6>
            <ul class="m-0 list-none p-0">
                <li class="py-3 px-2 border-round-md hover:surface-hover cursor-pointer"><i class="pi pi-eye mr-3"></i>A/B Test</li>
                <li class="py-3 px-2 border-round-md hover:surface-hover cursor-pointer"><i class="pi pi-video mr-3"></i>Video Shoot</li>
                <li class="py-3 px-2 border-round-md hover:surface-hover cursor-pointer"><i class="pi pi-sitemap mr-3"></i>Board Meeting</li>
                <li class="py-3 px-2 border-round-md hover:surface-hover cursor-pointer"><i class="pi pi-compass mr-3"></i>Q4 Planning</li>
                <li class="py-3 px-2 border-round-md hover:surface-hover cursor-pointer"><i class="pi pi-palette mr-3"></i>Design Training</li>
            </ul>
        </div>
    </div>
</p-sidebar>
