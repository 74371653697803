<p-confirmDialog header="Discard" key="discardUser" icon="pi pi-question-circle" [breakpoints]="{ '960px': '75vw' }"
    [style]="{ width: '20vw' }"></p-confirmDialog>
<div *ngIf="loading" class="col-12 text-center">
    <p-progressSpinner></p-progressSpinner>
</div>
<form *ngIf="!loading" [formGroup]="dataEntryForm">
    <div class="formgrid grid">
        <div class="field col-12 md:col-6">
            <label for="first_name">First Name</label>
            <input pInputText pAutoFocus [autofocus]="true" trimInput type="text" class="w-full" id="first_name"
                name="first_name" formControlName="first_name">
        </div>
        <div class="field col-12 md:col-6">
            <label for="last_name">Last Name</label>
            <input pInputText trimInput type="text" class="w-full" id="last_name" name="last_name"
                formControlName="last_name">
        </div>
        <div class="field col-12 md:col-6">
            <label for="username">Username</label>
            <input pInputText type="text" trimInput class="w-full" id="username" name="username"
                formControlName="username" placeholder="email address">
        </div>
        <div class="field col-12 md:col-6">
            <label for="timezone">Timezone</label>
            <p-dropdown [options]="timezoneOptions" appendTo="body" placeholder="Choose timezone" id="timezone"
                name="timezone" styleClass="w-full" formControlName="timezone" filter="true" showDelay="500"
                pTooltip="All timestamps shown on the site are adjusted to the selected timezone." [escape]="false">>
            </p-dropdown>
        </div>
        <div *ngIf="!newRecord" class="field col-12">
            <div class="formgroup-inline">
                <div class="field-checkbox">
                    <p-checkbox id="changePassword" inputId="changePassword" name="changePassword"
                        formControlName="changePassword" [binary]="true" (onChange)="togglePasswordFields()">
                    </p-checkbox>
                    <label for="changePassword"> Change Password</label>
                </div>
            </div>
        </div>
        <ng-container *ngIf="dataEntryForm.value.changePassword">
            <div class="field col-12">
                <label for="new_password">New Password</label>
                <p-password id="new_password" name="new_password" placeholder="New Password"
                    formControlName="new_password" appendTo="body" [style]="{'width':'100%'}"
                    [inputStyle]="{'width':'100%'}">
                    <ng-template pTemplate="header">
                        <h6 class="text-left">Pick a password</h6>
                    </ng-template>
                    <ng-template pTemplate="footer">
                        <p-divider></p-divider>
                        <p class="mt-2 text-left">Requirements</p>
                        <ul class="pl-2 ml-2 mt-0 text-left" style="line-height: 1.5">
                            <li>At least one lowercase</li>
                            <li>At least one uppercase</li>
                            <li>At least one numeric</li>
                            <li>Minimum {{minPasswordLength}} characters</li>
                        </ul>
                    </ng-template>
                </p-password>
            </div>
            <div class="field col-12">
                <label for="repeat_password">Repeat Password</label>
                <p-password [feedback]="false" id="repeat_password" name="repeat_password" placeholder="Repeat Password"
                    formControlName="repeat_password" [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}">
                </p-password>
            </div>
            <div class="field col-12">
                <p-messages *ngIf="dataEntryForm.errors?.mismatch && dataEntryForm.get('repeat_password').touched"
                    severity="error">
                    <ng-template pTemplate>
                        Input the same password that you used in the first field.
                    </ng-template>
                </p-messages>
                <p-messages *ngIf="formError" severity="error">
                    <ng-template pTemplate>
                        {{formError}}
                    </ng-template>
                </p-messages>
            </div>
        </ng-container>
    </div>
</form>
<p-messages *ngIf="errorMessage" severity="error">
    <ng-template pTemplate>
        {{errorMessage}}
    </ng-template>
</p-messages>
<hr>
<div class="flex flex-wrap">
    <p-button label="Cancel" icon="pi pi-times" class="ml-auto" styleClass="p-button-secondary" [disabled]="updating"
        (onClick)="onCancel()">
    </p-button>
    <p-button label="Save" icon="pi pi-check" class="ml-2" (onClick)="onSave()"
        [disabled]="!dataEntryForm.valid || updating"></p-button>
</div>
