import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './layout/app.layout.component';
import { GameClientsComponent } from './components/game-clients/game-clients.component';
import { PackagesComponent } from './components/packages/packages.component';
import { SoftwareComponent } from './components/software/software.component';
import { AuthGuard } from './service/auth-guard.service';
import { GamesComponent } from './components/games/games.component';
import { UsersComponent } from './components/users/users.component';
import { SettingsMgrComponent } from './components/settings-mgr/settings-mgr.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { LoginComponent } from './components/login/login.component';
import { environment } from 'src/environments/environment';
import { RoleGuardService } from './service/role-guard.service';
import { AlertsComponent } from './components/alerts/alerts.component';

const ACL_ADMINISTRATOR = 1; // 1	Administrator
const ACL_STAFF = 2;         // 2	Staff

const routerOptions: ExtraOptions = {
  anchorScrolling: 'enabled'
};

const routes: Routes = [
  {
    path: '', component: AppLayoutComponent,
    children: [
      { path: '', title: 'Game Clients', component: GameClientsComponent, canActivate: [AuthGuard] },
      { path: 'packages', title: 'Game Updates', component: PackagesComponent, canActivate: [AuthGuard] },
      { path: 'software', title: 'Software', component: SoftwareComponent, canActivate: [AuthGuard] },
      { path: 'gps', title: 'Game Provisioning', component: GamesComponent, canActivate: [AuthGuard] },
      { path: 'alerts', title: 'Game Alerts', component: AlertsComponent, canActivate: [AuthGuard] },
      {
        path: 'users', title: 'User Management', component: UsersComponent, canActivate: [RoleGuardService],
        data: { expectedAccessLevels: [ACL_ADMINISTRATOR] }
      },
      {
        path: 'settings', title: 'Site Settings', component: SettingsMgrComponent, canActivate: [RoleGuardService],
        data: { expectedAccessLevels: [ACL_ADMINISTRATOR] }
      }
    ]
  },
  { path: 'login', title: environment.appName, component: LoginComponent },
  { path: 'forgot-password', title: 'Forgot Password', component: ForgotPasswordComponent },
  { path: 'resetpasswordprompt', title: 'Password Reset', component: ResetPasswordComponent },
  { path: 'notfound', loadChildren: () => import('./components/notfound/notfound.module').then(m => m.NotfoundModule) },
  { path: '**', redirectTo: '/notfound' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
