import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ApiService, Setting } from 'src/app/service/api.service';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { ModifySettingComponent } from './modify-setting/modify-setting.component';

@Component({
  selector: 'app-settings-mgr',
  templateUrl: './settings-mgr.component.html',
  styleUrls: ['./settings-mgr.component.scss']
})
export class SettingsMgrComponent implements OnInit {
  settings: Setting[];
  dialogRef: DynamicDialogRef;
  loading = false;
  totalRows = 0;
  sortField = "key_name";
  rowsPerPage = 20;
  sortOrder = 1;
  selectedRow: any;

  constructor(private apiService: ApiService, private confirmService: ConfirmationService,
    private dialogService: DialogService, public layoutService: LayoutService) { }

  ngOnInit(): void {
    this.refreshData();
  }

  getAddButtonLabel(): string {
    if (this.layoutService.isMobile())
      return "";
    else
      return "Add Setting";
  }

  refreshData() {
    this.apiService.getSettings().subscribe({
      next: (resp) => {
        this.settings = resp;
      }, error: (err) => {
        this.layoutService.onError(err);
      }
    });
  }

  onRowSelect(event: any) {
    this.dialogRef = this.dialogService.open(ModifySettingComponent, {
      header: 'Edit Setting',
      closable: false,
      closeOnEscape: false,
      draggable: true,
      width: this.layoutService.isMobile() ? "100%" : "30vw",
      data: {
        key: this.selectedRow.key_name,
        layoutService: this.layoutService
      }
    });

    this.dialogRef.onClose.subscribe((created: boolean) => {
      if (created) {
        this.refreshData();
      }
      this.selectedRow = null;
    });
  }

  onAdd() {
    this.dialogRef = this.dialogService.open(ModifySettingComponent, {
      header: 'Add Setting',
      closable: false,
      closeOnEscape: false,
      draggable: true,
      width: this.layoutService.isMobile() ? "100%" : "30vw",
      data: {
        layoutService: this.layoutService
      }
    });

    this.dialogRef.onClose.subscribe((created: boolean) => {
      if (created) {
        this.refreshData();
      }
    });
  }

  getPageHeight() {
    if (this.layoutService.isMobile())
      return '100%';
    else return "calc(100vh - 27vh)";
  }
}
