import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { EditGameClient, GameAudit, UpdateGameClient } from 'src/app/api/game-client';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { ApiService } from 'src/app/service/api.service';
import { ReportViewerComponent } from '../report-viewer/report-viewer.component';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EditGameSettingsComponent } from '../edit-game-settings/edit-game-settings.component';

@Component({
  selector: 'app-edit-game-client',
  templateUrl: './edit-game-client.component.html',
  styleUrls: ['./edit-game-client.component.scss']
})
export class EditGameClientComponent implements OnInit {
  firstLoad = true;
  dataEntryForm: UntypedFormGroup;
  record: EditGameClient = {} as EditGameClient;
  errorMessage: string;
  successMessage: string;
  updating = false;
  loading = false;
  maxFilesize = 20 * 1024 * 1024;
  weeklyOptions: SelectItem[];
  auditScheduleOptions: SelectItem[];
  auditFrequencyOptions: SelectItem[];
  monthlyOptions: SelectItem[];
  dialogRef: DynamicDialogRef;
  layoutService: LayoutService; // Reference to component passed in through DynamicDialogConfig

  formChanged = false;
  uploadHelpDialog = false;

  constructor(private apiService: ApiService, private router: Router,
    private confirmService: ConfirmationService, private dialogService: DialogService, private config: DynamicDialogConfig,
    private ref: DynamicDialogRef, private activatedRoute: ActivatedRoute) {
    this.auditScheduleOptions = [
      { value: "", label: "None" },
      { value: "Ad hoc", label: "Ad hoc" },
      { value: "Recurring", label: "Recurring" }
    ];

    this.auditFrequencyOptions = [
      { value: "Daily", label: "Daily" },
      { value: "Weekly", label: "Weekly" },
      { value: "Monthly", label: "Monthly" }
    ];

    this.weeklyOptions = [
      { value: 0, label: "Sunday" },
      { value: 1, label: "Monday" },
      { value: 2, label: "Tuesday" },
      { value: 3, label: "Wednesday" },
      { value: 4, label: "Thursday" },
      { value: 5, label: "Friday" },
      { value: 6, label: "Saturday" }
    ];

    this.monthlyOptions = [
      { value: "1", label: "First Day" },
      { value: "15", label: "15th" },
      { value: "last", label: "Last Day" }
    ];
  }

  ngOnInit() {
    let id = null;
    if (this.config.data.id !== undefined) {
      id = this.config.data.id;
      this.layoutService = this.config.data.layoutService;
    }

    this.loading = true;

    this.dataEntryForm = new UntypedFormGroup({
      'game': new UntypedFormControl({ value: null, disabled: true }),
      'game_version': new UntypedFormControl({ value: null, disabled: true }),
      'mac_address': new UntypedFormControl({ value: null, disabled: true }),
      'location_name': new UntypedFormControl({ value: null, disabled: true }),
      'drone_version': new UntypedFormControl({ value: null, disabled: true }),
      'check_in_time': new UntypedFormControl({ value: null, disabled: true }),
      'package_id': new UntypedFormControl({ value: null, disabled: true }),
      'public_ip_address': new UntypedFormControl({ value: null, disabled: true }),
      'local_ip_address': new UntypedFormControl({ value: null, disabled: true }),
      'schedule_type': new UntypedFormControl(""),
      'schedule_frequency': new UntypedFormControl(null),
      'schedule_options_monthly': new UntypedFormControl(null),
      'schedule_options_weekly': new UntypedFormControl(null),
      'audit_id': new UntypedFormControl(null),
      'clear_current_audits': new UntypedFormControl(false),
      'clear_lifetime_audits': new UntypedFormControl(false),
      'update_settings': new UntypedFormControl(false),
      'delete_settings': new UntypedFormControl(false),
    });

    if (id) {
      this.apiService.getGameClient(id).subscribe({
        next: (resp) => {
          this.record = resp.body as EditGameClient;

          this.dataEntryForm.controls['game'].setValue(this.record.name);
          this.dataEntryForm.controls['game_version'].setValue(this.record.game_version);
          this.dataEntryForm.controls['mac_address'].setValue(this.record.mac_address);
          this.dataEntryForm.controls['location_name'].setValue(this.record.location_name);
          this.dataEntryForm.controls['drone_version'].setValue(this.record.drone_version);
          this.dataEntryForm.controls['check_in_time'].setValue(this.record.check_in_time);
          this.dataEntryForm.controls['package_id'].setValue(this.record.package_id);
          this.dataEntryForm.controls['public_ip_address'].setValue(this.record.public_ip_address);
          this.dataEntryForm.controls['local_ip_address'].setValue(this.record.local_ip_address);
          this.dataEntryForm.controls['schedule_type'].setValue(this.record.schedule_type ? this.record.schedule_type : "");
          this.dataEntryForm.controls['schedule_frequency'].setValue(this.record.schedule_frequency);
          this.dataEntryForm.controls['clear_current_audits'].setValue(this.record.clear_current_audits);
          this.dataEntryForm.controls['clear_lifetime_audits'].setValue(this.record.clear_lifetime_audits);
          this.dataEntryForm.controls['update_settings'].setValue(this.record.update_settings);

          if (this.record.schedule_type === 'Recurring') {
            if (this.record.schedule_frequency === 'Weekly') {
              this.dataEntryForm.controls['schedule_options_weekly'].setValue(this.record.schedule_options);
            } else if (this.record.schedule_frequency === 'Monthly') {
              this.dataEntryForm.controls['schedule_options_monthly'].setValue(this.record.schedule_options);
            }
          }

          if (this.record.audits.length) {
            this.dataEntryForm.controls['audit_id'].setValue(this.record.audits[0].audit_id);
          }

          this.loading = false;

          this.dataEntryForm.valueChanges.subscribe(val => {
            this.formChanged = true;
          });

        }, error: (err) => {
          this.layoutService.onError(err);
          setTimeout(() => this.ref.close(), 2000);
        }
      });
    } else {
      this.loading = false;

      this.dataEntryForm.valueChanges.subscribe(val => {
        this.formChanged = true;
      });
    }
  }

  scheduleTypeChanged(event: any) {
    if (this.dataEntryForm.value.schedule_type === 'Recurring') {
      this.dataEntryForm.get('schedule_frequency').setValidators(Validators.required);
    } else {
      this.dataEntryForm.get('schedule_frequency').clearValidators();
      this.dataEntryForm.get('schedule_options_monthly').reset();
      this.dataEntryForm.get('schedule_options_monthly').clearValidators();
      this.dataEntryForm.get('schedule_options_weekly').reset();
      this.dataEntryForm.get('schedule_options_weekly').clearValidators();
      this.dataEntryForm.get('schedule_options_monthly').updateValueAndValidity();
      this.dataEntryForm.get('schedule_options_weekly').updateValueAndValidity();
    }
    this.dataEntryForm.get('schedule_frequency').updateValueAndValidity();
  }

  scheduleFrequencyChanged(event: any) {
    if (this.dataEntryForm.value.schedule_frequency === 'Weekly') {
      this.dataEntryForm.get('schedule_options_weekly').setValidators(Validators.required);
      this.dataEntryForm.get('schedule_options_weekly').reset();

      this.dataEntryForm.get('schedule_options_monthly').clearValidators();
      this.dataEntryForm.get('schedule_options_monthly').reset();
      this.dataEntryForm.get('schedule_options_monthly').updateValueAndValidity();
    } else if (this.dataEntryForm.value.schedule_frequency === 'Monthly') {
      this.dataEntryForm.get('schedule_options_monthly').setValidators(Validators.required);
      this.dataEntryForm.get('schedule_options_monthly').reset();

      this.dataEntryForm.get('schedule_options_weekly').clearValidators();
      this.dataEntryForm.get('schedule_options_weekly').reset();
      this.dataEntryForm.get('schedule_options_weekly').updateValueAndValidity();
    } else {
      this.dataEntryForm.get('schedule_options_monthly').reset();
      this.dataEntryForm.get('schedule_options_monthly').clearValidators();
      this.dataEntryForm.get('schedule_options_monthly').updateValueAndValidity();

      this.dataEntryForm.get('schedule_options_weekly').reset();
      this.dataEntryForm.get('schedule_options_weekly').clearValidators();
      this.dataEntryForm.get('schedule_options_weekly').updateValueAndValidity();
    }
  }

  submitForm() {
    this.updating = true;
    this.errorMessage = '';
    let gameClient = {} as UpdateGameClient;

    let schedule_options = null;
    let schedule_frequency = null;
    if (this.dataEntryForm.value.schedule_type === 'Recurring') {
      schedule_frequency = this.dataEntryForm.value.schedule_frequency;
      if (this.dataEntryForm.value.schedule_frequency === 'Weekly') {
        schedule_options = this.dataEntryForm.value.schedule_options_weekly;
      } else if (this.dataEntryForm.value.schedule_frequency === 'Monthly') {
        schedule_options = this.dataEntryForm.value.schedule_options_monthly;
      }
    }

    gameClient.mac_address = this.record.mac_address;
    gameClient.schedule_type = this.dataEntryForm.value.schedule_type;
    gameClient.schedule_frequency = schedule_frequency;
    gameClient.schedule_options = schedule_options;
    gameClient.clear_current_audits = this.dataEntryForm.value.clear_current_audits;
    gameClient.clear_lifetime_audits = this.dataEntryForm.value.clear_lifetime_audits;
    gameClient.update_settings = this.dataEntryForm.value.update_settings;
    gameClient.delete_settings = this.dataEntryForm.value.delete_settings;
    this.apiService.updateGameClient(gameClient).subscribe({
      next: (resp) => {
        this.updating = false;
        this.ref.close(true);
      }, error: (err) => {
        this.updating = false;
        this.layoutService.onError(err);
      }
    });
  }

  // Checks if changes have been made to form values. If so, opens the cancel modal. If not, reroutes directly to the correct page.
  verifyCancelAction(event) {
    // Ignore change in audit_id field since this isn't used
    const changedFields = this.getDirtyValues(this.dataEntryForm);
    if (!this.formChanged || (Object.keys(changedFields).length === 1 && 'audit_id' in changedFields)) {
      this.ref.close(false);
    } else {
      this.confirmService.confirm({
        message: "Are you sure you'd like to discard your changes?",
        header: 'Game Client Modified',
        icon: 'pi pi-exclamation-triangle',
        key: 'discardClient',
        accept: () => {
          this.ref.close(false);
        },
        reject: () => {
          //reject action
        }
      });
    }
  }

  getDirtyValues(form: any) {
    let dirtyValues = {};

    Object.keys(form.controls)
      .forEach(key => {
        const currentControl = form.controls[key];

        if (currentControl.dirty) {
          if (currentControl.controls)
            dirtyValues[key] = this.getDirtyValues(currentControl);
          else
            dirtyValues[key] = currentControl.value;
        }
      });

    return dirtyValues;
  }

  onDeleteClientClick() {
    this.confirmService.confirm({
      message: 'If you delete this game client, all audits and settings linked to this client will be deleted from this site. If the game is still in service, the game client will return to the list of clients the next time it reports in. Are you sure you want to continue deleting the client?',
      header: 'Delete Game Client',
      icon: 'pi pi-exclamation-triangle',
      key: 'discardClient',
      accept: () => {
        this.updating = true;
        let gameClient = {} as UpdateGameClient;
        gameClient.mac_address = this.record.mac_address;
        gameClient.deleteClient = true;
        this.apiService.deleteGameClient(gameClient).subscribe({
          next: (resp) => {
            this.updating = false;
            this.ref.close(true);
          }, error: (err) => {
            this.updating = false;
            this.layoutService.onError(err);
          }
        });
      },
      reject: () => {
        //reject action
      }
    });
  }

  onViewCurrentAuditClick() {
    this.dialogRef = this.dialogService.open(ReportViewerComponent, {
      header: 'Current Audit',
      width: this.layoutService.isMobile() ? "100%" : "30vw",
      closable: true,
      closeOnEscape: true,
      draggable: true,
      data: {
        layoutService: this.layoutService,
        id: this.dataEntryForm.value.audit_id,
        mac_address: this.record.mac_address,
        type: 'audit_filename',
        filename: this.generateFilename('audit')
      }
    });
  }

  generateFilename(type, includeDate = true): string {
    let selectedAudit = this.getSelectedAudit();
    let filename = null;
    if (selectedAudit !== null) {
      if (includeDate)
        filename = `${this.record.name}_${this.record.location_name}_${selectedAudit.formattedAuditDate}_${type}`;
      else
        filename = `${this.record.name}_${this.record.location_name}_${type}`;

      filename = filename.replaceAll("/", "-").replaceAll(" ", "_").replaceAll(":", "");
    }

    return filename;
  }

  getSelectedAudit(): GameAudit {
    let selectedAudit = this.record.audits.filter(a => a.audit_id === this.dataEntryForm.value.audit_id);
    if (selectedAudit.length) {
      return selectedAudit[0];
    } else {
      return null;
    }
  }

  onViewLifetimeAuditClick() {
    this.dialogRef = this.dialogService.open(ReportViewerComponent, {
      header: 'Lifetime Audit',
      width: this.layoutService.isMobile() ? "100%" : "30vw",
      closable: true,
      closeOnEscape: true,
      draggable: true,
      data: {
        layoutService: this.layoutService,
        id: this.dataEntryForm.value.audit_id,
        mac_address: this.record.mac_address,
        type: 'lifetime_audit_filename',
        filename: this.generateFilename('lifetime_audit')
      }
    });
  }

  onViewGameLogClick() {
    let selectedAudit = this.getSelectedAudit();
    this.dialogRef = this.dialogService.open(ReportViewerComponent, {
      header: 'Game Log',
      width: this.layoutService.isMobile() ? "100%" : "85vw",
      closable: true,
      closeOnEscape: true,
      draggable: true,
      data: {
        layoutService: this.layoutService,
        id: this.dataEntryForm.value.audit_id,
        mac_address: this.record.mac_address,
        type: 'log',
        audit_file: selectedAudit.audit_file
      }
    });
  }

  onDownloadAuditClick() {
    let selectedAudit = this.getSelectedAudit();
    if (selectedAudit !== null) {
      window.location.href = selectedAudit.audit_file;
    }
  }

  onViewCurrentSettingsClick() {
    this.dialogRef = this.dialogService.open(ReportViewerComponent, {
      header: 'Current Settings',
      width: this.layoutService.isMobile() ? "100%" : "30vw",
      closable: true,
      closeOnEscape: true,
      draggable: true,
      data: {
        layoutService: this.layoutService,
        id: null,
        mac_address: this.record.mac_address,
        type: 'settings_filename',
        filename: this.generateFilename('settings', false)
      }
    });
  }

  onViewModifiedSettingsClick() {
    this.dialogRef = this.dialogService.open(ReportViewerComponent, {
      header: 'Modified Settings',
      width: this.layoutService.isMobile() ? "100%" : "30vw",
      closable: true,
      closeOnEscape: true,
      draggable: true,
      data: {
        layoutService: this.layoutService,
        id: null,
        mac_address: this.record.mac_address,
        type: 'settings_filename',
        modified: true,
        filename: this.generateFilename('modified_settings', false)
      }
    });
  }

  onEditSettingsClick() {
    this.dialogRef = this.dialogService.open(EditGameSettingsComponent, {
      header: 'Edit Settings',
      width: this.layoutService.isMobile() ? "100%" : "30vw",
      closable: false,
      closeOnEscape: false,
      draggable: true,
      data: {
        layoutService: this.layoutService,
        id: null,
        mac_address: this.record.mac_address
      }
    });

    this.dialogRef.onClose.subscribe((updated: boolean) => {
      if (updated) {
        this.record.settings_file_exists = true;
        this.confirmService.confirm({
          message: 'Game settings were saved. Do you want to release the updated settings to the game now?',
          header: 'Update Settings',
          icon: 'pi pi-question-circle',
          key: 'discardClient',
          closeOnEscape: false,
          accept: () => {
            this.dataEntryForm.controls['update_settings'].setValue(true);
          },
          reject: () => {
            //reject action
          }
        });
      }
    });
  }
}
