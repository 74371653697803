import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, last, map, Observable, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';


export interface GameEntity {
  game_id: number;
  name: string;
  subassemblies?: GameSubassemblyEntity[];
}

export interface GameSubassemblyEntity {
  subassembly_id: number;
  game_id: number;
  name: string;
  packages?: GamePackageEntity[];
}

export interface GamePackageEntity {
  game_package_id: number;
  subassembly_id: number;
  version_num: string;
  version_name: string;
  package_filename: string;
  package_key: string;
  modified: string;
}

export interface GpsClientRecord {
  game_provisioning_client_id: number;
  local_ip_address: string;
  public_ip_address: string;
  mac_address: string;
  check_in_time: string;
  game_installed: string;
  serial_num: string;
  cpu_serial: string;
  sd_card_serial: string;
  employee: string;
}

export interface GpsClientLogRecord {
  gps_client_log_id?: number;
  game_provisioning_client_id?: number;
  submitted: string;
  log_file: string;
}

interface RequestMessage {
  action: string;
  key: string;
  ip: string;
  mac: string;
  cpu_serial: string;
  sd_card_serial: string;
  filtering?: any;
}

interface ApiResponse {
  data?: any[];
  error?: string;
  game_id?: number;
  subassembly_id?: number;
  ok?: boolean;
}

interface ApiErrorResponse {
  authenticated: boolean;
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class GameProvisioningService {
  constructor(private http: HttpClient) { }

  getGames(first: number, rows: number, sortField: string, sortOrder: number, filter: string): Observable<HttpResponse<GameEntity[]>> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-auth': this.getUserToken()
    });

    const message = <RequestMessage>{
      action: 'gps_list_games_for_mgr'
    };

    let querystring = `/ws?first=${first}&rows=${rows}&sortField=${sortField}&sortOrder=${sortOrder}`;

    if (filter) {
      const encFilter = encodeURIComponent(filter)
      querystring += `&filter=${encFilter}`;
    }

    return this.http.post<GameEntity[]>(environment.apiUrl + querystring, message, { headers: httpHeaders, observe: 'response' }).pipe(
      catchError(this.handleError));
  }

  addGame(gameName: string): Observable<HttpResponse<GameEntity>> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-auth': this.getUserToken()
    });

    const message = {
      action: 'gps_add_game',
      game_name: gameName
    };

    let url = `${environment.apiUrl}/ws`;
    return this.http.post<GameEntity>(url, message, { headers: httpHeaders, observe: 'response' }).pipe(
      catchError(this.handleError));
  }

  updateGame(game: GameEntity): Observable<HttpResponse<GameEntity>> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-auth': this.getUserToken()
    });

    const message = {
      action: 'gps_update_game',
      game_id: game.game_id,
      game_name: game.name
    };

    let url = `${environment.apiUrl}/ws`;
    return this.http.post<GameEntity>(url, message, { headers: httpHeaders, observe: 'response' }).pipe(
      catchError(this.handleError));
  }

  addSubassembly(subassembly: GameSubassemblyEntity): Observable<HttpResponse<GameSubassemblyEntity>> {
    /*
    "action" : "gps_add_subassembly",
           "game_id": "",
           "subassembly": ""
    */
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-auth': this.getUserToken()
    });

    const message = {
      action: 'gps_add_subassembly',
      game_id: subassembly.game_id,
      name: subassembly.name
    };

    let url = `${environment.apiUrl}/ws`;
    return this.http.post<GameSubassemblyEntity>(url, message, { headers: httpHeaders, observe: 'response' }).pipe(
      catchError(this.handleError));
  }

  updateSubassembly(subassembly: GameSubassemblyEntity): Observable<HttpResponse<GameSubassemblyEntity>> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-auth': this.getUserToken()
    });

    const message = {
      action: 'gps_update_subassembly',
      subassembly_id: subassembly.subassembly_id,
      game_id: subassembly.game_id,
      name: subassembly.name
    };

    let url = `${environment.apiUrl}/ws`;
    return this.http.post<GameSubassemblyEntity>(url, message, { headers: httpHeaders, observe: 'response' }).pipe(
      catchError(this.handleError));
  }

  addGameSubassemblies(game: GameEntity, subassemblies: GameSubassemblyEntity[]): Observable<HttpResponse<GameEntity>> {
    /*
    "action" : "gps_add_game_subassemblies",
           "game_name?": "",
           "game_id"?: "",
           "subassemblies": ["", "", ...]
    */
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-auth': this.getUserToken()
    });

    const subassemblyList: string[] = [];
    subassemblies.forEach(element => {
      subassemblyList.push(element.name);
    });

    const message = {
      action: 'gps_add_game_subassemblies',
      game_name: game.name,
      game_id: game.game_id,
      subassemblies: subassemblyList
    };

    let url = `${environment.apiUrl}/ws`;
    return this.http.post<GameEntity>(url, message, { headers: httpHeaders, observe: 'response' }).pipe(
      catchError(this.handleError));
  }

  getCompleteGame(gameId: number): Observable<HttpResponse<GameEntity>> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-auth': this.getUserToken()
    });

    const message = {
      action: 'gps_get_complete_game',
      game_id: gameId
    };

    let url = `${environment.apiUrl}/ws`;
    return this.http.post<GameEntity>(url, message, { headers: httpHeaders, observe: 'response' }).pipe(
      catchError(this.handleError));
  }

  // addPackage(gamePackage: GamePackageEntity, packageFile: File): Observable<any> {
  addPackage(gamePackage: FormData): Observable<HttpEvent<any>> {
    const httpHeaders = new HttpHeaders({
      'x-auth': this.getUserToken()
    });

    let url = `${environment.apiUrl}/ws`;
    // return this.http.post<GamePackageEntity>(url, gamePackage, { headers: httpHeaders, observe: 'events', reportProgress: true }).pipe(
    //   catchError(this.handleError));

    // return this.http.post<any>(url, gamePackage, { headers: httpHeaders, reportProgress: true }).pipe(
    //   catchError(this.handleError));

    const req = new HttpRequest('POST', url, gamePackage, {
      headers: httpHeaders,
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }

  updatePackage(gamePackage: FormData): Observable<HttpEvent<any>> {
    const httpHeaders = new HttpHeaders({
      'x-auth': this.getUserToken()
    });

    let url = `${environment.apiUrl}/ws`;
    // return this.http.post<GamePackageEntity>(url, gamePackage, { headers: httpHeaders, observe: 'events', reportProgress: true }).pipe(
    //   catchError(this.handleError));

    // return this.http.post<any>(url, gamePackage, { headers: httpHeaders, reportProgress: true }).pipe(
    //   catchError(this.handleError));

    const req = new HttpRequest('POST', url, gamePackage, {
      headers: httpHeaders,
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }

  deleteSubassembly(subassemblyId: number): Observable<HttpResponse<any>> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-auth': this.getUserToken()
    });

    const message = {
      action: 'gps_delete_subassembly',
      subassembly_id: subassemblyId
    };

    let url = `${environment.apiUrl}/ws`;
    return this.http.post<any>(url, message, { headers: httpHeaders, observe: 'response' }).pipe(
      catchError(this.handleError));
  }

  deletePackage(gamePackageId: number): Observable<HttpResponse<any>> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-auth': this.getUserToken()
    });

    const message = {
      action: 'gps_delete_package',
      game_package_id: gamePackageId
    };

    let url = `${environment.apiUrl}/ws`;
    return this.http.post<any>(url, message, { headers: httpHeaders, observe: 'response' }).pipe(
      catchError(this.handleError));
  }

  deleteGame(gameId: number): Observable<HttpResponse<any>> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-auth': this.getUserToken()
    });

    const message = {
      action: 'gps_delete_game',
      game_id: gameId
    };

    let url = `${environment.apiUrl}/ws`;
    return this.http.post<any>(url, message, { headers: httpHeaders, observe: 'response' }).pipe(
      catchError(this.handleError));
  }

  getInstalls(first: number, rows: number, sortField: string, sortOrder: number, filter: string): Observable<HttpResponse<GpsClientRecord[]>> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-auth': this.getUserToken()
    });

    const message = {
      action: 'gps_get_installs',
    };

    let querystring = `/ws?first=${first}&rows=${rows}&sortField=${sortField}&sortOrder=${sortOrder}`;

    if (filter) {
      const encFilter = encodeURIComponent(filter)
      querystring += `&filter=${encFilter}`;
    }

    return this.http.post<GpsClientRecord[]>(environment.apiUrl + querystring, message, { headers: httpHeaders, observe: 'response' }).pipe(
      catchError(this.handleError));
  }

  getGpsClientLogs(gpsClientId: number): Observable<HttpResponse<GpsClientLogRecord[]>> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-auth': this.getUserToken()
    });

    const message = {
      action: 'gps_get_gps_client_logs',
      game_provisioning_client_id: gpsClientId
    };

    let url = `${environment.apiUrl}/ws`;
    return this.http.post<GpsClientLogRecord[]>(url, message, { headers: httpHeaders, observe: 'response' }).pipe(
      catchError(this.handleError));
  }

  deleteGpsClient(gpsClientId: number): Observable<HttpResponse<any>> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-auth': this.getUserToken()
    });

    const message = {
      action: 'gps_delete_gps_client',
      game_provisioning_client_id: gpsClientId
    };

    let url = `${environment.apiUrl}/ws`;
    return this.http.post<any>(url, message, { headers: httpHeaders, observe: 'response' }).pipe(
      catchError(this.handleError));
  }

  downloadLog(url: string): Observable<string> {
    // console.log('downloadLog', url);

    return this.http.get(url, { responseType: 'text' });
  }

  public getUserToken(): string {
    const previousSessionToken = localStorage.getItem('session_token');
    if (previousSessionToken) {
      return previousSessionToken;
    } else {
      return "";
    }
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////

  handleError(error: HttpErrorResponse) {
    let message = "";
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      message = error.error.message;
      // console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if (error.error) {
        message = error.error.error;
      } else {
        message = error.statusText;
      }
      // console.error(
      //   `Backend returned code ${error.status}, ` +
      //   `body was: ${error.error}`, error);
    }
    // return an observable with a user-facing error message
    return throwError(() => new Error(message));
  }
}
