import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Table } from 'primeng/table';
import { LazyLoadEvent, MenuItem } from 'primeng/api';
import * as _ from 'lodash';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AlertViewerComponent } from './alert-viewer/alert-viewer.component';
import { Alert, ApiService } from 'src/app/service/api.service';
import { LayoutService } from 'src/app/layout/service/app.layout.service';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit, OnDestroy {
  @ViewChild('dt', { static: false }) alertTable: Table;
  alerts: Alert[] = [];
  selectedAlert: Alert;
  csvExportFilename = '';

  dialogRef: DynamicDialogRef;
  totalAlerts = 0;

  loading: boolean;

  // LAZY LOADING CODE
  firstLoad = true;
  rowsPerPage = 20;
  sortField = 'alert_time';
  sortOrder = -1; // Descending
  searchTerm: string;
  // stateKey = 'alertTable';
  // END LAZY LOADING CODE

  filterTimeout: any;
  filterValue: string = '';
  timerId: any;

  items: MenuItem[];
  activeItem: MenuItem;
  queryParams: any;

  columns = [
    { field: 'alert_time', header: 'Timestamp', sort_field: 'alert_time' },
    { field: 'public_ip_address', header: 'Public IP', sort_field: 'public_ip_address' },
    { field: 'local_ip_address', header: 'Local IP', sort_field: 'local_ip_address' },
    { field: 'log_message', header: 'Log Message', sort_field: 'log_message' }
  ];

  constructor(private apiService: ApiService,
    public layoutService: LayoutService, private dialogService: DialogService,
    private route: ActivatedRoute) {
    this.loading = false;
  }

  ngOnInit(): void {
    this.timerId = setInterval(() => {
      this.forceUpdate();
    }, 1000 * 60 * 15);
  }

  ngOnDestroy() {
    clearInterval(this.timerId);
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  getPageHeight() {
    if (this.layoutService.isMobile())
      return '100%';
    else return "calc(100vh - 19vh)";
  }

  forceUpdate() {
    this.alertTable.filter(this.searchTerm, 'search', 'contains');
  }

  loadDataLazy(event: LazyLoadEvent) {
    let filters = {};
    this.loading = true;
    let sf = event.sortField || this.sortField;
    let filter = event.filters['search'] ? event.filters['search'].value : null;
    if (filter)
      filters['s'] = filter;

    this.apiService.getAlerts(event.first, event.rows, sf, event.sortOrder, filter).subscribe({
      next: (resp) => {
        if (resp.body) {
          this.alerts = resp.body.rows;
          this.totalAlerts = resp.body.totalRows;
        } else {
          this.alerts = [];
          this.totalAlerts = 0;
        }
        this.loading = false
      }, error: (err) => {
        this.layoutService.onError(err);
        this.loading = false;
      }
    });
  }

  onGlobalFilterBodyChange(event, dt) {
    dt.filter(event.srcElement.value, 'search', 'contains');
  }

  handleRowClick() {
    this.dialogRef = this.dialogService.open(AlertViewerComponent, {
      header: 'Alert Detail',
      width: this.layoutService.isMobile() ? "100%" : "30vw",
      closable: true,
      closeOnEscape: true,
      draggable: true,
      data: {
        layoutService: this.layoutService,
        id: this.selectedAlert.alert_id
      }
    });

    this.dialogRef.onClose.subscribe((created: boolean) => {
      this.selectedAlert = {} as Alert;
      if (created) {
        this.forceUpdate();
      }
    });
  }
}