import { Component, ElementRef } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { ColorScheme, LayoutService } from './service/app.layout.service';
import { AuthService } from '../service/auth.service';

@Component({
  selector: 'app-menu-profile',
  templateUrl: './app.menuprofile.component.html',
  animations: [
    trigger('menu', [
      transition('void => inline', [
        style({ height: 0 }),
        animate('400ms cubic-bezier(0.86, 0, 0.07, 1)', style({ opacity: 1, height: '*' })),
      ]),
      transition('inline => void', [
        animate('400ms cubic-bezier(0.86, 0, 0.07, 1)', style({ opacity: 0, height: '0' }))
      ]),
      transition('void => overlay', [
        style({ opacity: 0, transform: 'scaleY(0.8)' }),
        animate('.12s cubic-bezier(0, 0, 0.2, 1)')
      ]),
      transition('overlay => void', [
        animate('.1s linear', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class AppMenuProfileComponent {

  constructor(public layoutService: LayoutService, private authService: AuthService,
    public el: ElementRef) { }

  toggleMenu() {
    this.layoutService.onMenuProfileToggle();
  }

  onToggleTheme() {
    this.layoutService.onToggleTheme();
  }

  get isHorizontal() {
    return this.layoutService.isHorizontal() && this.layoutService.isDesktop();
  }

  get menuProfileActive(): boolean {
    return this.layoutService.state.menuProfileActive;
  }

  get menuProfilePosition(): string {
    return this.layoutService.config.menuProfilePosition;
  }

  get isTooltipDisabled(): boolean {
    return !this.layoutService.isSlim();
  }

  get fullname(): string {
    return this.authService.getUserAccount().first_name + ' ' + this.authService.getUserAccount().last_name;
  }

  get userAccessLevel(): string {
    return this.authService.getUserAccount().access;
  }
}
