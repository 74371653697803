import { version } from "./version";

export const environment = {
  production: true,

  appName: 'Coastal Amusements',

  // Version of the application, taken from package.json
  version: version,

  // Number of seconds before session token expires that it is permitted to
  // refresh it
  secondsBeforeTokenExpiration: 5400,

  // URL of API
  apiUrl: 'https://coastal.restlessmindsstudio.com'
};
