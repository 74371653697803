<div class="flex gap-2 mb-2">
    <span class="material-symbols-outlined" style="font-size: 2.5rem">content_copy</span><span
        class="font-semibold my-auto text-2xl">Game Provisioning System (GPS)</span>
</div>
<p-tabView (onChange)="tabChanged($event)">
    <p-tabPanel header="GPS Packages">
        <div [style.height]="getPageHeight()">
            <p-table #dt [columns]="columns" [value]="rows" selectionMode="single"
                [scrollable]="!layoutService.isMobile()" [(selection)]="selectedRow" [lazy]="true"
                (onLazyLoad)="loadDataLazy($event)" scrollHeight="flex" [responsive]="true" [paginator]="true"
                [rows]="rowsPerPage" [sortField]="sortField" [sortOrder]="sortOrder" [totalRecords]="totalRows"
                [lazyLoadOnInit]="true" [rowsPerPageOptions]="[10,20,30,50,100]" (onRowSelect)="onRowSelect($event)"
                [loading]="loading" styleClass="p-datatable-striped">
                <ng-template pTemplate="caption">
                    <div class="flex">
                        <button type="button" pButton icon="pi pi-plus" (click)="addGame()" [label]="getAddButtonLabel()"
                            class="my-auto"></button>
                        <span class="p-input-icon-left ml-auto">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" [(ngModel)]="searchTerm" pAutoFocus [autofocus]="true"
                                (onChange)="onGlobalFilterBodyChange($event, dt)"
                                (input)="onGlobalFilterBodyChange($event, dt)" placeholder="Search"
                                (click)="$event.target.select()" />
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                            {{col.header}} <p-sortIcon [field]="col.field"></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr [pSelectableRow]="rowData">
                        <td *ngFor="let col of columns">
                            <span class="p-column-title font-bold mr-auto">{{col.header}}</span>
                            {{rowData[col.field]}}
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td [attr.colspan]="columns.length">
                            No games found.
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                    <ng-container *ngIf="rows.length > 0">
                        <div class="text-right text-xl" [ngClass]="{'mb-8': layoutService.isMobile()}">
                            {{totalRows | number}} game{{totalRows == 0 || totalRows > 1 ? 's' : '' }}
                        </div>
                    </ng-container>
                </ng-template>
            </p-table>
        </div>
    </p-tabPanel>
    <p-tabPanel header="Install Log">
        <div [style.height]="getPageHeight()">
            <app-game-installs #installs></app-game-installs>
        </div>
    </p-tabPanel>
</p-tabView>
