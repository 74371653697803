import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ApiService, UserLoginRecord } from 'src/app/service/api.service';
import { AuthService } from 'src/app/service/auth.service';
import { SelectItem } from 'primeng/api';

// Must contain uppercase, lowercase and number or symbol
const PASSWORD_REGEX = /^((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;

interface TicketAlert {
  label: string,
  value: string
}

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.css']
})
export class AccountSettingsComponent implements OnInit {
  dataEntryForm: UntypedFormGroup;
  userRecord: UserLoginRecord = {} as UserLoginRecord;
  errorMessage: string;
  successMessage: string;
  loading = false;
  updating = false;
  timezoneOptions: SelectItem[];
  minPasswordLength: number;

  constructor(private authService: AuthService, private apiService: ApiService,
    public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
  }

  ngOnInit() {
    this.loading = true;
    this.userRecord = this.authService.getUserAccount();

    this.dataEntryForm = new UntypedFormGroup({
      'first_name': new UntypedFormControl(this.userRecord.first_name, [Validators.required, Validators.maxLength(50)]),
      'last_name': new UntypedFormControl(this.userRecord.last_name, [Validators.required, Validators.maxLength(50)]),
      'username': new UntypedFormControl({ value: this.userRecord.username, disabled: true }),
      'timezone': new UntypedFormControl(this.userRecord.timezone, Validators.required),
      'changePassword': new UntypedFormControl(false, null),
      'new_password': new UntypedFormControl({ value: null, disabled: true }, null),
      'repeat_password': new UntypedFormControl({ value: null, disabled: true }, null)
    }, this.passwordsMatch);

    this.apiService.getUserOptions().subscribe({
      next: (resp) => {
        this.timezoneOptions = resp.body.timezones_list.map(t => { return { value: t.name, label: t.name } });
        // this.accessLevelOptions = resp.body.access_level_list.map(a => { return { value: +a.access_level_id, label: a.name } });
        this.minPasswordLength = resp.body.min_password_length;

        this.loading = false;
      }, error: (err) => {
        this.loading = false;
      }
    });
  }

  onCancel() {
    this.ref.close();
  }

  private passwordsMatch(form: UntypedFormGroup) {
    // console.log('passwordsMatch called');
    if (form.get('new_password').value !== form.get('repeat_password').value) {
      // console.log('mismatch');
      return { mismatch: true };
    } else {
      // console.log('match');
      return null;
    }
  }

  togglePasswordFields() {
    const newPasswordControl = this.dataEntryForm.get('new_password');
    const repeatPasswordControl = this.dataEntryForm.get('repeat_password');
    if (this.dataEntryForm.value.changePassword) {
      newPasswordControl.enable();
      newPasswordControl.setValidators(Validators.compose(
        [Validators.required, Validators.minLength(this.minPasswordLength), Validators.maxLength(200)]));
      newPasswordControl.updateValueAndValidity();

      repeatPasswordControl.enable();
      repeatPasswordControl.setValidators(Validators.compose(
        [Validators.required, Validators.minLength(this.minPasswordLength), Validators.pattern(PASSWORD_REGEX), Validators.maxLength(200)]));
      repeatPasswordControl.updateValueAndValidity();
    } else {
      newPasswordControl.disable();
      newPasswordControl.clearValidators();
      newPasswordControl.reset();
      newPasswordControl.updateValueAndValidity();

      repeatPasswordControl.disable();
      repeatPasswordControl.clearValidators();
      repeatPasswordControl.reset();
      repeatPasswordControl.updateValueAndValidity();
    }
  }

  onSave() {
    this.updating = true;
    this.errorMessage = '';
    let newFirstName = null;
    let newLastName = null;
    let newTimezone = null;
    let newPassword = null;

    if (this.userRecord.first_name !== this.dataEntryForm.value.first_name)
      newFirstName = this.dataEntryForm.value.first_name;

    if (this.userRecord.last_name !== this.dataEntryForm.value.last_name)
      newLastName = this.dataEntryForm.value.last_name;

    if (this.userRecord.timezone !== this.dataEntryForm.value.timezone)
      newTimezone = this.dataEntryForm.value.timezone;

    if (this.dataEntryForm.value.changePassword) {
      newPassword = this.dataEntryForm.value.new_password;
    }

    this.apiService.updateMyAccount(this.userRecord.user_id, newFirstName,
      newLastName, newTimezone, newPassword).subscribe({
        next: (resp) => {
          if (resp.body.ok) {
            if (resp.headers.get('x-auth')) {
              const sessionToken = resp.headers.get('x-auth');
              localStorage.setItem('session_token', sessionToken);
            }
          }
          this.updating = false;
          this.ref.close(true);
        }, error: (err) => {
          this.updating = false;
          this.errorMessage = err;
        }
      });
  }
}
