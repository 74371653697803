import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
// import { SelectItem, ConfirmationService, MessageService } from 'primeng/api';
import * as _ from 'lodash';
// import { ModifyGameComponent } from '../modify-game/modify-game.component';
import { GamesInstallsComponent } from '../game-installs/game-installs.component';
import { GameEntity, GameProvisioningService } from 'src/app/service/game-provisioning.service';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { ModifyGameComponent } from '../modify-game/modify-game.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subject, Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { Table } from 'primeng/table';
import { LazyLoadEvent } from 'primeng/api';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-games',
  templateUrl: './games.component.html',
  styleUrls: ['./games.component.css']
})
export class GamesComponent implements OnInit, OnDestroy {
  @ViewChild('installs') installs: GamesInstallsComponent;
  @ViewChild('dt', { static: false }) tableComponent: Table;

  selectedRow: GameEntity = null;
  totalRows: number;
  columns: any[];
  rows: GameEntity[] = [];
  // modifyGameComponent: ComponentRef<ModifyGameComponent> = null;
  loading = true;
  dialogRef: DynamicDialogRef;

  // LAZY LOADING CODE
  firstLoad = true;
  rowsPerPage = 20;
  sortField = 'name';
  sortOrder = 1; // Ascending
  searchTerm: string;
  // stateKey = 'gpsTable';
  // END LAZY LOADING CODE

  queryParams: any;

  private readonly searchSubject = new Subject<string | undefined>();
  searchSubscription: Subscription;

  constructor(private gameProvisioningService: GameProvisioningService, public layoutService: LayoutService,
    private route: ActivatedRoute, private dialogService: DialogService) {
    this.columns = [
      // { field: 'game_id', header: 'ID' },
      { field: 'name', header: 'Name' }
    ];
  }

  ngOnInit() {
    // this.route.queryParams.subscribe(params => {
    //   if (!this.firstLoad)
    //     return;

    //   // console.log('this.route.queryParams', params);
    //   this.queryParams = {};

    //   if (params['s'] !== undefined) {
    //     // console.log('search from query:', params['s'])
    //     this.queryParams['s'] = params['s'];
    //     this.searchTerm = params['s'];
    //   }

    //   // console.log('query =', this.queryParams);
    //   if (!_.isEmpty(this.queryParams)) {
    //     if (localStorage.getItem(this.stateKey)) {
    //       // console.log('clearing filter from state object, before:', localStorage.getItem(this.stateKey));
    //       let event = <LazyLoadEvent>JSON.parse(localStorage.getItem(this.stateKey));
    //       event.filters = null;
    //       localStorage.setItem(this.stateKey, JSON.stringify(event));
    //       // console.log('clearing filter from state object, after:', localStorage.getItem(this.stateKey));
    //     } else {
    //       // console.log('query params found but no state object, nothing to change')
    //     }
    //   } else {
    //     if (localStorage.getItem(this.stateKey)) {
    //       let event = <LazyLoadEvent>JSON.parse(localStorage.getItem(this.stateKey));
    //       if (event.filters) {
    //         if (event.filters['search']) {
    //           // console.log('restoring search from storage:', event.filters['search'].value)
    //           this.searchTerm = event.filters['search'].value;
    //           this.queryParams['s'] = this.searchTerm;
    //         }
    //       }
    //     }
    //   }
    // });

    this.searchSubscription = this.searchSubject
      .pipe(
        debounceTime(800),
        distinctUntilChanged()
      )
      .subscribe(searchTerm => this.tableComponent.filter(searchTerm, 'search', 'contains'));
  }

  ngOnDestroy(): void {
    this.searchSubscription.unsubscribe();
  }

  getAddButtonLabel(): string {
    if (this.layoutService.isMobile())
      return "";
    else
      return "Add Game";
  }

  forceUpdate() {
    this.tableComponent.filter(this.searchTerm, 'search', 'contains');
  }

  loadDataLazy(event: LazyLoadEvent) {
    // Apply the filters passed in the event and execute query
    let filters = {};
    this.loading = true;
    // console.log('loadDataLazy', event);
    let sf = event.sortField || this.sortField;
    let filter = event.filters['search'] ? event.filters['search'].value : null;
    if (filter)
      filters['s'] = filter;

    // this.router.navigate(['/'].concat(this.route.snapshot.url.map(p => p.path)), { queryParams: this.criteria ? { ...this.criteria.queryParams, ...filters } : filters });
    // console.log(`criteria - first: ${event.first}, rows: ${event.rows}, sort field: ${sf}, sort order: ${event.sortOrder}, status: ${status}, search: ${filter}, locationIDs: ${locationIDs ? locationIDs.map(l => l.location_id).join(',') : locationIDs}`);
    //event.first, event.rows, sf, event.sortOrder, filter
    this.gameProvisioningService.getGames(event.first, event.rows, sf, event.sortOrder, filter).subscribe({
      next: (resp) => {
        this.selectedRow = null;
        if (resp.body) {
          this.rows = resp.body['data'];
          this.totalRows = resp.body['totalRows'];;
        } else {
          this.rows = [];
        }
        this.loading = false
      }, error: (err) => {
        this.layoutService.onError(err);
        this.loading = false;
      }
    });
  }

  addGame() {
    this.dialogRef = this.dialogService.open(ModifyGameComponent, {
      header: 'Add Game',
      width: this.layoutService.isMobile() ? "100%" : "30vw",
      closable: false,
      closeOnEscape: false,
      draggable: true,
      data: {
        layoutService: this.layoutService,
        id: null
      }
    });

    this.dialogRef.onClose.subscribe((created: boolean) => {
      if (created) {
        this.forceUpdate();
      }
    });
  }

  editGame(game: GameEntity) {
    this.dialogRef = this.dialogService.open(ModifyGameComponent, {
      header: 'Edit Game',
      width: this.layoutService.isMobile() ? "100%" : "30vw",
      closable: false,
      closeOnEscape: false,
      draggable: true,
      data: {
        layoutService: this.layoutService,
        id: game.game_id
      }
    });

    this.dialogRef.onClose.subscribe((created: boolean) => {
      if (created) {
        this.forceUpdate();
      } else {
        this.selectedRow = null;
      }
    });
  }

  onRowSelect(event: any) {
    // console.log(event.data);
    this.editGame(event.data);
  }

  tabChanged(event: any) {
    if (event.index === 0) {
      this.forceUpdate();
    } else {
      this.installs.forceUpdate();
    }
  }

  onGlobalFilterBodyChange(event, dt) {
    const searchQuery = event.srcElement.value;
    this.searchSubject.next(searchQuery?.trim());
  }

  getPageHeight() {
    if (this.layoutService.isMobile())
      return '100%';
    else return "calc(100vh - 27vh)";
  }
}
