<div class=" bg-primary-reverse bg-primary-50">
    <div class="flex justify-content-center">
        <div class="w-full lg:w-5 h-screen text-center flex justify-content-center align-items-start">
            <div class="z-5 w-full lg:w-8 px-6 text-center mt-8" style="max-width: 400px;">
                <div class="w-full flex align-items-center justify-content-center">
                    <img src="assets/layout/images/login/icon-login.png" alt="avalon-layout" class="w-6rem" />
                </div>
                <h1 class="text-4xl font-light mt-4 text-primary-500">Forgot Password?</h1>
                <p *ngIf="!resetResult">Enter your account's email address.</p>
                <ng-container *ngIf="!resetResult else resetResponse">
                    <form #f="ngForm">
                        <div class="mt-5 text-left">
                            <label for="login_username" class="block mb-2" style="color:#4c566a">Email</label>
                            <span class="p-input-icon-right block">
                                <i class="pi pi-user"></i>
                                <input type="text" pAutoFocus [autofocus]="true" id="login_username"
                                    name="login_username" required email ngModel pInputText class="w-full">
                            </span>
                            <ng-container *ngIf="errorResult">
                                <p-messages severity="error">
                                    <ng-template pTemplate>
                                        {{errorResult}}
                                    </ng-template>
                                </p-messages>
                            </ng-container>
                            <div class="flex align-items-center justify-content-between mt-4 gap-3">
                                <button pButton pRipple label="Submit" class="w-10rem" (click)="onSubmit(f)"
                                    [disabled]="loading || !f.valid"></button>
                                <a routerLink="/login" class="secondary-button">Back to Sign In</a>
                            </div>
                        </div>
                    </form>
                </ng-container>
                <ng-template #resetResponse>
                    <p-messages severity="success">
                        <ng-template pTemplate>
                            {{resetResult}}
                        </ng-template>
                    </p-messages>
                    <a class="text-left text-sm mt-2" [routerLink]="['/login']">Back to Sign In</a>
                </ng-template>
            </div>
        </div>

        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            class="absolute bottom-0 w-screen" viewBox="0 0 1440 250">
            <defs>
                <linearGradient id="c" x1="50%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stop-color="var(--primary-200)" />
                    <stop offset="99.052%" stop-color="var(--primary-300)" />
                </linearGradient>
                <path id="b"
                    d="M0 202c142.333-66.667 249-90 320-70 106.5 30 122 83.5 195 83.5h292c92.642-106.477 190.309-160.81 293-163 102.691-2.19 216.025 47.643 340 149.5v155.5H0V202z" />
                <filter id="a" width="105.1%" height="124.3%" x="-2.6%" y="-12.8%" filterUnits="objectBoundingBox">
                    <feOffset dy="-2" in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="12" />
                    <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.11 0" />
                </filter>
                <linearGradient id="d" x1="50%" x2="50%" y1="0%" y2="99.142%">
                    <stop offset="0%" stop-color="var(--primary-300)" />
                    <stop offset="100%" stop-color="var(--primary-500)" />
                </linearGradient>
            </defs>
            <g fill="none" fill-rule="evenodd">
                <g transform="translate(0 .5)">
                    <use fill="#000" filter="url(#a)" xlink:href="#b" />
                    <use fill="url(#c)" xlink:href="#b" />
                </g>
                <path fill="url(#d)"
                    d="M0 107c225.333 61.333 364.333 92 417 92 79 0 194-79.5 293-79.5S914 244 1002 244s156-45 195-68.5c26-15.667 107-74.167 243-175.5v357.5H0V107z"
                    transform="translate(0 .5)" />
            </g>
        </svg>

    </div>
</div>
