import { NgModule } from '@angular/core';
import { DatePipe, HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppLayoutModule } from './layout/app.layout.module';

import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ListboxModule } from 'primeng/listbox';
import { MenuModule } from 'primeng/menu';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { PaginatorModule } from 'primeng/paginator';
import { PasswordModule } from 'primeng/password';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RippleModule } from 'primeng/ripple';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AnimateModule } from 'primeng/animate';

import { GameClientsComponent } from './components/game-clients/game-clients.component';
import { PackagesComponent } from './components/packages/packages.component';
import { SoftwareComponent } from './components/software/software.component';
import { EditGameClientComponent } from './components/edit-game-client/edit-game-client.component';
import { ModifyPackageComponent } from './components/modify-package/modify-package.component';
import { ModifySoftwareComponent } from './components/modify-software/modify-software.component';
import { ReportViewerComponent } from './components/report-viewer/report-viewer.component';
import { EditGameSettingsComponent } from './components/edit-game-settings/edit-game-settings.component';
import { ModifySubassemblyComponent } from './components/modify-subassembly/modify-subassembly.component';
import { ModifyGamePackageComponent } from './components/modify-game-package/modify-game-package.component';
import { GamesComponent } from './components/games/games.component';
import { ModifyGameComponent } from './components/modify-game/modify-game.component';
import { GamesInstallsComponent } from './components/game-installs/game-installs.component';
import { GameInstallDetailsComponent } from './components/game-install-details/game-install-details.component';
import { TrimInputDirective } from './service/trim-input.directive';
import { UsersComponent } from './components/users/users.component';
import { ModifyUserComponent } from './components/users/modify-user/modify-user.component';
import { SettingsMgrComponent } from './components/settings-mgr/settings-mgr.component';
import { ModifySettingComponent } from './components/settings-mgr/modify-setting/modify-setting.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { LoginComponent } from './components/login/login.component';
import { AccountSettingsComponent } from './components/account-settings/account-settings.component';
import { AlertViewerComponent } from './components/alerts/alert-viewer/alert-viewer.component';
import { AlertsComponent } from './components/alerts/alerts.component';
import { GameInstallLogViewerComponent } from './components/game-install-log-viewer/game-install-log-viewer.component';


@NgModule({
  declarations: [
    AppComponent,
    GameClientsComponent,
    PackagesComponent,
    SoftwareComponent,
    EditGameClientComponent,
    ModifyPackageComponent,
    ModifySoftwareComponent,
    ReportViewerComponent,
    EditGameSettingsComponent,
    GamesComponent,
    ModifyGameComponent,
    ModifySubassemblyComponent,
    ModifyGamePackageComponent,
    GamesInstallsComponent,
    GameInstallDetailsComponent,
    TrimInputDirective,
    UsersComponent,
    ModifyUserComponent,
    SettingsMgrComponent,
    ModifySettingComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    AccountSettingsComponent,
    AlertViewerComponent,
    AlertsComponent,
    GameInstallLogViewerComponent
  ],
  imports: [
    AppRoutingModule,
    AppLayoutModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    BreadcrumbModule,
    ButtonModule,
    CalendarModule,
    CheckboxModule,
    ConfirmDialogModule,
    ConfirmPopupModule,
    DialogModule,
    DropdownModule,
    FileUploadModule,
    InputNumberModule,
    InputTextModule,
    InputTextareaModule,
    ListboxModule,
    MenuModule,
    MessageModule,
    MessagesModule,
    MultiSelectModule,
    PaginatorModule,
    PasswordModule,
    ProgressBarModule,
    ProgressSpinnerModule,
    RippleModule,
    TableModule,
    TabViewModule,
    ToastModule,
    TooltipModule,
    VirtualScrollerModule,
    DynamicDialogModule,
    AnimateModule
  ],
  providers: [DialogService, ConfirmationService, MessageService, DatePipe,
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
