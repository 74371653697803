import { Component, OnInit, Input } from '@angular/core';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
// import { ModifyPackageComponent } from '../modify-package/modify-package.component';
// import { ModifySubassemblyComponent } from '../modify-subassembly/modify-subassembly.component';
import { GameEntity, GamePackageEntity, GameProvisioningService, GameSubassemblyEntity } from 'src/app/service/game-provisioning.service';
import { ConfirmationService, Message } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { ModifySubassemblyComponent } from '../modify-subassembly/modify-subassembly.component';
import { ModifyGamePackageComponent } from '../modify-game-package/modify-game-package.component';


@Component({
  selector: 'app-modify-game',
  templateUrl: './modify-game.component.html',
  styleUrls: ['./modify-game.component.css']
})
export class ModifyGameComponent implements OnInit {
  visible: boolean;
  errorMessage: string;

  game: GameEntity;
  gameForm: FormGroup;
  subassemblies: GameSubassemblyEntity[];
  packages: GamePackageEntity[];
  selectedSubassembly: GameSubassemblyEntity;
  selectedPackage: GamePackageEntity;
  deleting = false;
  loading = false;
  editGame = false;
  updating = false;
  dialogRef: DynamicDialogRef;
  lastSelectedSubassembly: GameSubassemblyEntity;

  constructor(private formBuilder: FormBuilder, private gameProvisioningService: GameProvisioningService, public layoutService: LayoutService,
    private dialogService: DialogService, private confirmationService: ConfirmationService, private ref: DynamicDialogRef,
    private config: DynamicDialogConfig) {
    this.subassemblies = [];
  }

  ngOnInit() {
    this.gameForm = this.formBuilder.group({
      'gameName': new FormControl('', Validators.required),
      'subassemblyName': new FormControl(''),
      'versionName': new FormControl('')
    });

    if (this.config.data.id) {
      // Edit game
      this.editGame = true;
      this.getGameData(this.config.data.id);
    } else {
      this.editGame
    }
  }

  getGameData(gameID: number) {
    this.loading = true;
    this.gameProvisioningService.getCompleteGame(gameID).subscribe({
      next: (resp) => {
        this.game = null;
        if (resp.body) {
          this.game = resp.body;
          // console.log('this.game', this.game)
          this.gameForm.get('gameName').setValue(this.game.name);
          this.subassemblies = this.game.subassemblies;
          if (this.lastSelectedSubassembly) {
            // console.log('restoring selected subassembly in list')
            for (let i = 0; i < this.subassemblies.length; i++) {
              if (this.lastSelectedSubassembly.subassembly_id === this.subassemblies[i].subassembly_id) {
                this.selectedSubassembly = this.subassemblies[i];
                // console.log('seting selection')
                this.getSubassemblyPackges(null);
                break;
              }
            }
          }
        } else {
          this.game = null;
        }
        this.loading = false
      }, error: (err) => {
        this.layoutService.onError(err),
          setTimeout(() => this.ref.close(), 2000);
      }
    });
  }

  reloadGameData() {
    this.lastSelectedSubassembly = this.selectedSubassembly;
    this.selectedSubassembly = null;
    this.getGameData(this.game.game_id);
  }

  getSubassemblyPackges(event: any) {
    this.selectedPackage = null;
    if (!!this.selectedSubassembly && this.selectedSubassembly.packages) {
      // console.log('selectedSubassembly', this.selectedSubassembly);
      // console.log(event.value);
      this.packages = this.selectedSubassembly.packages;
    } else {
      this.packages = [];
    }
  }

  submitGame() {
    // If this.game not defined
    //   only the game name was set by user so just add the game name to the database
    // else
    //   subassemblies are already saved as they get added so these can be ignored
    //   if the game name field <> this.game.name
    //     update the game name in the database
    this.errorMessage = '';
    this.updating = true;

    if (this.game === undefined) {
      this.gameProvisioningService.addGame(this.gameForm.value.gameName).subscribe({
        next: (resp) => {
          // console.log(resp);
          this.updating = false;
          this.ref.close(true);
        }, error: (err) => {
          // console.log('handling error', err)
          this.updating = false;
          this.errorMessage = err;
        }
      });
    } else {
      this.game.name = this.gameForm.value.gameName;
      this.gameProvisioningService.updateGame(this.game).subscribe(gameRecord => {
        // console.log('updateGame', gameRecord);
        this.updating = false;
        this.ref.close(true);
      }, error => {
        this.updating = false;
        this.errorMessage = error.message;
      });
    }
  }

  onCancel() {
    this.ref.close(false);
  }

  addSubassembly() {
    this.errorMessage = '';

    if (this.game === undefined) {
      this.updating = true;
      this.gameProvisioningService.addGame(this.gameForm.value.gameName).subscribe(gameRecord => {
        this.game = gameRecord.body;
        this.updating = false;
        this.addSubassembly();
      }, error => {
        this.errorMessage = error.message;
        this.updating = false;
      });
      return;
    }

    const subassemblyName: string = this.gameForm.value.subassemblyName.trim();
    if (subassemblyName !== '' &&
      this.subassemblies.findIndex(s => s.name.toLowerCase() === subassemblyName.toLowerCase()) === -1) {
      this.gameProvisioningService.addSubassembly({
        subassembly_id: 0,
        game_id: this.game.game_id,
        name: subassemblyName
      }).subscribe(s => {
        let subassembly = s.body;
        // console.log('addSubassembly', subassembly)
        if (subassembly.subassembly_id !== undefined) {
          subassembly.game_id = this.game.game_id;
          subassembly.name = subassemblyName;
          subassembly.packages = [];
          this.gameForm.get('subassemblyName').setValue('');
          this.subassemblies.push(subassembly);
          this.subassemblies = [...this.subassemblies];
        }
      }, error => {
        this.errorMessage = 'Subassembly names must be unique';
      });
    } else {
      this.errorMessage = 'Subassembly names must be unique';
    }
  }

  editSubassembly() {
    this.dialogRef = this.dialogService.open(ModifySubassemblyComponent, {
      header: 'Edit Subassembly',
      width: this.layoutService.isMobile() ? "100%" : "15vw",
      closable: false,
      closeOnEscape: false,
      draggable: true,
      data: {
        layoutService: this.layoutService,
        subassembly: this.selectedSubassembly
      }
    });

    this.dialogRef.onClose.subscribe((created: boolean) => {
      if (created) {
        this.getGameData(this.game.game_id);
      }
    });
  }

  addPackage() {
    this.errorMessage = '';

    const versionName: string = this.gameForm.value.versionName.trim();
    if (versionName !== '' &&
      this.packages.findIndex(s => s.version_name.toLowerCase() === versionName.toLowerCase()) === -1) {
      this.gameForm.get('versionName').setValue('');

      this.dialogRef = this.dialogService.open(ModifyGamePackageComponent, {
        header: 'Add Game Package',
        width: this.layoutService.isMobile() ? "100%" : "20vw",
        closable: false,
        closeOnEscape: false,
        draggable: true,
        data: {
          layoutService: this.layoutService,
          id: this.game.game_id,
          subassembly: this.selectedSubassembly,
          versionName
        }
      });

      this.dialogRef.onClose.subscribe(changed => {
        if (changed) {
          this.reloadGameData();
        }
      });
    } else {
      this.errorMessage = 'Version names must be unique';
    }
  }

  editPackage() {
    if (this.selectedPackage) {
      this.dialogRef = this.dialogService.open(ModifyGamePackageComponent, {
        header: 'Edit Game Package',
        width: this.layoutService.isMobile() ? "100%" : "20vw",
        closable: false,
        closeOnEscape: false,
        draggable: true,
        data: {
          layoutService: this.layoutService,
          id: this.game.game_id,
          subassembly: this.selectedSubassembly,
          package: this.selectedPackage
        }
      });

      this.dialogRef.onClose.subscribe((result: any) => {
        if (result.removed) {
          let idx = this.packages.findIndex(s => s.version_name === this.selectedPackage.version_name);
          this.selectedPackage = null;
          this.packages.splice(idx, 1);
        } else if (result.modified) {
          this.reloadGameData();
        }
      });
    }
  }

  confirmDelete() {
    this.errorMessage = '';
    this.confirmationService.confirm({
      message: 'If you delete this game, all subassemblies and versions / packages linked to it will also be deleted. Are you REALLY sure you want to continue?',
      header: 'Delete Game',
      icon: 'fa fa-trash',
      key: 'deleteGame',
      accept: () => {
        this.deleting = true;
        this.gameProvisioningService.deleteGame(this.game.game_id).subscribe(result => {
          this.ref.close(true);
        }, error => {
          this.errorMessage = error.message;
        });
      }
    });
  }
}
