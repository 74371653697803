<p-confirmDialog key="discardAlert" [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '20vw' }"></p-confirmDialog>
<div *ngIf="loading else showAlert" class="col-12 text-center">
    <p-progressSpinner></p-progressSpinner>
</div>
<ng-template #showAlert>
    <form [formGroup]="dataEntryForm">
        <div class="formgrid grid">
            <div class="field col-12 md:col-4">
                <label for="alert_id">Alert ID</label>
                <input pInputText type="text" class="w-full" id="alert_id" name="alert_id" formControlName="alert_id">
            </div>
            <div class="field col-12 md:col-4">
                <label for="alert_time">Timestamp</label>
                <input pInputText type="text" class="w-full" id="alert_time" name="alert_time"
                    formControlName="alert_time">
            </div>
            <div class="field col-12 md:col-4">
                <label for="sent">Alert Sent</label>
                <input pInputText type="text" class="w-full" id="sent" name="sent" formControlName="sent">
            </div>
            <div class="field col-12 md:col-6">
                <label for="public_ip_address">Public IP Address</label>
                <input pInputText type="text" class="w-full" id="public_ip_address" name="public_ip_address"
                    formControlName="public_ip_address">
            </div>
            <div class="field col-12 md:col-6">
                <label for="local_ip_address">Local IP Address</label>
                <input pInputText type="text" class="w-full" id="local_ip_address" name="local_ip_address"
                    formControlName="local_ip_address">
            </div>
            <div class="field col-12">
                <label for="log_message">Log Message</label>
                <textarea class="block w-full max-h-25rem" rows="10" pInputTextarea formControlName="log_message"></textarea>
            </div>
        </div>
        <hr>
        <div class="flex flex-wrap">
            <p-button *ngIf="authService.isAdministrator()" label="Delete" icon="pi pi-trash" class="mr-auto"
                styleClass="p-button-danger" [disabled]="loading" (onClick)="onDeleteClick()">
            </p-button>
            <p-button label="Close" icon="pi pi-times" class="ml-auto" styleClass="p-button-secondary"
                [disabled]="loading" (onClick)="onCloseClick()">
            </p-button>
        </div>
    </form>
</ng-template>
