<div *ngIf="rows.length == 0 && !logData" class="col-12 text-center">
    <p-progressSpinner></p-progressSpinner>
</div>

<p-table *ngIf="rows.length && config.data.type != 'log'" [value]="rows" styleClass="p-datatable-striped">
    <ng-template pTemplate="caption">
        <div class="flex flex-wrap"><button pButton pRipple label="PDF" icon="pi pi-download"
                class="p-button-raised ml-auto mr-2" (click)="export('pdf')"></button><button pButton pRipple
                label="XLSX" icon="pi pi-download" class="p-button-raised" (click)="export('xlsx')"></button></div>
    </ng-template>
    <ng-template pTemplate="body" let-row>
        <tr>
            <td class="font-bold">{{row.key}}</td>
            <td>{{row.value}}</td>
        </tr>
    </ng-template>
</p-table>
<ng-container *ngIf="config.data.type == 'log'">
    <div class="flex flex-wrap"><button pButton pRipple label="Download" icon="pi pi-download"
            class="p-button-raised ml-auto" (click)="onDownloadAuditClick()"></button></div>
    <pre>
{{logData}}
</pre>
</ng-container>
