<p-confirmDialog header="Discard" key="discard" icon="pi pi-question-circle" [breakpoints]="{ '960px': '75vw' }"
    [style]="{ width: '20vw' }"></p-confirmDialog>
<form [formGroup]="dataEntryForm">
    <div class="formgrid grid">
        <div class="field col-12 md:col-6">
            <label for="software_name">Software Name</label>
            <input pInputText type="text" [autofocus]="true" trimInput class="w-full" id="software_name"
                name="software_name" formControlName="software_name">
        </div>
        <div class="field col-12 md:col-6">
            <label for="version_num">Version #</label>
            <input pInputText type="text" trimInput class="w-full" id="version_num" name="version_num"
                formControlName="version_num">
        </div>
        <div class="field col-12">
            <label for="description">Description</label>
            <input pInputText type="text" trimInput class="w-full" id="description" name="description"
                formControlName="description">
        </div>

        <div *ngIf="!newRecord" class="field col-12">
            <a href="{{record.package_filename}}">Current package size: {{record.package_filesize}}</a>
        </div>
        <div *ngIf="!newRecord" class="field-checkbox col-12">
            <p-checkbox id="replacePackageFile" inputId="replacePackageFile" name="replacePackageFile"
                (onChange)="toggleUploader()" formControlName="replacePackageFile" [binary]="true"></p-checkbox>
            <label for="replacePackageFile"> Update File</label>
        </div>
        <div *ngIf="newRecord || dataEntryForm.value.replacePackageFile" class="field col-12">
            <label for="package_file">Package</label>
            <p-fileUpload #u [style.display]="uploading ? 'none' : 'block'" name="package_file"
                [showUploadButton]="false" [showCancelButton]="false" customUpload="true"
                (uploadHandler)="myUploader($event)" (onProgress)="updateProgress($event)" accept=".gz">
                <ng-template pTemplate="content" let-files>
                    <div class="text-center font-italic p-1">Drag & drop <strong>.tar.gz</strong> file here</div>
                </ng-template>
            </p-fileUpload>
            <div class="font-italic" *ngIf="uploading">Uploading: {{ uploader.files[0].name }}...</div>
            <p-progressBar *ngIf="uploading" [value]="uploadProgress" [showValue]="false"></p-progressBar>
            <p-messages *ngIf="errorMessage" severity="error">
                <ng-template pTemplate>
                    {{errorMessage}}
                </ng-template>
            </p-messages>
        </div>
    </div>
    <hr>
    <div class="flex flex-wrap">
        <p-button *ngIf="!newRecord" label="Delete" icon="pi pi-trash" class="mr-auto" styleClass="p-button-danger"
            [disabled]="uploading || deleting" (onClick)="onDeleteClick()" pTooltip="Delete game package"
            showDelay="500">
        </p-button>
        <p-button label="Cancel" icon="pi pi-times" class="ml-auto" styleClass="p-button-secondary"
            [disabled]="uploading || deleting" (onClick)="verifyCancelAction($event)">
        </p-button>
        <p-button label="Save" icon="pi pi-check" class="ml-2" (onClick)="submitForm()"
            [disabled]="!dataEntryForm.valid || uploading || deleting"></p-button>
    </div>
</form>
