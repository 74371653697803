import { Component, OnInit } from '@angular/core';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { LayoutService } from './layout/service/app.layout.service';
import { AuthService } from './service/auth.service';
import { DialogService } from 'primeng/dynamicdialog';
import { AccountSettingsComponent } from './components/account-settings/account-settings.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  constructor(private primengConfig: PrimeNGConfig, private layoutService: LayoutService,
    private authService: AuthService, private messageService: MessageService, private dialogService: DialogService) { }

  ngOnInit(): void {
    this.primengConfig.ripple = true;

    this.layoutService.logoutClicked.subscribe(() => this.authService.signoutUser());
    this.layoutService.configUpdate$.subscribe(() => {
      localStorage.setItem('theme', this.layoutService.config.colorScheme);
    });

    this.layoutService.profileClicked.subscribe(() => {
      const ref = this.dialogService.open(AccountSettingsComponent, {
        header: 'Account Settings',
        width: this.layoutService.isMobile() ? "100%" : "30vw",
        closable: false,
        closeOnEscape: false,
        draggable: true,
        data: {
        }
      });
    });

    this.layoutService.serverError.subscribe((error) => {
      let errorMessage = error.message || error;
      if (errorMessage.toLowerCase() === 'unauthorized') {
        this.authService.signoutUser();
      } else {
        this.messageService.add({ severity: 'error', summary: 'Web Server', detail: errorMessage, life: 10000 });
      }
    });

    this.authService.signInStatusUpdate.subscribe(signedIn => {
      // Build menu for users that just signed in
      if (signedIn.ok) {
        this.buildMenu();
      }
    });

    // Build menu for users that are already authenticated and returning to the site
    if (this.authService.isAuthenticated()) {
      this.buildMenu();
    }
  }

  buildMenu() {
    // Build menu items available to user
    this.layoutService.config.menuItems = [
      {
        label: 'Management',
        icon: null,
        items: [
          {
            label: 'Game Clients', icon: 'network_node', routerLink: ['/']
          },
          {
            label: 'Game Updates', icon: 'update', routerLink: ['/packages']
          },
          {
            label: 'Software', icon: 'deployed_code_update', routerLink: ['/software']
          },
          {
            label: 'Game Provisioning', icon: 'content_copy', routerLink: ['/gps']
          },
          {
            label: 'Game Alerts', icon: 'warning_amber', routerLink: ['/alerts']
          }
        ]
      }
    ];

    if (this.authService.isAdministrator()) {
      this.layoutService.config.menuItems[0].items.push({
        label: 'Users', icon: 'person', routerLink: ['/users']
      });

      this.layoutService.config.menuItems[0].items.push({
        label: 'Server Settings', icon: 'settings', routerLink: ['/settings']
      });
    }

    if (!localStorage.getItem('theme')) {
      localStorage.setItem('theme', 'light');
    }
    this.layoutService.setTheme(localStorage.getItem('theme'));
  }
}
