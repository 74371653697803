<p-table #dt [columns]="columns" [value]="rows" selectionMode="single" [scrollable]="!layoutService.isMobile()"
    [lazy]="true" (onLazyLoad)="loadDataLazy($event)" scrollHeight="flex" [responsive]="true" [paginator]="true"
    [rows]="rowsPerPage" [sortField]="sortField" [sortOrder]="sortOrder" [totalRecords]="totalRows"
    [lazyLoadOnInit]="true" [(selection)]="selectedRow" [rowsPerPageOptions]="[10,20,30,50,100]"
    (onRowSelect)="onRowSelect($event)" [loading]="loading" styleClass="p-datatable-striped">
    <ng-template pTemplate="caption">
        <div class="flex">
            <span class="p-input-icon-left ml-auto">
                <i class="pi pi-search"></i>
                <input pInputText type="text" [(ngModel)]="searchTerm" pAutoFocus [autofocus]="true"
                    (onChange)="onGlobalFilterBodyChange($event, dt)" (input)="onGlobalFilterBodyChange($event, dt)"
                    placeholder="Search" (click)="$event.target.select()" />
            </span>
        </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                {{col.header}} <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData">
            <td *ngFor="let col of columns">
                <span class="p-column-title font-bold mr-auto">{{col.header}}</span>
                <ng-container *ngIf="col.type === 'datetime'; else noFormat">
                    {{rowData[col.field] | date:'M/d/yy h:mm:ss aa'}}
                </ng-container>
                <ng-template #noFormat>{{rowData[col.field]}}</ng-template>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td [attr.colspan]="columns.length">
                No installs found.
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="summary">
        <ng-container *ngIf="rows.length > 0">
            <div class="text-right text-xl" [ngClass]="{'mb-8': layoutService.isMobile()}">
                {{totalRows | number}} install{{totalRows == 0 || totalRows > 1 ? 's' : '' }}
            </div>
        </ng-container>
    </ng-template>
</p-table>
