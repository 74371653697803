import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { GameProvisioningService, GameSubassemblyEntity } from 'src/app/service/game-provisioning.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-modify-subassembly',
  templateUrl: './modify-subassembly.component.html',
  styleUrls: ['./modify-subassembly.component.css']
})
export class ModifySubassemblyComponent implements OnInit {
  subassembly: GameSubassemblyEntity;
  dataEntryForm: UntypedFormGroup;
  submitted: boolean;
  editSubassembly = true;
  errorMessage: string;
  working = false;
  deleting = false;

  constructor(private gameProvisioningService: GameProvisioningService,
    private confirmationService: ConfirmationService, private ref: DynamicDialogRef, private config: DynamicDialogConfig) {
  }

  ngOnInit() {
    if (this.config.data) {
      this.subassembly = this.config.data.subassembly;
    }
// console.log('this.subassembly', this.subassembly)
    this.dataEntryForm = new UntypedFormGroup({
      'subassembly': new UntypedFormControl(this.subassembly.name, Validators.required),
      'numLinkedVersions': new UntypedFormControl({ value: this.subassembly.packages.length, disabled: true })
    });
  }

  submitSubassembly() {
    this.working = true;
    // Clone object so it doesn't modify original instance passed to this component
    const updatedSubassembly = _.clone(this.subassembly);
    updatedSubassembly.name = this.dataEntryForm.value.subassembly;
    this.gameProvisioningService.updateSubassembly(updatedSubassembly).subscribe({
      next: (resp) => {
        this.working = false
        this.ref.close(true);
      }, error: (err) => {
        this.errorMessage = err;
        this.working = false
      }
    });
  }

  onCancel() {
    this.ref.close(false);
  }

  confirmDelete() {
    this.confirmationService.confirm({
      message: 'If you delete this subassembly, all versions / packages linked to it will also be deleted. Do you want to continue?',
      header: 'Delete Subassembly',
      icon: 'fa fa-trash',
      key: "deleteSubassembly",
      accept: () => {
        this.deleting = true;
        this.gameProvisioningService.deleteSubassembly(this.subassembly.subassembly_id).subscribe({
          next: (resp) => {
            this.deleting = false
            this.ref.close(true);
          }, error: (err) => {
            this.errorMessage = err;
            this.deleting = false
          }
        });
      }
    });
  }
}
