import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

  model: any[] = [];

  constructor(public layoutService: LayoutService) {
    this.layoutService.configUpdate$.subscribe(config => {
      this.model = [...this.layoutService.config.menuItems];
    });
  }

  ngOnInit() {
    this.model = this.layoutService.config.menuItems;
  }
}
