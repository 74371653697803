import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileUpload } from 'primeng/fileupload';
import { GameEntity, GamePackageEntity, GameProvisioningService, GameSubassemblyEntity } from 'src/app/service/game-provisioning.service';

@Component({
  selector: 'app-modify-game-package',
  templateUrl: './modify-game-package.component.html',
  styleUrls: ['./modify-game-package.component.css']
})
export class ModifyGamePackageComponent implements OnInit {
  @ViewChild('u', { static: false }) uploader: FileUpload;
  game: GameEntity;
  versionName: string;
  subassembly: GameSubassemblyEntity;
  package: GamePackageEntity;
  dataEntryForm: UntypedFormGroup;
  submitted: boolean;
  editPackage = false;
  modified: string;
  uploadProgress = 0;
  errorMessage: string;

  uploading = false;
  deleting = false;

  constructor(private gameProvisioningService: GameProvisioningService,
    private confirmationService: ConfirmationService, private ref: DynamicDialogRef, private config: DynamicDialogConfig) {
  }

  ngOnInit() {
    this.uploading = false;

    this.dataEntryForm = new UntypedFormGroup({
      'subassembly': new UntypedFormControl({ value: null, disabled: true }),
      'versionName': new UntypedFormControl({ value: null, disabled: true }),
      'versionNum': new UntypedFormControl(null, Validators.required),
      'modified': new UntypedFormControl({ value: null, disabled: true })
    });

    this.subassembly = this.config.data.subassembly;

    if (this.config.data.versionName === undefined) {
      // Edit game
      this.editPackage = true;
      this.package = this.config.data.package;
      this.versionName = this.package.version_name;
      this.dataEntryForm.controls['subassembly'].setValue(this.subassembly.name);
      this.dataEntryForm.controls['versionName'].setValue(this.package.version_name);
      this.dataEntryForm.controls['modified'].setValue(this.package.modified);
      this.dataEntryForm.controls['versionNum'].setValue(this.package.version_num);
    } else {
      this.versionName = this.config.data.versionName;
      this.dataEntryForm.controls['subassembly'].setValue(this.subassembly.name);
      this.dataEntryForm.controls['versionName'].setValue(this.config.data.versionName);
    }
  }

  submitPackage() {
    this.uploading = true;
    this.uploadProgress = 0;
    this.dataEntryForm.controls['versionNum'].disable();
    this.uploader.upload();
  }

  myUploader(event: any) {
    this.errorMessage = '';

    const formData = new FormData();
    formData.append('version_num', this.dataEntryForm.value.versionNum);
    formData.append('version_name', this.versionName);
    formData.append('subassembly_id', this.subassembly.subassembly_id.toString());
    formData.append('files', event.files[0]);

    if (this.package !== undefined) {
      formData.append('action', 'gps_update_package');
      formData.append('game_package_id', this.package.game_package_id.toString());

      this.gameProvisioningService.updatePackage(formData).subscribe({
        next: (event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            // console.log(Math.round(100 * event.loaded / event.total));
            this.uploadProgress = Math.round(100 * event.loaded / event.total);
          } else if (event instanceof HttpResponse) {
            this.errorMessage = event.body.message;
            this.uploadProgress = 100;
            this.ref.close({ modified: true, removed: false });
            // success!
          } else {
            // console.log('event', event);
            // this.uploadProgress = 100;

          }
        },
        error: (err: any) => {
          // console.log('printing error:', err.error.error);
          this.uploadProgress = 100;

          if (err.error && err.error.error) {
            this.errorMessage = err.error.error;
          } else {
            this.errorMessage = 'Could not upload the file!';
          }

          this.dataEntryForm.controls['versionNum'].enable();
          this.uploading = false;
        }
      });
    } else {
      formData.append('action', 'gps_add_package');
      // this.gameProvisioningService.addPackage(formData).subscribe(e => {
      //   console.log(e);
      //   this.ref.close(true);
      // }, error => {
      //   this.uploader.clear();
      //   this.uploading = false;
      //   this.dataEntryForm.controls['versionNum'].enable();
      //   this.errorMessage = error.message;
      // });
      this.gameProvisioningService.addPackage(formData).subscribe({
        next: (event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            // console.log(Math.round(100 * event.loaded / event.total));
            this.uploadProgress = Math.round(100 * event.loaded / event.total);
          } else if (event instanceof HttpResponse) {
            this.errorMessage = event.body.message;
            this.uploadProgress = 100;
            this.ref.close({ modified: true, removed: false });
            // success!
          } else {
            // console.log('event', event);
            // this.uploadProgress = 100;

          }
        },
        error: (err: any) => {
          // console.log(err);
          this.uploadProgress = 100;

          if (err.error && err.error.error) {
            this.errorMessage = err.error.error;
          } else {
            this.errorMessage = 'Could not upload the file!';
          }
          this.dataEntryForm.controls['versionNum'].enable();
          this.uploading = false;
        }
      });
    }
  }

  updateProgress(event: any) {
    // console.log(event);
  }

  confirmDelete() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this game package?',
      header: 'Delete Package',
      icon: 'fa fa-trash',
      key: 'deleteGamePackage',
      accept: () => {
        // console.log('deleting package');
        this.deleting = true;
        this.gameProvisioningService.deletePackage(this.package.game_package_id).subscribe(result => {
          if (result) {
            this.ref.close({ modified: false, removed: true });
          } else {
            this.deleting = false;
            this.errorMessage = 'Unknown error while deleting game package';
          }
        }, error => {
          this.deleting = false;
          this.errorMessage = error.message;
        });
      }
    });
  }

  onCancel() {
    this.ref.close({ modified: false, removed: false });
  }
}
