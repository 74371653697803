import { Component, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileUpload } from 'primeng/fileupload';
import { Package, PackageCriterian } from 'src/app/api/package';
import { ApiService } from 'src/app/service/api.service';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { ActivatedRoute } from '@angular/router';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import * as moment from 'moment';

@Component({
  selector: 'app-modify-package',
  templateUrl: './modify-package.component.html',
  styleUrls: ['./modify-package.component.scss']
})
export class ModifyPackageComponent implements OnInit {
  @ViewChild('u', { static: false }) uploader: FileUpload;
  firstLoad = true;
  updating = false;
  loading = false;
  dataEntryForm: UntypedFormGroup;
  record: Package = {} as Package;
  formChanged = false;
  newRecord = true;
  maxFilesize = 20 * 1024 * 1024;
  packageDownloadServer = '';
  minDate = new Date();
  uploading = false;
  deleting = false;
  uploadProgress = 0;
  errorMessage: string;

  operandItems: SelectItem[] = [
    { label: 'Game Client', value: 'mac' },
    { label: 'Game Name', value: 'game' },
    { label: 'Game Version Name', value: 'game_version_name' },
    { label: 'Date Code', value: 'game_version_datecode' },
    { label: 'Operating System', value: 'operating_system' },
    { label: 'Public IP Address', value: 'public_ip_address' }
  ];

  operatorItems: SelectItem[] = [
    { label: '=', value: '=' },
    { label: '!=', value: '!=' }
  ];

  valueItems: SelectItem[] = [];

  taskCriteria: SelectItem[] = [];

  criterianPlaceHolder: string;

  constructor(private apiService: ApiService, public layoutService: LayoutService,
    private activatedRoute: ActivatedRoute, private config: DynamicDialogConfig, private ref: DynamicDialogRef,
    private confirmationService: ConfirmationService) { }

  ngOnInit() {
    let id = null;
    // console.log('id', id)
    if (this.config.data.id !== undefined) {
      // console.log('this.config.data.id', this.config.data.id)
      id = this.config.data.id;
      this.newRecord = false;
    }

    this.dataEntryForm = new UntypedFormGroup({
      'package_name': new UntypedFormControl(null, Validators.required),
      'replacePackageFile': new UntypedFormControl(false, null),
      'gameUpdate': new UntypedFormControl(false, null),
      'expiration_date': new UntypedFormControl(false, null),
      'operand': new UntypedFormControl(null, null),
      'operator': new UntypedFormControl('=', null),
      'opValue': new UntypedFormControl(null, null),
      'criteria': new UntypedFormControl(null, Validators.required),
      'criteria_op_value': new UntypedFormControl(null, null)
    });

    if (id) {
      this.apiService.getPackage(id).subscribe({
        next: (resp) => {
          // When editing a task we no longer allow setting task type
          this.record = resp.body;
          // console.log(resp.body);

          this.dataEntryForm.controls['package_name'].setValue(this.record.package_name);
          this.dataEntryForm.controls['expiration_date'].setValue(this.record.expiration_date);

          for (const criterian of this.record.rules) {
            // console.log('criterian', criterian);
            const operand = this.getSelectItemByValue(criterian.operand, "operand");
            const operator = this.getSelectItemByValue(criterian.op, "operator");
            const opValue: SelectItem = { value: criterian.value, label: criterian.value } as SelectItem;

            this.taskCriteria.push({ label: criterian.label, value: { operand: operand.value, op: operator.value, value: opValue.value } });
            this.dataEntryForm.controls['criteria'].setValue({ operand: operand.value, op: operator.value, value: opValue.value });
          }

          if (this.record.package_name.toLowerCase().search("game update") >= 0) {
            this.dataEntryForm.controls['gameUpdate'].setValue(true);
          }

          this.taskCriteria = _.clone(this.taskCriteria);

          this.dataEntryForm.valueChanges.subscribe(val => {
            this.formChanged = true;
          });
        }, error: (err) => this.layoutService.onError(err)
      });
    } else {
      this.dataEntryForm.valueChanges.subscribe(val => {
        this.formChanged = true;
      });
    }
  }

  submitForm() {
    this.errorMessage = '';
    if (this.newRecord || this.dataEntryForm.value.replacePackageFile) {
      if (this.uploader.files.length === 0) {
        this.errorMessage = "Select a file to upload."
        return;
      } else {
        this.uploader.upload();
      }
    } else
      this.myUploader(null);
  }

  toggleUploader() {
    this.errorMessage = '';
  }

  toggleGameUpdate() {
    const tag = "game update";
    const regex = new RegExp(tag, "ig"); // case-insenstive search
    let packageName = '';
    if (this.dataEntryForm.value.package_name !== null) {
      packageName = this.dataEntryForm.value.package_name.trim();
    }
    if (this.dataEntryForm.value.gameUpdate) {
      // Add "game update" if not in string
      if (packageName.toLowerCase().search(tag) === -1) {
        packageName = packageName + ' ' + tag;
      }
    }
    else {
      // Remove "game update" from string
      packageName = packageName.replace(regex, "");
    }

    this.dataEntryForm.controls['package_name'].setValue(packageName.trim());
  }

  myUploader(event: any) {
    this.errorMessage = '';

    this.updating = true;
    this.uploading = true;

    const formData = new FormData();
    formData.append('package_name', this.dataEntryForm.value.package_name);
    formData.append('expiration_date', this.dataEntryForm.value.expiration_date ? moment(this.dataEntryForm.value.expiration_date).format('YYYY-MM-DD') : null);
    formData.append('rules', JSON.stringify(this.taskCriteria.map(c => { return { operand: c.value.operand, op: c.value.op, value: c.value.value } as PackageCriterian })));

    if (event)
      formData.append('files', event.files[0]);

    this.dataEntryForm.disable();
    if (this.newRecord) {
      this.apiService.addUpdatePackage(formData).subscribe({
        next: (event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            // console.log(Math.round(100 * event.loaded / event.total));
            this.uploadProgress = Math.round(100 * event.loaded / event.total);
          } else if (event instanceof HttpResponse) {
            this.errorMessage = event.body.message;
            this.uploadProgress = 100;
            this.ref.close(true);
            // success!
          } else {
            // console.log('event', event);
            // this.uploadProgress = 100;
          }
        },
        error: (err: any) => {
          // console.log(err);
          this.uploadProgress = 100;

          if (err.error && err.error.message) {
            this.errorMessage = err.error.message;
          } else {
            this.errorMessage = 'Could not upload the file!';
          }

          this.dataEntryForm.enable();
        }
      });
    } else {
      formData.append('package_id', this.record.package_id);
      this.apiService.modifyUpdatePackage(formData).subscribe({
        next: (event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            // console.log(Math.round(100 * event.loaded / event.total));
            this.uploadProgress = Math.round(100 * event.loaded / event.total);
          } else if (event instanceof HttpResponse) {
            this.errorMessage = event.body.message;
            this.uploadProgress = 100;
            this.ref.close(true);
            // success!
          } else {
            // console.log('event', event);
            // this.uploadProgress = 100;
          }
        },
        error: (err: any) => {
          // console.log(err);
          this.uploadProgress = 100;

          if (err.error && err.error.message) {
            this.errorMessage = err.error.message;
          } else {
            this.errorMessage = 'Could not upload the file!';
          }

          this.dataEntryForm.enable();
        }
      });
    }
  }

  updateProgress(event: any) {
    // console.log(event);
  }

  validForm() {
    let valid = true;
    if (this.dataEntryForm.valid) {
      if ((this.newRecord || this.dataEntryForm.value.replacePackageFile) && this.uploader?.files.length === 0) {
        valid = false;
      }
    } else {
      valid = false;
    }

    // this.cd.detectChanges();
    return valid;
  }

  onDeleteClick() {
    this.confirmationService.confirm({
      header: "Delete Game Update",
      message: "Deleting this game update WILL NOT remove it from games that have already downloaded it. Do you want to continue?",
      accept: () => {
        this.updating = true;
        const formData = new FormData();
        formData.append('package_id', this.record.package_id);
        formData.append('delete_package', "1");

        this.apiService.modifyUpdatePackage(formData).subscribe({
          next: (resp) => {
            this.updating = false;
            this.ref.close(true);
          }, error: (err) => {
            this.updating = false;
            this.layoutService.onError(err);
          }
        });
      },
      reject: () => {
      },
      key: "discard"
    });
  }

  verifyCancelAction(event) {
    if (!this.formChanged) {
      this.ref.close(false);
    } else {
      this.confirmationService.confirm({
        message: "Are you sure you'd like to discard these changes?",
        accept: () => {
          this.ref.close(false);
        },
        reject: () => {
        },
        key: "discard"
      });
    }
  }

  onOperandChange(event: any) {
    this.getValuesForOperand(event.value);
  }

  getValuesForOperand(operand: string) {
    this.valueItems = [];
    if (operand) {
      this.loading = true;
      this.valueItems.push({ label: '', value: null });

      this.apiService.getDroneCriteriaValues(operand).subscribe({
        next: (resp) => {
          if (resp.ok) {
            this.valueItems = resp.data.map(kv => { return { label: kv.k, value: kv.v } });
            this.loading = false;
          } else {
            this.loading = false;
            this.layoutService.onError(resp.message);
          }
        }, error: (err) => {
          this.loading = false;
          this.layoutService.onError(err);
        }
      });
    }
  }

  getSelectItemByValue(value: any, type: string): SelectItem {
    let items: SelectItem[] = [];

    switch (type) {
      case "operand":
        items = this.operandItems.filter(o => o.value === value);
        break;

      case "operator":
        items = this.operatorItems.filter(o => o.value === value);
        break;

      case "opValue":
        items = this.valueItems.filter(o => o.value === value);
        break;
    }

    if (items.length)
      return items[0];
    else
      return null;
  }

  addCriterian(event: any) {
    if (this.dataEntryForm.value.operand && this.dataEntryForm.value.operator && this.dataEntryForm.value.opValue) {
      const operand = this.getSelectItemByValue(this.dataEntryForm.value.operand, "operand");
      const operator = this.getSelectItemByValue(this.dataEntryForm.value.operator, "operator");
      const opValue = this.getSelectItemByValue(this.dataEntryForm.value.opValue, "opValue");

      // console.log(`operand: ${operand.label}, operator: ${operator.label}, opValue: ${opValue.label}`);
      let criterian = { label: `${operand.label} ${operator.label} ${opValue.label}`, value: { operand: operand.value, op: operator.value, value: opValue.value } };
      for (let c of this.taskCriteria) {
        if (_.isEqual(c, criterian)) {
          this.layoutService.onError("Cannot add a duplicate criterian.");
          return;
        }
      }

      this.taskCriteria.push({ label: `${operand.label} ${operator.label} ${opValue.label}`, value: { operand: operand.value, op: operator.value, value: opValue.value } });
      this.taskCriteria = _.clone(this.taskCriteria);
      this.dataEntryForm.controls['operand'].reset();
      this.dataEntryForm.controls['criteria'].setValue({ operand: operand.value, op: operator.value, value: opValue.value });
      this.getValuesForOperand(null);
      // this.softwareForm.updateValueAndValidity();
    }
  }

  removeCriterian(event: any) {
    if (this.dataEntryForm.value.criteria) {
      // console.log(`selection to remove:`); console.log(this.softwareForm.value.criteria);
      for (let idx = 0; idx < this.taskCriteria.length; ++idx) {
        if (_.isEqual(this.taskCriteria[idx].value, this.dataEntryForm.value.criteria)) {
          // console.log(`removing from taskCriteria:`); console.log(this.taskCriteria[idx].value);
          this.taskCriteria.splice(idx, 1);
          this.taskCriteria = _.clone(this.taskCriteria);
        }
      }

      if (this.taskCriteria.length) {
        this.dataEntryForm.controls['criteria'].setValue(this.taskCriteria[0].value);
        // this.softwareForm.updateValueAndValidity();
      } else {
        this.dataEntryForm.controls['criteria'].reset();
      }
    }
  }
}
