import { EventEmitter, Injectable, Output } from '@angular/core';
import { Subject } from 'rxjs';

export type MenuMode = 'static' | 'overlay' | 'horizontal' | 'slim' | 'slim-plus' | 'reveal' | 'drawer';

export type ColorScheme = 'light' | 'dark';


export interface AppConfig {
  inputStyle: string;
  colorScheme: ColorScheme;
  componentTheme: string;
  ripple: boolean;
  menuMode: MenuMode;
  scale: number;
  menuTheme: ColorScheme;
  topbarTheme: string;
  menuProfilePosition: string;
  menuItems: any;
  themes: any;
}

interface LayoutState {
  staticMenuMobileActive: boolean;
  overlayMenuActive: boolean;
  staticMenuDesktopInactive: boolean;
  configSidebarVisible: boolean;
  menuHoverActive: boolean;
  rightMenuActive: boolean;
  topbarMenuActive: boolean;
  menuProfileActive: boolean;
  sidebarActive: boolean;
  anchored: boolean;
  user: any;
}

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  @Output() profileClicked = new EventEmitter<any>();
  @Output() logoutClicked = new EventEmitter<any>();
  @Output() serverError = new EventEmitter<any>();

  config: AppConfig = {
    ripple: true,
    inputStyle: 'outlined',
    menuMode: 'static',
    colorScheme: 'light',
    componentTheme: 'green',
    scale: 14,
    menuTheme: 'light',
    topbarTheme: 'green',
    menuProfilePosition: 'end',
    menuItems: null,
    themes: {
      dark: { sceneName: 'Emerald', colorScheme: 'dark', colorSchemeColor: '#20262e', menuTheme: 'dark', menuThemeColor: '#2a323d', componentTheme: 'teal', componentThemeColor: '#20c997', topbarTheme: 'teal', topbarThemeColor: '#00796B', menuMode: 'static', cardColor: '#2a323d' },
      light: { sceneName: 'Green Light', colorScheme: 'light', colorSchemeColor: '#EFEFEF', menuTheme: 'light', menuThemeColor: '#ffffff', componentTheme: 'green', componentThemeColor: '#198754', topbarTheme: 'green', topbarThemeColor: '#43A047', menuMode: 'static', cardColor: '#ffffff' }
    }
  };

  state: LayoutState = {
    staticMenuDesktopInactive: false,
    overlayMenuActive: false,
    configSidebarVisible: false,
    staticMenuMobileActive: false,
    menuHoverActive: false,
    rightMenuActive: false,
    topbarMenuActive: false,
    menuProfileActive: false,
    sidebarActive: false,
    anchored: false,
    user: { first_name: '', last_name: '', access_level: '' }
  };

  private configUpdate = new Subject<AppConfig>();

  private overlayOpen = new Subject<any>();

  private topbarMenuOpen = new Subject<any>();

  private menuProfileOpen = new Subject<any>();

  configUpdate$ = this.configUpdate.asObservable();

  overlayOpen$ = this.overlayOpen.asObservable();

  topbarMenuOpen$ = this.topbarMenuOpen.asObservable();

  menuProfileOpen$ = this.menuProfileOpen.asObservable();

  onMenuToggle() {
    if (this.isOverlay()) {
      this.state.overlayMenuActive = !this.state.overlayMenuActive;

      if (this.state.overlayMenuActive) {
        this.overlayOpen.next(null);
      }
    }

    if (this.isDesktop()) {
      this.state.staticMenuDesktopInactive = !this.state.staticMenuDesktopInactive;
    }
    else {
      this.state.staticMenuMobileActive = !this.state.staticMenuMobileActive;

      if (this.state.staticMenuMobileActive) {
        this.overlayOpen.next(null);
      }
    }
  }

  onTopbarMenuToggle() {
    this.state.topbarMenuActive = !this.state.topbarMenuActive;
    if (this.state.topbarMenuActive) {
      this.topbarMenuOpen.next(null);
    }
  }

  onOverlaySubmenuOpen() {
    this.overlayOpen.next(null);
  }

  showConfigSidebar() {
    this.state.configSidebarVisible = true;
  }

  isOverlay() {
    return this.config.menuMode === 'overlay';
  }

  isDesktop() {
    return window.innerWidth > 991;
  }

  isSlim() {
    return this.config.menuMode === 'slim';
  }

  isSlimPlus() {
    return this.config.menuMode === 'slim-plus';
  }

  isHorizontal() {
    return this.config.menuMode === 'horizontal';
  }

  isMobile() {
    return !this.isDesktop();
  }

  onConfigUpdate() {
    // console.log('config',  this.config)
    this.configUpdate.next(this.config);
  }

  isRightMenuActive(): boolean {
    return this.state.rightMenuActive;
  }

  openRightSidebar(): void {
    this.state.rightMenuActive = true;
  }

  onMenuProfileToggle() {
    this.state.menuProfileActive = !this.state.menuProfileActive;
    if (this.state.menuProfileActive && this.isHorizontal() && this.isDesktop()) {
      this.menuProfileOpen.next(null);
    }
  }

  replaceThemeLink(href: string, onComplete: Function) {
    const id = 'theme-link';
    const themeLink = <HTMLLinkElement>document.getElementById(id);
    const cloneLinkElement = <HTMLLinkElement>themeLink.cloneNode(true);

    cloneLinkElement.setAttribute('href', href);
    cloneLinkElement.setAttribute('id', id + '-clone');

    themeLink.parentNode!.insertBefore(cloneLinkElement, themeLink.nextSibling);

    cloneLinkElement.addEventListener('load', () => {
      themeLink.remove();
      cloneLinkElement.setAttribute('id', id);
      onComplete();
    });
  }

  onColorSchemeChange(colorScheme: ColorScheme) {
    const themeLink = <HTMLLinkElement>document.getElementById('theme-link');
    const themeLinkHref = themeLink.getAttribute('href');
    const currentColorScheme = 'theme-' + this.config.colorScheme;
    const newColorScheme = 'theme-' + colorScheme;
    const newHref = themeLinkHref!.replace(currentColorScheme, newColorScheme);
    this.replaceThemeLink(newHref, () => {
      this.config.colorScheme = colorScheme;
      if (colorScheme === 'dark') {
        this.config.menuTheme = 'dark';
      }
      this.onConfigUpdate();
    });

  }

  setTheme(colorScheme: string) {
    if (Object.keys(this.config.themes).includes(colorScheme)) {
      this.changeScene(this.config.themes[colorScheme]);
    }
  }

  onToggleTheme() {
    if (this.config.colorScheme === 'light') {
      this.changeScene(this.config.themes['dark']);
    } else {
      this.changeScene(this.config.themes['light']);
    }
  }

  changeScene(item: any) {
    this.replaceScene(item.colorScheme, item.componentTheme)
    this.changeMenuTheme(item.menuTheme)
    this.changeTopbarTheme(item.topbarTheme)
  }

  changeTopbarTheme(theme: string) {
    this.config.topbarTheme = theme;
    this.onConfigUpdate();
  }

  changeMenuTheme(theme: ColorScheme) {
    this.config.menuTheme = theme;
    this.onConfigUpdate();
  }

  replaceScene(colorScheme: ColorScheme, componentTheme: string) {
    const id = 'theme-link';
    const themeLink = <HTMLLinkElement>document.getElementById(id);
    const themeLinkHref = themeLink.getAttribute('href');

    let newHref = themeLinkHref!.replace(this.config.colorScheme, colorScheme);
    newHref = newHref.replace(this.config.componentTheme, componentTheme)

    themeLink.setAttribute('href', newHref)

    this.config.componentTheme = componentTheme;
    this.config.colorScheme = colorScheme;
    this.onConfigUpdate();
  }

  onLogoutClicked() {
    this.logoutClicked.emit();
  }

  onProfileSettingsClicked() {
    this.profileClicked.emit();
  }

  onError(error: any) {
    this.serverError.emit(error);
  }
}
