import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { GameProvisioningService, GpsClientLogRecord, GpsClientRecord } from 'src/app/service/game-provisioning.service';
import { DatePipe } from '@angular/common';
import { GameInstallLogViewerComponent } from '../game-install-log-viewer/game-install-log-viewer.component';

@Component({
  selector: 'app-game-install-details',
  templateUrl: './game-install-details.component.html',
  styleUrls: ['./game-install-details.component.css']
})
export class GameInstallDetailsComponent implements OnInit {
  visible: boolean;
  dialogHeader: string;
  dataEntryForm: UntypedFormGroup;

  gpsClientRecord: GpsClientRecord;
  logs: GpsClientLogRecord[] = [];
  selectedLog: GpsClientLogRecord = null;

  displayLogViewer = false;
  logData: string;
  logViewerHeader: string;

  submitted: boolean;

  downloading = false;
  deleting = false;

  dialogRef: DynamicDialogRef;

  constructor(private gameProvisioningService: GameProvisioningService, public layoutService: LayoutService, private router: Router,
    private confirmService: ConfirmationService, private dialogService: DialogService, private config: DynamicDialogConfig,
    private ref: DynamicDialogRef, private activatedRoute: ActivatedRoute, private datePipe: DatePipe) {
  }

  ngOnInit() {
    this.gpsClientRecord = null;
    if (this.config.data.record !== undefined) {
      // console.log('this.config.data.record', this.config.data.record)
      this.gpsClientRecord = this.config.data.record;
    }

    this.dataEntryForm = new UntypedFormGroup({
      'local_ip_address': new UntypedFormControl({ value: this.gpsClientRecord.local_ip_address, disabled: true }),
      'public_ip_address': new UntypedFormControl({ value: this.gpsClientRecord.public_ip_address, disabled: true }),
      'mac_address': new UntypedFormControl({ value: this.gpsClientRecord.mac_address, disabled: true }),
      'check_in_time': new UntypedFormControl({ value: this.datePipe.transform(this.gpsClientRecord.check_in_time, 'M/d/yy h:mm:ss aa'), disabled: true }),
      'game_installed': new UntypedFormControl({ value: this.gpsClientRecord.game_installed, disabled: true }),
      'serial_num': new UntypedFormControl({ value: this.gpsClientRecord.serial_num, disabled: true }),
      'cpu_serial': new UntypedFormControl({ value: this.gpsClientRecord.cpu_serial, disabled: true }),
      'sd_card_serial': new UntypedFormControl({ value: this.gpsClientRecord.sd_card_serial, disabled: true }),
      'selected_log': new UntypedFormControl()
    });

    // console.log('this.gpsClient', this.gpsClient);
    this.gameProvisioningService.getGpsClientLogs(this.gpsClientRecord.game_provisioning_client_id).subscribe(urls => {
      // console.log(urls);
      this.logs = urls.body['data'];
    });
  }

  onCloseClick(): void {
    this.ref.close(false);
  }

  confirmDelete() {
    this.confirmService.confirm({
      message: 'Are you sure you want to delete this?',
      header: 'Delete GPS Install Record',
      icon: 'fa fa-trash',
      key: 'discardGpsClient',
      accept: () => {
        this.deleting = true;
        this.gameProvisioningService.deleteGpsClient(this.gpsClientRecord.game_provisioning_client_id).subscribe(result => {
          if (result) {
            this.ref.close(true);
          } else {
            this.deleting = false;
          }
        }, error => {
          this.deleting = false;
        });
      }
    });
  }

  hideLogViewer() {
    this.displayLogViewer = false;
    this.logData = '';
    this.selectedLog = null;
  }

  downloadLogFile() {
    console.log('this.selectedLog.log_file', this.dataEntryForm.value.selected_log)
    window.open(this.dataEntryForm.value.selected_log, '_blank');
  }

  onViewLog(event: any) {
    this.dialogRef = this.dialogService.open(GameInstallLogViewerComponent, {
      header: 'GPS Client Log',
      width: this.layoutService.isMobile() ? "100%" : "85vw",
      closable: true,
      closeOnEscape: true,
      draggable: true,
      data: {
        layoutService: this.layoutService,
        selected_log: this.dataEntryForm.value.selected_log
      }
    });
  }
}
