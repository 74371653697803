<p-confirmDialog header="Warning" icon="pi pi-exclamation-triangle" key="deleteSubassembly"
    [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '20vw' }"></p-confirmDialog>
<form [formGroup]="dataEntryForm">
    <div class="formgrid grid">
        <div class="field col-12">
            <label for="subassembly">Subassembly</label>
            <input pInputText type="text" class="w-full" id="subassembly" name="subassembly"
                formControlName="subassembly" placeholder="e.g. Main, Camera, Sound, etc.">
        </div>
        <div class="field col-12">
            <label for="numLinkedVersions">Version Names Linked</label>
            <input pInputText type="text" class="w-full" id="numLinkedVersions" name="numLinkedVersions"
                formControlName="numLinkedVersions">
        </div>
    </div>
    <p-messages *ngIf="errorMessage" severity="error">
        <ng-template pTemplate>
            {{errorMessage}}
        </ng-template>
    </p-messages>
    <div class="flex flex-wrap">
        <p-button label="Delete" icon="pi pi-trash" class="mr-auto" styleClass="p-button-danger"
            [disabled]="working || deleting" (onClick)="confirmDelete()">
        </p-button>
        <p-button label="Cancel" icon="pi pi-times" class="ml-auto" styleClass="p-button-secondary"
            [disabled]="working || deleting" (onClick)="onCancel()">
        </p-button>
        <p-button label="Save" icon="pi pi-check" class="ml-2" (onClick)="submitSubassembly()"
            [disabled]="!dataEntryForm.valid || working || deleting"></p-button>
    </div>
</form>
