import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { Alert, ApiService } from 'src/app/service/api.service';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-alert-viewer',
  templateUrl: './alert-viewer.component.html',
  styleUrls: ['./alert-viewer.component.scss']
})
export class AlertViewerComponent implements OnInit {
  alert: Alert;
  layoutService: LayoutService; // Reference to component passed in through DynamicDialogConfig
  dataEntryForm: UntypedFormGroup;
  loading = false;

  constructor(private apiService: ApiService, public authService: AuthService, private confirmService: ConfirmationService,
    private ref: DynamicDialogRef, private config: DynamicDialogConfig, private datePipe: DatePipe) { }

  ngOnInit() {
    // Due to dynamically instantiating this component, I get an error if I use dependency injection so instead
    // The caller needs to pass a reference to the AppMainComponent
    this.layoutService = this.config.data.layoutService;

    this.dataEntryForm = new UntypedFormGroup({
      'alert_id': new UntypedFormControl({ value: null, disabled: true }),
      'sent': new UntypedFormControl({ value: null, disabled: true }),
      'alert_time': new UntypedFormControl({ value: null, disabled: true }),
      'local_ip_address': new UntypedFormControl({ value: null, disabled: true }),
      'public_ip_address': new UntypedFormControl({ value: null, disabled: true }),
      'log_message': new UntypedFormControl({ value: null, disabled: true })
    });

    this.loading = true;
    this.apiService.getAlert(this.config.data.id).subscribe({
      next: (resp) => {
        if (resp.body) {
          this.loading = false;
          this.alert = resp.body;
          this.dataEntryForm.controls['alert_id'].setValue(this.alert.alert_id);
          this.dataEntryForm.controls['sent'].setValue(this.alert.sent == 1 ? "True" : "False");
          this.dataEntryForm.controls['alert_time'].setValue(this.datePipe.transform(this.alert.alert_time, 'M/d/yy h:mm:ss aa'));
          this.dataEntryForm.controls['local_ip_address'].setValue(this.alert.local_ip_address);
          this.dataEntryForm.controls['public_ip_address'].setValue(this.alert.public_ip_address);
          this.dataEntryForm.controls['log_message'].setValue(this.alert.log_message);
        } else {
          this.layoutService.onError("Alert record no longer exists.");
          setTimeout(() => this.ref.close(), 2000);
        }
      }, error: (err) => {
        this.loading = false;
        this.layoutService.onError(err)
      }
    });
  }

  onDeleteClick() {
    if (this.alert) {
      this.confirmService.confirm({
        message: "Are you sure you want to delete this?",
        header: 'Delete Alert',
        icon: 'pi pi-exclamation-triangle',
        key: 'discardAlert',
        accept: () => {
          this.loading = true;
          this.apiService.deleteAlert(this.alert.alert_id).subscribe({
            next: (resp) => {
              this.ref.close(true);
            }, error: (err) => {
              this.loading = false;
              this.layoutService.onError(err)
            }
          });
        },
        reject: () => {
          //reject action
        }
      });
    } else {
      this.layoutService.onError("No alert record to delete.");
    }
  }

  onCloseClick() {
    this.ref.close();
  }
}
