import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RoleGuardService  {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const expectedAccessLevels = route.data['expectedAccessLevels'];

    if (!this.authService.isAuthenticated()) {
      // console.log('not signed in, RoleGuardService redirecting to /login');
      this.router.navigate(['/login']);
      return false;
    } else if (!expectedAccessLevels.includes(this.authService.getAccessLevel())) {
      // console.log(`not authorized to view: ${route.toString()}, RoleGuardService redirecting to /`);
      this.router.navigate(['/']);
      return false;
    } else {
      return true;
    }
  }
}
