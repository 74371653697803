import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CustomValidators } from 'src/app/api/custom-validators';
import { EditGameSettings } from 'src/app/api/game-client';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-edit-game-settings',
  templateUrl: './edit-game-settings.component.html',
  styleUrls: ['./edit-game-settings.component.scss']
})
export class EditGameSettingsComponent implements OnInit {
  gameClientSettings: any;
  currentSettings: any;
  layoutService: LayoutService; // Reference to component passed in through DynamicDialogConfig
  dataEntryForm: UntypedFormGroup;
  loading = true;
  updating = false;
  fields: any;

  constructor(private apiService: ApiService, private ref: DynamicDialogRef, public config: DynamicDialogConfig, private confirmService: ConfirmationService) {
  }

  ngOnInit() {
    // Due to dynamically instantiating this component, I get an error if I use dependency injection so instead
    // The caller needs to pass a reference to the layoutService
    this.layoutService = this.config.data.layoutService;
    this.gameClientSettings = [];

    this.apiService.getGameSettings(this.config.data.mac_address).subscribe({
      next: (resp) => {
        if (resp.body) {
          this.buildForm(resp.body);
          this.loading = false;
        } else {
          this.loading = false;
          this.layoutService.onError(resp);
          setTimeout(() => this.ref.close(), 2000);
        }
      }, error: (err) => {
        this.loading = false;
        this.layoutService.onError(err);
        setTimeout(() => this.ref.close(), 2000);
      }
    });
  }

  buildForm(settings: EditGameSettings) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    });

    this.currentSettings = settings.currentSettings;
    this.gameClientSettings = [];
    this.fields = {};
    for (const section of settings.layout['sections']) {
      if (section['name'] !== undefined && section['settings'] !== undefined && Array.isArray(section['settings'])) {
        for (const setting of section['settings']) {
          if (setting['visible'] === undefined || (setting['visible'] !== undefined && setting['visible'])) {
            if (setting['editable'] !== undefined)
              continue;

            switch (setting["type"]) {
              case "number":
                // console.log(setting['field_name'], this.currentSettings[setting['offset']]);

                let validators = [Validators.required, Validators.min(setting["min"]), Validators.max(setting["max"])];
                // IF the min or max is not a multiple of the step (precision field on RPi platform)
                if (setting["min"] % setting["step"] || setting["max"] % setting["step"]) {
                  setting["step"] = 1;
                }

                validators.push(CustomValidators.MultipleOf(setting["min"], setting["max"], setting["step"]));
                this.fields[setting['field_name']] = new UntypedFormControl(this.currentSettings[setting['offset']], validators);

                break;

              case "checkbox":
                this.fields[setting['field_name']] = new UntypedFormControl(this.currentSettings[setting['offset']] === 1, Validators.required);
                // console.log(setting['field_name'], this.currentSettings[setting['offset']]);
                break;

              case "combobox":
                this.fields[setting['field_name']] = new UntypedFormControl(this.currentSettings[setting['offset']], Validators.required);
                // console.log(setting['field_name'], this.currentSettings[setting['offset']]);

                // If min max keys found then the values are generated instead of reading from the options array
                if (setting["min"] !== undefined && setting["max"] !== undefined && setting["options"] === undefined) {
                  setting["options"] = [];
                  for (let value = setting["min"]; value <= setting["max"]; value += setting["step"]) {
                    let label = value;
                    if (setting["display_as"] !== undefined && setting["multiply_by"] !== undefined && setting["display_as"] == "currency") {
                      label = formatter.format(value * setting["multiply_by"]);
                    }

                    setting["options"].push({ value, label } as SelectItem);
                  }
                } else {
                  // Create SelectItem objects from options and overwrite it in settings
                  const options = [];
                  for (const [key, value] of Object.entries(setting["options"])) {
                    options.push({ value, label: key } as SelectItem);
                  }
                  setting["options"] = options;
                }
                break;

            } // end switch

          } // endif visible is set or visible doesn't exist

        } // end for each setting in section

        this.gameClientSettings.push(section);

      } // endif valid section

    } // end for each section

    this.dataEntryForm = new UntypedFormGroup(this.fields);
  }

  onCloseClick() {
    this.ref.close();
  }

  verifyCancelAction(event) {
    if (!this.dataEntryForm.dirty) {
      this.ref.close(false);
    } else {
      this.confirmService.confirm({
        message: "Are you sure you'd like to discard your changes?",
        key: "discardSettings",
        accept: () => {
          this.ref.close(false);
        },
        reject: () => {
        }
      });
    }
  }

  submitForm() {
    this.updating = true;
    let updatedSettings = {};

    updatedSettings["mac"] = this.config.data.mac_address;
    for (const key of Object.keys(this.fields)) {
      updatedSettings[key] = this.dataEntryForm.value[key];
    }

    this.apiService.updateGameSettings(updatedSettings).subscribe({
      next: (resp) => {
        // console.log('resp', resp)
        this.updating = false;
        if (resp.ok) {
          this.ref.close(true);
        } else {
          this.layoutService.onError(resp.message);
        }
      }, error: (err) => {
        this.updating = false;
        this.layoutService.onError(err);
      }
    });
  }
}
