import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  templateUrl: './login.component.html'
})
export class LoginComponent {
  rememberMe: boolean = false;
  loginError: string;
  signingIn = false;

  constructor(private authService: AuthService, private router: Router, private layoutService: LayoutService) { }

  get dark(): boolean {
    return this.layoutService.config.colorScheme !== 'light';
  }

  ngOnInit() {
    // console.log('SigninComponent.ngOnInit');
    this.signingIn = false;

    if (this.authService.isAuthenticated()) {
      this.router.navigate(['/']);
    }
  }

  ngOnDestroy() {
  }

  onSignIn(form: NgForm) {
    if (form.valid && !this.signingIn) {
      this.loginError = '';
      this.signingIn = true;

      this.authService.signInUser(form.value.login_username, form.value.login_password).subscribe({
        next: (resp) => {
          if (resp.body.ok) {
            const sessionToken = resp.headers.get('x-auth');
            localStorage.setItem('session_token', sessionToken);
            if (this.authService.loadUserFromToken(sessionToken)) {
              this.authService.initSessionTokenCheck();
            }
            this.router.navigate(['/']);
          } else {
            this.loginError = resp.body.message || "Unknown error while signing in.";
            this.signingIn = false;
            // console.log(this.loginError)
          }
          this.authService.signInStatusUpdate.next(resp.body);
        }, error: (err) => {
          console.error('Should not be here!');
        }
      });
    }
  }
}
