<p-confirmDialog key="discardGpsClient" [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '20vw' }">
</p-confirmDialog>
<form [formGroup]="dataEntryForm">
    <div class="formgrid grid">
        <div class="field col-12 md:col-4">
            <label for="check_in_time">Timestamp</label>
            <input pInputText type="text" class="w-full" id="check_in_time" name="check_in_time"
                formControlName="check_in_time">
        </div>
        <div class="field col-12 md:col-8">
            <label for="game_installed">Gamed Installed</label>
            <input pInputText type="text" class="w-full" id="game_installed" name="game_installed"
                formControlName="game_installed">
        </div>
        <div class="field col-12 md:col-4">
            <label for="public_ip_address">Public IP Address</label>
            <input pInputText type="text" class="w-full" id="public_ip_address" name="public_ip_address"
                formControlName="public_ip_address">
        </div>
        <div class="field col-12 md:col-4">
            <label for="local_ip_address">Local IP Address</label>
            <input pInputText type="text" class="w-full" id="local_ip_address" name="local_ip_address"
                formControlName="local_ip_address">
        </div>
        <div class="field col-12 md:col-4">
            <label for="mac_address">MAC Address</label>
            <input pInputText type="text" class="w-full" id="mac_address" name="mac_address"
                formControlName="mac_address">
        </div>
        <div class="field col-12 md:col-4">
            <label for="serial_num">Game Serial #</label>
            <input pInputText type="text" class="w-full" id="serial_num" name="serial_num" formControlName="serial_num">
        </div>
        <div class="field col-12 md:col-4">
            <label for="cpu_serial">CPU Serial #</label>
            <input pInputText type="text" class="w-full" id="cpu_serial" name="cpu_serial" formControlName="cpu_serial">
        </div>
        <div class="field col-12 md:col-4">
            <label for="sd_card_serial">Micro SD Card Serial #</label>
            <input pInputText type="text" class="w-full" id="sd_card_serial" name="sd_card_serial"
                formControlName="sd_card_serial">
        </div>
        <div class="field col-12">
            <label for="selected_log">Log File(s)</label>
            <p-listbox *ngIf="logs.length else noLogs" id="selected_log" [options]="logs"
                [listStyle]="{height: '150px'}" optionLabel="submitted" optionValue="log_file"
                (onChange)="onViewLog($event)" name="selected_log" formControlName="selected_log"></p-listbox>
            <ng-template #noLogs>
                <div>N/A</div>
            </ng-template>
        </div>
    </div>
    <hr>
    <div class="flex flex-wrap">
        <p-button label="Delete" icon="pi pi-trash" class="mr-auto" styleClass="p-button-danger"
            (onClick)="confirmDelete()">
        </p-button>
        <p-button label="Close" icon="pi pi-times" class="ml-auto" styleClass="p-button-secondary"
            (onClick)="onCloseClick()">
        </p-button>
    </div>
</form>
