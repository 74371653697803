import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GameClient } from 'src/app/api/game-client';
import { ApiService } from 'src/app/service/api.service';
import { AuthService } from 'src/app/service/auth.service';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { Table } from 'primeng/table';
import { ConfirmationService, LazyLoadEvent, MenuItem } from 'primeng/api';
import { Subject, Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import * as _ from 'lodash';
import { EditGameClientComponent } from '../edit-game-client/edit-game-client.component';

@Component({
  selector: 'app-game-clients',
  templateUrl: './game-clients.component.html',
  styleUrls: ['./game-clients.component.scss']
})
export class GameClientsComponent implements OnInit, OnDestroy {
  @ViewChild('dt', { static: false }) tableComponent: Table;

  rows: GameClient[] = [];
  selectedRow: GameClient;
  totalRows: number;
  loading = false;
  columns: any[];

  dialogRef: DynamicDialogRef;
  totalDrones = 0;

  // LAZY LOADING CODE
  firstLoad = true;
  rowsPerPage = 20;
  sortField = 'check_in_time';
  sortOrder = -1; // Descending
  searchTerm: string;
  // stateKey = 'gameClientTable';
  // END LAZY LOADING CODE

  filterTimeout: any;
  filterValue: string = '';
  timerId: any;

  items: MenuItem[];
  activeItem: MenuItem;
  queryParams: any;

  private readonly searchSubject = new Subject<string | undefined>();
  searchSubscription: Subscription;

  constructor(private apiService: ApiService, public layoutService: LayoutService, private router: Router,
    private route: ActivatedRoute, public authService: AuthService, private dialogService: DialogService,
    private confirmService: ConfirmationService) {
    this.selectedRow = {} as GameClient;
    this.totalRows = 0;

    this.columns = [
      { field: 'name', header: 'Game', type: 'string' },
      { field: 'location_name', header: 'Location', type: 'string' },
      { field: 'check_in_time', header: 'Check In', type: 'datetime' },
      { field: 'current_package_id', header: 'Package ID', type: 'string' },
      { field: 'schedule', header: 'Schedule', type: 'string' },
      { field: 'public_ip_address', header: 'Public IP', type: 'string' },
      { field: 'local_ip_address', header: 'Local IP', type: 'string' },
      { field: 'mac_address', header: 'MAC Address', type: 'string' },
      // { field: 'notes', header: 'Notes', type: 'string' }
    ];
  }

  ngOnInit(): void {
    this.timerId = setInterval(() => {
      this.forceUpdate();
    }, 1000 * 60 * 10);

    // this.route.queryParams.subscribe(params => {
    //   if (!this.firstLoad)
    //     return;

    //   // console.log('this.route.queryParams', params);
    //   this.queryParams = {};

    //   if (params['s'] !== undefined) {
    //     // console.log('search from query:', params['s'])
    //     this.queryParams['s'] = params['s'];
    //     this.searchTerm = params['s'];
    //   }

    //   // console.log('query =', this.queryParams);
    //   if (!_.isEmpty(this.queryParams)) {
    //     if (localStorage.getItem(this.stateKey)) {
    //       // console.log('clearing filter from state object, before:', localStorage.getItem(this.stateKey));
    //       let event = <LazyLoadEvent>JSON.parse(localStorage.getItem(this.stateKey));
    //       event.filters = null;
    //       localStorage.setItem(this.stateKey, JSON.stringify(event));
    //       // console.log('clearing filter from state object, after:', localStorage.getItem(this.stateKey));
    //     } else {
    //       // console.log('query params found but no state object, nothing to change')
    //     }
    //   } else {
    //     if (localStorage.getItem(this.stateKey)) {
    //       let event = <LazyLoadEvent>JSON.parse(localStorage.getItem(this.stateKey));
    //       if (event.filters) {
    //         if (event.filters['search']) {
    //           console.log('restoring search from storage:', event.filters['search'].value)
    //           this.searchTerm = event.filters['search'].value;
    //           this.queryParams['s'] = this.searchTerm;
    //         }
    //       }
    //     }
    //   }
    // });

    this.searchSubscription = this.searchSubject
      .pipe(
        debounceTime(800),
        distinctUntilChanged()
      )
      .subscribe(searchTerm => this.tableComponent.filter(searchTerm, 'search', 'contains'));

    // this.forceUpdate();
  }

  ngOnDestroy() {
    clearInterval(this.timerId);
    if (this.dialogRef) {
      this.dialogRef.close();
    }
    this.searchSubscription.unsubscribe();
  }

  forceUpdate() {
    this.tableComponent.filter(this.searchTerm, 'search', 'contains');
  }

  onRowSelect(event: any) {
    this.dialogRef = this.dialogService.open(EditGameClientComponent, {
      header: `Edit Game Client`,
      width: this.layoutService.isMobile() ? "100%" : "40vw",
      closable: false,
      closeOnEscape: false,
      draggable: true,
      data: {
        layoutService: this.layoutService,
        id: this.selectedRow.mac_address
      }
    });

    this.dialogRef.onClose.subscribe((created: boolean) => {
      this.selectedRow = null;
      if (created) {
        this.forceUpdate();
      }
    });
  }

  isSortDisabled(fieldName: string): boolean {
    if (fieldName.endsWith('_day')) {
      return true;
    }

    return false;
  }

  loadDataLazy(event: LazyLoadEvent) {
    // Apply the filters passed in the event and execute query
    let filters = {};
    this.loading = true;
    // console.log('loadDataLazy', event);
    let sf = event.sortField || this.sortField;
    let filter = event.filters['search'] ? event.filters['search'].value : null;
    if (filter)
      filters['s'] = filter;

    // this.router.navigate(['/'].concat(this.route.snapshot.url.map(p => p.path)), { queryParams: this.criteria ? { ...this.criteria.queryParams, ...filters } : filters });
    // console.log(`criteria - first: ${event.first}, rows: ${event.rows}, sort field: ${sf}, sort order: ${event.sortOrder}, status: ${status}, search: ${filter}, locationIDs: ${locationIDs ? locationIDs.map(l => l.location_id).join(',') : locationIDs}`);
    this.apiService.getGameClients(event.first, event.rows, sf, event.sortOrder, filter).subscribe({
      next: (resp) => {
        if (resp.body) {
          this.rows = resp.body.rows
          this.totalRows = resp.body.totalRows;
        } else {
          this.rows = [];
          this.totalDrones = 0;
        }
        this.loading = false
      }, error: (err) => {
        this.layoutService.onError(err);
        this.loading = false;
      }
    });
  }

  onGlobalFilterBodyChange(event, dt) {
    const searchQuery = event.srcElement.value;
    this.searchSubject.next(searchQuery?.trim());
  }

  getPageHeight() {
    if (this.layoutService.isMobile())
      return '100%';
    else return "calc(100vh - 27vh)";
  }
}